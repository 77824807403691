import React from "react";
import SolutionCard from "./SolutionCard";
import i18n from '../../../i18n';
import { Wrapper, SolutionCardList, Header, Body, EmptyBody, Footer, ExploreButton } from "./styles";

const SolutionPanel = ({data, title}) => {
  const itemList = data;
  const [showAllItems, setShowAllItems] = React.useState(false);

  return (
    <Wrapper>
      <Header>
        <p>{title}</p>
      </Header>
      <Body>
        {
          itemList.length === 0 ?
            <EmptyBody>
              <p>{i18n.t('dashboard.add')} {title.toLowerCase()} {i18n.t('dashboard.toAccount')}</p>
            </EmptyBody>
            :
            <SolutionCardList>
              {
                showAllItems ?
                  itemList.map(item => (
                    <SolutionCard data={item} key={item.id} />
                  ))
                  :
                  itemList.filter((item, index) => index < 2).map(item => (
                    <SolutionCard data={item} key={item.id} />
                  ))
              }
            </SolutionCardList>
        }
      </Body>
      <Footer>
        {
          !showAllItems && data.length > 1 ?
            <div className="mb-4">
              <ExploreButton onClick={() => setShowAllItems(true)} className="mr-4 mb-3">{i18n.t('dashboard.exploreMore')} {title}</ExploreButton>
              {
                itemList.filter((item, index) => index >= 2).map(item => (
                  <img src={item.icon} className="mr-3 item_icon mobile-hidden" key={item.id}/>
                ))
              }
            </div>
            :  <></>
        }
      </Footer>
    </Wrapper>
  )
};

export default SolutionPanel;
