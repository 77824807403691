import styled, {css} from "styled-components";

export const Wrapper = styled.div`
  background: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
`;

