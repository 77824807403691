import React from "react";
import styled from "styled-components";
import i18n from '../../../../i18n';

const Wrapper = styled.div`
  display: inline-block;
`;

const QuestionIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  margin-left: 5px;
  border-radius: 50%;
  background: #919fb3;
  position: relative;
  cursor: pointer;
`;

const Icon = styled.div`
  color: white;
  font-size: 8px;
`;

const Text = styled.div`
  display: ${props => props.isShow ? 'block' : 'none'};
  background: #fff;
  opacity: 0.9;
  width: 300px;
  position: absolute;
  top: 20px;
  z-index: 10;
  font-family: Circe-Regular;
  font-size: 13px;
  padding: 10px;
  border-radius: 7px;
  box-shadow: -2px 0px 5px 1px rgba(0,0,0,0.16);
`;

const Tooltip = () => {
  const [isOpened, setIsOpened] = React.useState(false);
  
  const toggleContainer = React.createRef();
  
  const onClickOutsideHandler = (event) => {
    if (isOpened && toggleContainer.current && !toggleContainer.current.contains( event.target )) {
      setIsOpened(false);
    }
  };
  
  React.useEffect(() => {
    window.addEventListener('click', onClickOutsideHandler);
  });
  return (
    <Wrapper ref={toggleContainer}>
      <QuestionIcon>
        <Icon className={'fa fa-question'} aria-hidden="true" onClick={() => setIsOpened(!isOpened)}/>
        <Text isShow={isOpened}>{i18n.t('dashboard.financialsBeyond')}</Text>
      </QuestionIcon>
    </Wrapper>
  )
}

export default Tooltip;
