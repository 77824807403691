export const productColors = {
  'receiptbank': '#EF6525',
  'fathom': '#4ABEA7',
  'approval': '#4C8A5C',
  'shopify': '#95BF46',
  'ringcentral': '#0073AE',
  'xero': '#14B5EA',
  'stripe': '#6772e5',
  'wagepoint': '#EF6525'
}
