import React from 'react';
import i18n from '../../i18n';
import {
  Wrapper, Label, MLabel
} from './styles';

export default () => (
  <Wrapper>
    <Label>404</Label>
    <MLabel href="/" alt="">{i18n.t('dashboard.errorPage')}</MLabel>
  </Wrapper>
);
