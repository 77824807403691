import React, {Component, Fragment, useEffect} from "react";
import styled, { css } from "styled-components";

const SelectWrapper = styled.div`
  position: relative;
`;

const Label = styled.div`
  font-family: Circe-Regular;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #5e7599;
`;

const SelectBox = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 10px;
  background-color: rgba(65, 92, 133, 0.05);
  width: ${props => props.width || 'unset'};
  min-width: 70px;
  min-width: ${props => props.size == 'sm' ? `70px` : `150px`};

  font-family: Circe-Regular;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #181e26;

  outline: none;
  border: 0;
  padding: 14px 15px;
  
  &:hover {
    cursor: pointer;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
  }
  
`;

const SelectList = styled.div`

  display: ${props => props.isShow ? 'block' : 'none'};
  position: absolute;
  top: 67px;
  left: 0px;
  
  width: ${props => props.width || '100%'};
  
  border-radius: 10px;
  background-color: #f5f7f9;
  
  font-family: Circe-Regular;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #181e26;

  outline: none;
  border: 0;
  z-index: 10;
  
  &:hover {
    cursor: pointer;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`;

const SelectItem = styled.div`
  
  padding: 5px 20px;
 
  &:hover {
    background: rgba(65, 92, 133, 0.8);
    cursor: pointer;
  }
`;

const DownIcon = styled.div`
  position: absolute;
  right: 10px;
`;

const StyledFlagIcon = styled.img`
    margin-right: 10px;
`;

const Select = (props) => {
  const {
    label, placeholder, options, selectedOptionId, viewMode, icon, size, width, disabled = false,
  } = props;
  const selectedOption = options.find((option) => {
    return (option.id === selectedOptionId);
  });

  const [isOpened, setIsOpened] = React.useState(null);

  const openedToggle = () => {
    if (disabled) return;
    setIsOpened(!isOpened);
  }

  const selectOption = (optionId) => {

    if (optionId !== props.selectedOptionId) {
      props.setOption(optionId);
    }
    else {
      props.setOption(null);
    }

    openedToggle();
  }

  const toggleContainer = React.createRef();

  const onClickOutsideHandler = (event) => {
    if (isOpened && toggleContainer.current && !toggleContainer.current.contains( event.target )) {
      setIsOpened(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', onClickOutsideHandler);
  });

  useEffect(() => {
    return () => {
      window.removeEventListener('click', onClickOutsideHandler);
    }
  })

  let selectItemName;

  if (viewMode === 'img') {
    selectItemName = '';
  } else if (selectedOption) {
    selectItemName = selectedOption.name;
  }

  return (
    <SelectWrapper ref={toggleContainer}>
      {label ? <Label>{label}</Label>: ''}
      <SelectBox onClick = {openedToggle} size = {size} width={width}>
        {
          selectedOption ?
            icon
            : null
        }
        {
          selectedOption && selectedOption.img ?
            <StyledFlagIcon src={selectedOption.img} />
            : ''
        }
        {
          selectedOption ?
            selectItemName
            : placeholder
        }

        <DownIcon className="fa fa-caret-down" aria-hidden="true"/>

        <SelectList isShow={isOpened} width={width}>
          {
            options.length ?
              options.map(option => {
                return (
                  <SelectItem
                    key = {option.id}
                    onClick={() => {
                      selectOption(option.id)
                    }}
                  >
                    { option.img ?
                      <StyledFlagIcon src={option.img} /> : ''
                    }
                    {option.name}
                  </SelectItem>
                )
              })
              : null
          }
        </SelectList>
      </SelectBox>

    </SelectWrapper>
  );
};

export default Select;








