import React from "react";
import styled, {css} from "styled-components";
import {CompletedIcon, ListTitle, PTIcon, ShowMoreBtn, TargetList} from "@/containers/Targets/styles";
import CircleProgressBar from "@/components/common/circle-progressbar";
import TaskList from "../TaskList";
import NoTaskPanel from "../NoTaskPanel";
import i18n from '../../../i18n';

const Wrapper = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border-radius: 7px;
    margin-bottom: 30px;
    border: ${props => props.finished === true ? 'none' : '1px solid #ededee'} ;
    background-color: #fff;
    background-color: ${props => props.finished === true ? '#fafcf9' : '#fff'};
    
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      border: 0px;
    }    
  `;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${props => props.finished === true ? '#a6b79f' : '#75757a'};
    padding: 20px 15px;
    cursor: pointer;
    
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      display: none;
    }
    
    img {
      width: 50px;
      min-width: 50px;
      height: 50px;
      margin-right: 15px;
    }
    
    .header-item {
    
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      h3 {
        font-size: 20px;
        color: #415c85;
      }
      
      p {
        font-size: 14px;
        color: #aab4c4;
      }
      
      > span {
        font-size: 11px;
        color: #aab4c4;
        margin-bottom: 5px;
      }
      
      i {
        font-size: 24px;
        cursor: pointer;
        color: #8d9bb0;
        
        @media (max-width: ${props => props.theme.breakpoints.md}) {
          display: none;
        }
      }
      
      .target-name {
        ${props => props.finished && css`
          text-decoration: line-through;
          color: #8d9bb0;
        `}
      }
      
      .date {
        color: #7937d3;
      }
      
      .price {
        color: #d88f32;
      }
      
      ${props => props.finished && css`
        h3 {
          color: #b0bcca;
        }
        
        p {
          color: #c7ced6;
        }
        
        > span {
          color: #c7ced6;
        }
        
        .date {
          color: #c6abe8;
        }
        
        .price {
          color: #eccfa7;
          
          span {
            color: #eccfa7;
          }
        }
      `}
    }
      
    .angle_must {
        > i {
          display: block !important;        
          margin-right: 20px;
        }
    }
    
    .h-title {
      flex: 2;
      max-width: 32%;
      overflow: hidden;
    }
    
    .h-task {
      flex: 1;
      overflow: hidden;
    }
    
    .h-due-date {
      flex: 1;
      overflow: hidden;
    }
    
    .h-reward {
      flex: 1;
      overflow: hidden;
    }
    
    .h-circle {
      flex: 1;
      overflow: hidden;
    }
  `;

const MobileHeader = styled(Header)`
    flex-direction: column;
    display: none;
    
    .mb_header_custom {
      border-bottom: 1px solid #e5dfde;
      padding-bottom: 10px !important;
    }
    
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      display: flex;
    }
    
    > div {
      width: 100%;
    }
`;

const Body = styled.div`
    border-top: 1px solid #e7e8e9;
    padding: 0 15px;
    // overflow: hidden;
    max-height: 0;
    transition: all 1s ease-out;
    border-top: 0 solid #ededee;
    &.visible {
      max-height: 1000px;
      border-top: 1px solid #ededee;
    }
    
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      border-top: 0;
    }
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px;
`;

const TargetBlock = ({target, showAll, setDetailViewId, markTaskAsComplete}) =>{

  let isAllCompleted = target.completed;

  const [isShowContent, setIsShowContent] = React.useState(false);

  const onClickMoreBtn = (id) => {
    setDetailViewId(id);
    document.getElementById('scroll-top').scrollTop = 0;
  };

  const toggleButton = (isAllCompleted, isShowContent, isMobile) => {
    return (
        <div className={`header-item ml-2 ${isMobile ? 'angle_must' : ''}`}>
          {
            isAllCompleted ?
                <i className="fa fa-angle-down" />
                :
                isShowContent ?
                    <i className="fa fa-angle-up" onClick={() => setIsShowContent(!isShowContent || isAllCompleted)}/>
                    :
                    <i className="fa fa-angle-down" onClick={() => setIsShowContent(!isShowContent)}/>
          }
        </div>
    )
  }

  let cnt = 0;

  return (
    <Wrapper finished={ isAllCompleted } id={'scroll-top'}>
      <Header finished={isAllCompleted} onClick={() => setIsShowContent(!isShowContent)}>
        <div className="d-flex align-items-center h-title" style={{'minWidth': '20%'}}>
          <img src={target.icon} alt="img" className={'header-icon'} />
          <div className={'header-item align-items-start'}>
            <h3 className={'target-name'}>{target.target_name}</h3>
            <p>{target.target_property}</p>
          </div>
        </div>
        <div className={'header-item h-task'}>
          <span className="upper-case">{i18n.t('dashboard.tasks')}</span>
          <h3>{target.tasks_count}</h3>
        </div>
        <div className={'header-item h-due-date'} style={{'minWidth': '15%'}}>
          <span className="upper-case">{i18n.t('dashboard.dueDate')}</span>
          <h3 className={'date'}>{target.dateStr}</h3>
        </div>
        <div className={'header-item h-reward'}>
          <span className="upper-case">{i18n.t('dashboard.reward')}</span>
          <h3 className={'price'}><PTIcon/>{target.price}</h3>
        </div>
        <div className={'h-circle'}>
        {
          isAllCompleted ?
              <CompletedIcon className='fa fa-check-circle' />
              :
              <CircleProgressBar value={target.percentage} cutout={90}/>
        }
        </div>
        <div className={'header-item'}>
          {
              isShowContent ?
                <i className="fa fa-angle-up"/>
                :
                <i className="fa fa-angle-down"/>
          }
        </div>
      </Header>
      <MobileHeader finished={isAllCompleted} onClick={() => setIsShowContent(!isShowContent)}>
        <div className="d-flex align-items-center justify-content-between mb_header_custom">
          <div className="d-flex align-items-center" style={{'minWidth': '20%'}}>
            <img src={target.icon} alt="img" className={'header-icon'} />
            <div className={'header-item align-items-start'}>
              <h3 className={'target-name'}>{target.target_name}</h3>
              <p>{target.target_property}</p>
            </div>
            {toggleButton(isAllCompleted, isShowContent, true)}
          </div>
          <div className="d-flex align-items-center">
            {
              isAllCompleted ?
                <CompletedIcon className='fa fa-check-circle' />
                :
                <CircleProgressBar value={target.percentage} cutout={90}/>
            }
            {toggleButton(isAllCompleted, isShowContent, false)}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-around mt-3">
          <div className={'header-item'}>
            <span className="upper-case">{i18n.t('dashboard.tasks')}</span>
            <h3>{target.tasks_count}</h3>
          </div>
          <div className={'header-item'} style={{'minWidth': '15%'}}>
            <span className="upper-case">{i18n.t('dashboard.dueDate')}</span>
            <h3 className={'date'}>{target.dateStr}</h3>
          </div>
          <div className={'header-item'}>
            <span className="upper-case">{i18n.t('dashboard.reward')}</span>
            <h3 className={'price'}><PTIcon/>{target.price}</h3>
          </div>
        </div>

      </MobileHeader>
      {isShowContent && (
        <Body className={isShowContent ? 'visible' : ''}>
          <div className="row">
            <div className="col-xl-6 col-lg-12 d-flex flex-column">
              <ListTitle mt={20}>{i18n.t('dashboard.myTasks')} <span>{target.myTasksLength}</span></ListTitle>
              <TargetList color={'#fafbfb'}>
                {
                  (target.myTasks || []).map((item, index) => {
                    if (item.tasks.length > 0) {
                      cnt++;
                      if (!showAll && cnt > 2) return;
                      return (
                        <TaskList data={item} isMyTask={true} key={index} markTaskAsComplete={markTaskAsComplete}/>
                      )
                    }
                  })
                }
              </TargetList>
            </div>
            <div className="col-xl-6 col-lg-12 d-flex flex-column">
              <ListTitle mt={20}><img src='/images/Solutions/Product_Cloudmeb.svg' />{i18n.t('dashboard.assingedTasks')} <span>{target.teamTasksLength}</span></ListTitle>
              {
                <TargetList color={'#fafbfb'}>
                  {
                    target.teamTasksLength > 0 ? (
                      <>
                        {
                          (target.teamTasks || []).map((item, index) => (item.tasks || []).length > 0 && (
                            <TaskList data={item} isMyTask={false} key={index} markTaskAsComplete={markTaskAsComplete}/>
                          ))
                        }
                      </>
                    ) : (
                      <NoTaskPanel/>
                    )
                  }
                </TargetList>
              }
            </div>
          </div>
        </Body>
      )}
      {isShowContent && (
        <Footer>
          {
            !showAll && !isAllCompleted &&
            <ShowMoreBtn
                className="upper-case"
                onClick={() => onClickMoreBtn(target.target_name)}
            >
              {i18n.t('dashboard.showMore')}
            </ShowMoreBtn>
          }
        </Footer>
      )}
    </Wrapper>
  )
};

export default TargetBlock;
