import i18next from "i18next";
import React, { Fragment } from "react";
import styled, { css } from "styled-components";
import i18n from "../../i18n";
//styles
const Wrapper = styled.div`
  background: linear-gradient(to right, ${props => props.bgColor} 50%, rgb(255, 255, 255) 50%) !important;
  position: relative;
  
  display: flex;
  align-items: center;
  padding: 30px;

  font-family: Circe-Regular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5e7599;
  
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    background: rgba(234, 249, 250, 0.9) !important;
    flex-wrap: wrap;
    justify-content: center;
    padding: 30px 0;
    border: 0;
  }
`;

const Copyright = styled.div`
  margin-right: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
  
  > p {
    margin: 0;
    text-align: center;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    width: 100%;
    justify-content: center;
    
    p {
      font-size: 28px;
      letter-spacing: -0.2px;
    }
    
    p:first-child > span {
      display: none;
    }
    
    img {
      transform: scale(2);
      margin: 0 8px 5px 9px;
    }
  }
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-bottom: 28px;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    p {
      font-size: 24px;
      margin-bottom: 20px;
    }
  }
`

const RightItem = styled.div`
  margin-left: 30px;
  cursor: pointer;
  font-weight: bold;
  
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    font-size: 29px;
    font-weight: bold;
    margin: 18px 19px;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    font-size: 27px;
    margin: 0 19px;
  }
  
   @media (max-width: ${props => props.theme.breakpoints.sm}) {
      font-size: 24px;
      margin-bottom: 20px;
  }
`

const Footer = ({path}) => {
  let bgColor = '#f7fdfd';
  if(path.includes('sign-up/5')) {
    bgColor = '#f3f8f1';
  }

  return (
    <Wrapper bgColor={bgColor}>
      <Copyright>
        <p>2021 © {i18n.t('auth.footer.cloudmeb')}. <span>{i18n.t('auth.footer.allrightReserved')}</span></p>
        <p> {i18n.t('auth.footer.proudly')} <span><img src="/images/footer/icon_ca.svg"/> {i18n.t('auth.footer.canadian')}</span> {i18n.t('auth.footer.owned')}</p>
      </Copyright>
      <RightItem>{i18n.t('auth.footer.termsUse')}</RightItem>
      <RightItem>{i18n.t('auth.privacyPolicy')}</RightItem>
    </Wrapper>
  )
}

export default Footer;
