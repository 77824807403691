import React from "react";
import styled, {css} from "styled-components";
import CheckAction from "@/components/CheckAction";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid #e5dfde;
  background: ${props => props.color || 'none'};
  
  &:last-child {
    border-bottom: none;
  }
`;

const Right = styled.div`
  display: flex;
  max-height: 20px;
  align-items: center;
  
  img {
    width: 20px;
    height: 20px;
    min-width: 20px;
    margin-left: 10px;
  }
  
  .icon_team_member {
    border-radius: 50%;
  }
`;

const DateView = styled.p`
  color: ${props => props.color || '#919498'};
  white-space: nowrap;
  font-size: 12px;
`;

const PTView = styled.p`
  margin-left: 10px;
  display: flex;
  align-items: center;
  flex-wrap: no-wrap;
  white-space: nowrap;
  color: #d88f32;
`;

const PTIcon = styled.span`
  width: 7px;
  height: 7px;
  background-color: #eea950;
  border-radius: 10px;
  margin-right: 5px;
  margin-bottom: 2px;
`;

const ArrowIcon = styled.span`
  width: 15px;
  min-width: 15px;
  height: 15px;
  border-radius: 2px;
  border: 1px solid #f3eef0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
  
  .fa-long-arrow-alt-right {
    transform: rotate(-45deg);
    font-size: 12px;
    color: #97a2b6;
  }
  
  &:hover {
    border: none;
    background: #5e7599;
    
    .fa-long-arrow-alt-right {
      color: #f1f3f6;
    }
  }
  
  ${props => props.expired === true && css`
    border: 1px solid #d57c6f;
    background: #d37466;
    
    .fa-long-arrow-alt-right {
      color: #fbf3f2;
    }
  `}
`;

const Content = styled.a`
  display: flex;
  flex: 1;
  justify-content: space-between;
  position: relative;
  height: 100%;
  padding: 15px 10px 15px 10px;
  cursor: pointer;
  
  &:hover {
    background-color: ${props => props.hoverColor || '#f0fafb'};
    
    .arrow-icon {
      border: none;
      background: #5e7599;
      
      .fa-long-arrow-alt-right {
        color: #f1f3f6;
      }
    }
    
    ${props => props.isExpired && css`
      .arrow-icon {
        border: none;
        background: #fff;
        
        .fa-long-arrow-alt-right {
          color: #2b3138;
        }
      }
    `}
    
    ${props => !props.isExpired && css`
      p {
        color: #415c85;
      }
    `}
  }
  
`;

const Desc = styled.div`
  display: flex;
  font-family: Circe-Regular;
  padding-right: 10px;
  color: ${props => props.color || '#181e26'};
  
  .decorationText {
    text-decoration: line-through;
  }
`;

const TargetItem = ({data, state, isMyTask, markTaskAsComplete}) => {
  let backgroundColor, actionBgColor, hoverColor, dateColor, descColor, titleColor='#181e26', subTitleColor='#8d9bb0';

  switch (state) {
    case 'EXPIRED':
      backgroundColor = '#d37466';
      descColor       = '#fff';
      dateColor       = '#fff';
      titleColor      = '#fff';
      subTitleColor   = '#e6c4c0';
      actionBgColor   = '#d37466';
      hoverColor      = '#c37167';
      break;
    case 'COMPLETED':
      dateColor       = '#a2a5a8';
      descColor       = '#8b8e93';
      actionBgColor   = '#fff';
      titleColor      = '#a3a5a9';
      subTitleColor   = '#d2d7e0';
      break;
  }

  if(state === 'Today') {
    backgroundColor = '#fefaee';
    actionBgColor   = '#fefaee';
    dateColor       = '#d37466';
  }

  const onActionProcess = (mode) => {
    markTaskAsComplete(data.objectId, mode)
  };

  const contentOfItem = () => {
    return (
      <>
        <Desc color={descColor}><p className={state === 'COMPLETED' ? 'decorationText' : ''}>{data.desc}</p></Desc>
        <Right>
          <DateView color={dateColor}>{data.date}</DateView>
          {
            isMyTask ?
              state !== 'EXPIRED' && data.price !== null &&
              <PTView><PTIcon/>{data.price}</PTView>
              :
              <img src={data.team_member_image} className="icon_team_member" alt="Team Member Image" />
          }
          <ArrowIcon className={'arrow-icon'} expired={state === 'EXPIRED'}><i className="fa fa-long-arrow-alt-right" /></ArrowIcon>
        </Right>
      </>
    )
  }

  return (
    <Wrapper color={backgroundColor}>
      {
        isMyTask && (
          <CheckAction
            bgColor={actionBgColor}
            hoverColor={hoverColor}
            value={state === 'COMPLETED'}
            isExpired={state === 'EXPIRED'}
            onActionProcess={(mode) => onActionProcess(mode)}
          />
        )
      }
      {data.link ? (
        <Content hoverColor={hoverColor} isExpired={state === 'EXPIRED'} href={data.link} target="_blank">
          {contentOfItem()}
        </Content>
      ) : (
        <Content hoverColor={hoverColor} isExpired={state === 'EXPIRED'}>
          {contentOfItem()}
        </Content>
      )}
    </Wrapper>
  )
}

export default TargetItem;
