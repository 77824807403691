import React, { useState } from "react";
import styled, { css } from "styled-components";
import i18n from '../../i18n';

const TemplateCard = ({data, toggleOpenModal}) => {
  const {
    Template_Logo__c,
    color_code_hover__c,
    Template_Title__c,
    template_sub_text__c,
    Category__c,
    Description__c,
    Price__c,
    CurrencyIsoCode,
  } = data;
  const [ isHovered, setHover ] = useState(false);

  return (
    <Container onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <Header style={{backgroundColor: isHovered ? '#5e7599' : 'white'}}>
        {
          <Icon src={Template_Logo__c} />
        }
        <div>
          <h3 style={{color: isHovered ? 'white' : '#405c85'}}>{Template_Title__c}</h3>
          <p style={{color: isHovered ? 'white' : '#97a4b7'}}>{template_sub_text__c}</p>
        </div>
      </Header>
      <Content>
        <p className={'property'}>{Category__c}</p>
        <Desc>
          <p>
            {Description__c}
          </p>
        </Desc>
      </Content>
      <Footer>
        <div>
          <p className={'price'}>${Price__c}<span> {CurrencyIsoCode}</span></p>
        </div>
        <AddButton onClick={() => toggleOpenModal(true, data)}>{i18n.t('dashboard.add')}</AddButton>
      </Footer>
    </Container>
  );
}

//styles
const Container = styled.div`
  box-shadow: 0 0 20px #f5f5f5;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: 100%;

`

const Header = styled.div`
  display: flex;
  border-bottom: 1px solid #ebeef2;
  padding: 10px 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: none;

  h3 {
    margin-left: 10px;
    font-size: 16px;
  }

  p {
    margin-left: 10px;
    font-size: 12px;
  }

  > div {
    flex: 1
  }
`;

const Icon = styled.img`
  width: 28px;
  height: 28px;
  border-radius: 100%;
`

const Content = styled.div`
  flex: 1;
  padding: 10px 15px;

  .property {
    font-size: 11px;
    padding-bottom: 5px;
    color: #8295b0;
  }
`

const Desc = styled.div`
  display: flex;
  align-items: flex-start;

  span {
    font-size: 31px;
    line-height: 14px;
    margin-right: 5px;
  }

  p {
    font-size: 12px;
    line-height: 1.9;
    white-space: break-spaces;
  }
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 12px;
  background-color: #fbfefe;

  .price {
    font-size: 17px;
    font-weight: bold;
    color: #405c85;

    span {
      font-size: 12px;
      font-weight: 400;
    }
  }
`;

const AddButton = styled.button`
  font-size: 10px;
  padding: 4px 10px;
  font-family: Circe-Regular;
  background: none;
  border-radius: 3px;
  border: 1px solid #e3e7e8;
  cursor: pointer;
  color: #72767c;
  letter-spacing: 1px;
  
  &:hover {
    background-color: #415c85 !important;
    color: #fff;
    border-color: #415c85;
  }
`

export default TemplateCard;
