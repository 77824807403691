import React from "react";
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";
import { MenuItem } from "@material-ui/core";
import { STORE_KEYS } from '@/stores';
import Header from '../header';
import {
  Container, Content, SubPanel, Center
} from "../styles";
import {
  TextField, SaveBtn, CancelBtn
} from "./styles";
import i18n from '../../../i18n';

const Financial = (props) => {

  const {
    businessName, businessType, q1Data, q2Data, q3Data, nextYearEnd, fiscalYearEnd,
    phone, website, monthlyTransactions, salesTaxFrequency,
    updateBusinessName, updateBusinessType, updateFiscalYearEnd, updateSalesTaxFrequency,
    updateMonthlyTransactions, updatePhone, updateWebsite, updateProfile,
  } = props;

  const types = [
    {
      value: "Registered",
      label: "Registered"
    },
    {
      value: "Partnership",
      label: "Partnership"
    },
    {
      value: "Incorporated",
      label: "Incorporated"
    },
    {
      value: "Non-Profit",
      label: "Non-Profit"
    },
    {
      value: "Freelancer",
      label: "Freelancer"
    }
  ];

  const frequencies = [
    {
      value: "Weekly",
      label: "Weekly"
    },
    {
      value: "Monthly",
      label: "Monthly"
    },
    {
      value: "Quarterly",
      label: "Quarterly"
    },
    {
      value: "Yearly",
      label: "Yearly"
    }
  ];

  const transactions = [
    {
      value: "1-50 Transactions",
      label: "1-50"
    },
    {
      value: "50-100 Transactions",
      label: "50-100"
    },
    {
      value: "100-250 Transactions",
      label: "100-250"
    },
    {
      value: "250-500 Transactions",
      label: "250-500"
    },

    {
      value: "500-1000 Transactions",
      label: "500-1000"
    }
  ];

  return (
    <Container>
      <Header item="financial"/>
      <Content>
        <Center>
          <div className="wrapper">
            <SubPanel>
              <div className="title">{i18n.t('dashboard.business')}</div>
              <div className="content mb-4">
                <div className="row">
                  <div className="col-lg-3 col-sm-6 mb-4">
                    <TextField
                      id="company-name"
                      label={i18n.t('auth.companyName')}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={businessName}
                      onChange={(e) => updateBusinessName(e.target.value || '')}
                    />
                  </div>
                  <div className="col-lg-3 col-sm-6 mb-4 mb-lg-0">
                    <TextField
                      id="type"
                      select
                      label={i18n.t('dashboard.type')}
                      onChange={(e) =>updateBusinessType(e.target.value || '')}
                      variant="outlined"
                      m={2}
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={businessType}
                    >
                      {types.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <div className="col-lg-3 col-sm-6 mb-4 mb-lg-0">
                    <TextField
                      id="phone"
                      label={i18n.t('auth.phone')}
                      variant="outlined"
                      // helperText="Wrong phone format"
                      // error
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={phone}
                      onChange={(e) => updatePhone(e.target.value || '')}
                    />
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <TextField
                      id="website"
                      label={i18n.t('dashboard.website')}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={website}
                      onChange={(e) => updateWebsite(e.target.value || '')}
                    />
                  </div>
                </div>
              </div>
            </SubPanel>
            <SubPanel>
              <div className="title">{i18n.t('dashboard.fiscalMilestones')}</div>
              <div className="content mb-5">
                <div className="row">
                  <div className="col-lg-2 col-sm-6 mb-4 mb-lg-0">
                    <TextField
                      id="new-year-end"
                      label={i18n.t('dashboard.prevFiscal')}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={fiscalYearEnd}
                      onChange={(e) => updateFiscalYearEnd(e.target.value)}
                    />
                  </div>
                  <div className="col-lg-2 col-sm-6 mb-4 mb-lg-0">
                    <TextField
                      id="q1"
                      label="Q1"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      disabled
                      value={q1Data}
                    />
                  </div>
                  <div className="col-lg-2 col-sm-6 mb-4 mb-lg-0">
                    <TextField
                      id="q2"
                      label="Q2"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      disabled
                      value={q2Data}
                    />
                  </div>
                  <div className="col-lg-2 col-sm-6 mb-4 mb-lg-0">
                    <TextField
                      id="q3"
                      label="Q3"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      disabled
                      value={q3Data}
                    />
                  </div>
                  <div className="col-lg-2 col-sm-6">
                    <TextField
                      id="fiscal-year-end"
                      label={i18n.t('dashboard.nextFiscal')}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      disabled
                      value={nextYearEnd}
                      onChange={(e) => updateFiscalYearEnd(e.target.value || '')}
                    />
                  </div>
                </div>
              </div>
            </SubPanel>
            <SubPanel>
              <div className="row">

                <div className="col-xl-4 col-lg-5 col-sm-6 mb-5">
                  <div className="title">{i18n.t('auth.salesTax')}</div>
                  <div className="row">
                    <div className="col-lg-8">
                      <TextField
                        id="frequency"
                        select
                        label={i18n.t('dashboard.frequency')}
                        variant="outlined"
                        onChange={(e) => updateSalesTaxFrequency(e.target.value)}
                        InputLabelProps={{
                          shrink: true
                        }}
                        value={salesTaxFrequency}
                      >
                        {frequencies.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>

                  </div>
                </div>

                <div className="col-xl-4 col-lg-5 col-sm-6 mb-5">
                  <div className="title">{i18n.t('auth.monthlyTransactions')}</div>
                  <div className="row">
                    <div className="col-lg-8">
                      <TextField
                        id="frequency"
                        select
                        label={i18n.t('dashboard.ofTransactions')}
                        variant="outlined"
                        value={monthlyTransactions}
                        onChange={(e) =>updateMonthlyTransactions(e.target.value || '')}
                        InputLabelProps={{
                          shrink: true
                        }}
                      >
                        {transactions.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>

                  </div>
                </div>
              </div>
            </SubPanel>
            <div className="d-flex">
              <CancelBtn>{i18n.t('dashboard.cancel')}</CancelBtn>
              <SaveBtn onClick={() => updateProfile()}>{i18n.t('dashboard.save')}</SaveBtn>
            </div>

          </div>
        </Center>
      </Content>
    </Container>
  )
};

export default compose(
  inject(STORE_KEYS.SALESFORCESTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.SALESFORCESTORE]: {
         businessName,
         businessType,
         q1Data,
         q2Data,
         q3Data,
         nextYearEnd,
         fiscalYearEnd,
         phone,
         website,
         monthlyTransactions,
         totalPoints,
         salesTaxFrequency,
         updateBusinessName,
         updateBusinessType,
         updateFiscalYearEnd,
         updateSalesTaxFrequency,
         updateMonthlyTransactions,
         updatePhone,
         updateWebsite,
         updateProfile,
       },
     }) => ({
      businessName,
      businessType,
      q1Data,
      q2Data,
      q3Data,
      nextYearEnd,
      fiscalYearEnd,
      phone,
      website,
      monthlyTransactions,
      totalPoints,
      salesTaxFrequency,
      updateBusinessName,
      updateBusinessType,
      updateFiscalYearEnd,
      updateSalesTaxFrequency,
      updateMonthlyTransactions,
      updatePhone,
      updateWebsite,
      updateProfile,
    })
  )
)(Financial);
