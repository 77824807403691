import React from "react";
import {Carousel} from "react-bootstrap";

//components
import SpecialServiceCard from "../../../components/dashboard/solutions/SpecialServiceCard";
import ServiceCard from "../../../components/services/service-card";
import i18n from '../../../i18n'; 
//styles
import { Content, Left, Center, SubPanel } from "../../styles";
import { SpecialCardPanel, LeftCard } from "../styles";
import AddServiceDialog from "../../../components/dialogs/AddServiceDialog";

const Services = (props) => {

  const { data: services, spData: specialOfferData } = props;

  const [openModal, setOpenModal] = React.useState(false);

  const [activeItem, setActiveItem] = React.useState(null);

  const toggleOpenModal = (isOpen, item) => {
    setOpenModal(isOpen);
    setActiveItem(item);
  };

  return (
    <Content>
      <Left isMobile={true}>
        <div className={'wrapper'}>
          <LeftCard>
            <div className={'brand'}>
              <img src="/images/dashboard/solutions/img_services_005.png" alt=""/>
            </div>
            <div className={'description'}>
              <p>{i18n.t('dashboard.managingAccounting')}</p>
            </div>
          </LeftCard>
        </div>
      </Left>
      <Center smPadding>
        <div className={'wrapper'}>
          <AddServiceDialog
            open={openModal}
            item={activeItem}
            handleClose={() => setOpenModal(false)}
          />
          <SpecialCardPanel>
            <div className={'title'}>{i18n.t('dashboard.specialOffers')}</div>
            <div className={'row mobile-hidden'}>
              {
                specialOfferData.map((item, index) => {
                  return (
                    <div className="col-lg-6 mb-4" key={index}>
                      <SpecialServiceCard data={item} toggleOpenModal={toggleOpenModal}/>
                    </div>
                  )
                })
              }
            </div>
            <div className="mobile-show">
              <Carousel
                  nextIcon={null}
                  prevIcon={null}
                  className={'d-flex'}
              >
                {
                  specialOfferData.map((item, index) => {
                    return (
                        <Carousel.Item>
                          <div className={"pb-5 h-100"}>
                            <SpecialServiceCard data={item} toggleOpenModal={toggleOpenModal}/>
                          </div>
                        </Carousel.Item>
                    )
                  })
                }
              </Carousel>
            </div>
          </SpecialCardPanel>
          <SubPanel>
            <div className={'title'}>{i18n.t('dashboard.allServices')}<span>{services.length}</span></div>
            <div className={'row'}>
              {
                services.map((product, index) => (
                  <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12 mb-4" key={index}>
                    <ServiceCard data={product} toggleOpenModal={toggleOpenModal} />
                  </div>
                ))
              }
            </div>
          </SubPanel>
        </div>
      </Center>
      <Left isMobile={false}>
        <div className={'wrapper'}>
          <LeftCard>
            <div className={'brand'}>
              <img src="/images/dashboard/solutions/img_services_005.png" alt=""/>
            </div>
            <div className={'description'}>
              <p>{i18n.t('dashboard.managingAccounting')}</p>
            </div>
          </LeftCard>
        </div>
      </Left>
    </Content>
  )
}

export default Services;
