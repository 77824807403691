import styled, {css} from "styled-components";
import {Link} from "react-router-dom";

export const Container = styled.div`
  display: ${props => props.isShow ? 'flex' : 'none'};
  position: fixed;
  z-index: 1100;
  padding: 50px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0, 0.5);
  justify-content: center;
  align-items: center;
  
  button:disabled,
  button[disabled]{
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }
`;

export const Wrapper = styled.div`
  max-width: 946px;
  min-height: 374px;
  background: white;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: row;
  border-radius: 7px;
  overflow: hidden;
  
  ${props => props.isSuccessPanel && css`
    flex: unset;
    min-height: unset;
  `}
  
  @media(max-width: 880px) {
    width: 100%;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: column;
    max-width: 490px;
  }
`;

export const Left = styled.div`
  padding: 10px;
  width: 100%;
  
  @media(max-width: 880px) {
    width: 100%;
  }
`;

export const Title = styled.h3`
  font-size: 14px;
  color: #181e26;
`;

export const Name = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px 0 20px 0;
  
  img {
    width: 28px;
    margin-right: 10px;
  }
  
  .name {
    font-family: Circe-Bold;
    font-size: 18px;
    color: #415c85;
   
    .type {
      color: #98a7be;
      margin: 10px 0 20px 0;
    }
  }
`;

export const VideoPanelWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 250px;
  background: #84868a;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Message = styled.div`
  flex: 1;
`;

export const Right = styled.div`
  background: #fbfefe;
  padding: 20px;
  min-width: 275px;
  width: 275px;
  display: flex;
  flex-direction: column;
  
  ${props => props.isSuccess === true && css`
    width: 330px;
    min-width: 330px;
  `}
  
  .description {
    color: #191f27;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
  }
`;

export const Header = styled.div`
  margin-top: 13px;
`;

export const Description = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 60px;
  
  p {
    color: #191f27;
    white-space: pre-wrap;
  }
`;

export const Info = styled.div`
  width: 100%;
  font-size: 15px;
  
  p {
    color: #191f27;
    margin-bottom: 20px;
    
    a {
      color: #415c85;
      font-family: Circe-Bold;
      border-bottom: 2px solid #8d9bb0;
      cursor: pointer;
      display: inline-block;
    }
  }
  
  .bold {
    font-weight: bold;
    color: #1d232b;
    font-size: 18px;
  }
`;

export const Price = styled.div`
  font-family: Circe-ExtraBold;
  font-size: 36px;
  color: #415c85;
  margin-bottom: 15px;
  
  span {
    font-size: 18px;
    color: #8d9bb0;
    vertical-align: middle;
  }
  
  .currency {
    color: #5e7599;
  }
`;

export const Warning = styled.div`
  background: #fdf6e2;
  padding: 15px 10px;
  margin-bottom: 10px;
  p {
    color: #191f27;
  }
`;

export const CardInfoText = styled.p`
  font-size: 11px;
  margin-top: 20px;
  color:  #8093af;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const CancelButton = styled.button`
  font-family: Circe-Regular;
  font-size: 14px;
  color: #5e7599;
  background: #f5f7f9;
  width: 70px;
  height: 40px;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  border: none;
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 47%;
  }
`;

export const PayButton = styled.button`
  font-family: Circe-Regular;
  font-size: 14px;
  color: #fff;
  background: #415c85;
  width: 130px;
  height: 40px;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  border: none;
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 47%;
  }
`;

export const AddButton = styled(Link)`
  font-family: Circe-Regular;
  font-size: 14px;
  color: #fff;
  background: #415c85;
  width: 130px;
  height: 40px;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 47%;
  }
`;

export const CloseButton = styled.button`
  font-family: Circe-Regular;
  font-size: 14px;
  color: #fff;
  background: #415c85;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  border: none;
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-top: 30px;
  }
`;
