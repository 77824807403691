import React from "react";
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";

import { STORE_KEYS } from '@/stores';
import Header from '../header';
import {
  Container, Content, SubPanel, Center, Button, WhiteButton, EditBtn, BillingInfoItem, LanguageSelect
} from "../styles";
import {
  TextFieldSpacing, TextField, SaveBtn, CancelBtn, GeneralInfo
} from "./styles";
import Select from "../../../components/common/Select";
import i18n from '../../../i18n';
import { WindowScroller } from "react-virtualized";

const Profile = (props) => {
  const lang = localStorage.getItem('lang');
  const [selectedLanguageId, setSelectedLanguageId] = React.useState(lang === 'null' || lang === 'undefined' ? 'en' : lang);
  const [isEditing, setIsEditing] = React.useState(false);
  const { username, email, updateProfileName, updateProfile } = props;
  
  const languageOptions = [
    {
      'id': 'de', 'name': 'Deutsch'
    },
    {
      'id': 'en', 'name': 'English'
    },
    {
      'id': 'es', 'name': 'Español'
    },
    {
      'id': 'fr', 'name': 'Français'
    },
    {
      'id': 'it', 'name': 'Italiano'
    },
    {
      'id': 'pt', 'name': 'Português'
    },
    {
      'id': 'chs', 'name': '汉语'
    },
    {
      'id': 'ja', 'name': '日本語'
    },
    {
      'id': 'ko', 'name': '한국어'
    },
  ];
  
  const onEditBtnClick = () => {
    setIsEditing(true);
  };

  const onSaveBtnClick = () => {
    setIsEditing(false);
    localStorage.setItem('lang', selectedLanguageId);
    updateProfile();
    window.location.reload();
  };

  const onCancelBtnClick = () => {
    setIsEditing(false);
  };

  return (
    <Container>
      <Header item="profile"/>
      <Content>
        <Center>
          <div className="wrapper">
            <SubPanel>
              <div className="title">
                {i18n.t('dashboard.general')}
                {
                  !isEditing &&
                  <EditBtn onClick={onEditBtnClick}>{i18n.t('dashboard.edit').toUpperCase()}</EditBtn>
                }
              </div>
              <div className="content mb-5">
                <GeneralInfo>
                  {
                    !isEditing ?
                      <>
                        <div className={'profile-info'}>
                          <div className="row flex-column">
                            <div className="col-sm-12 mb-4">
                              <BillingInfoItem>
                                <label className="label">{i18n.t('auth.name')}</label>
                                <p className="value">{ username || '_'}</p>
                              </BillingInfoItem>
                            </div>
                            <div className="col-sm-12">
                              <BillingInfoItem>
                                <label className="label">{i18n.t('auth.emailUpper')}</label>
                                <p className="value">{ email || '_'}</p>
                              </BillingInfoItem>
                            </div>
                          </div>
                        </div>
                      </>
                      :
                      <div className={'profile-info'}>
                        <div className="row flex-column">
                          <div className="col-sm-12 mb-4">
                            <TextField
                              id="name"
                              label={i18n.t('auth.name')}
                              variant="outlined"
                              onChange={(e) => updateProfileName(e.target.value || '')}
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={username}
                            />
                          </div>
                          <div className="col-sm-12">
                            <TextField
                              id="email"
                              label={i18n.t('auth.emailUpper')}
                              variant="outlined"
                              disabled={true}
                              // onChange={(e) => setEmail(e.target.value)}
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={email}
                            />
                          </div>
                        </div>
                      </div>
                  }
                </GeneralInfo>
                <LanguageSelect>
                  <Select
                    name="language"
                    label={i18n.t('dashboard.language')}
                    selectedOptionId={selectedLanguageId}
                    setOption={setSelectedLanguageId}
                    options={languageOptions}
                    placeholder={i18n.t('dashboard.chooseLanguage')}
                  />
                </LanguageSelect>
              </div>
            </SubPanel>
            <SubPanel>
              <div className="title">{i18n.t('auth.passwordUpper')}</div>
              <div className="content mb-5">
                <div className="row">
                  <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                    <TextField
                      id="current_password"
                      label={i18n.t('dashboard.currentPassword')}
                      variant="outlined"
                      type="password"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 col-md-4  col-sm-6 mb-4 mb-lg-0">
                    <TextField
                      id="new_password"
                      label={i18n.t('dashboard.newPassword')}
                      variant="outlined"
                      type="password"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </div>
                  <div className="col-lg-3 col-md-4  col-sm-6 mb-4 mb-lg-0">
                    <TextField
                      id="confirm_password"
                      label={i18n.t('dashboard.confirmPassword')}
                      variant="outlined"
                      type="password"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </div>
                </div>
              </div>
            </SubPanel>
            <div className="d-flex">
              <CancelBtn
                onClick={onCancelBtnClick}
              >
                {i18n.t('dashboard.cancel')}
              </CancelBtn>
              {
                (!isEditing && selectedLanguageId === '') ?
                  <SaveBtn
                    disabled={true}
                  >
                    {i18n.t('dashboard.save')}
                  </SaveBtn>
                  :
                  <SaveBtn
                    onClick={onSaveBtnClick}
                  >
                    {i18n.t('dashboard.save')}
                  </SaveBtn>
              }

            </div>

          </div>
        </Center>
      </Content>
    </Container>
  )
};

export default compose(
  inject(STORE_KEYS.AUTHSTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.AUTHSTORE]: {
         email,
         username,
         updateProfileName,
         updateProfile,
       },
     }) => ({
      email,
      username,
      updateProfileName,
      updateProfile,
    })
  )
)(Profile);
