import React, {useEffect} from "react";
import styled, {css} from "styled-components";
import i18n from '../../../i18n';
import TaskList from "./TaskList";
import { ListTitle } from "../styles";

const ChecklistBlock = (props) =>{

  const Wrapper = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border-radius: 7px;
    margin-bottom: 30px;
    background-color: #fff;
    background-color: #fff;
    
  `;

  const {
    tasks: data, lengthOfMyTasks = 0, lengthOfTeamTasks = 0, markTaskAsComplete, requestCloudTasks, selectedTabId,
  } = props;

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    requestCloudTasks();
  }, []);

  return (
    <Wrapper>
      <div className="row">
        <div className="col-xl-6 col-lg-12 d-flex flex-column">
          <ListTitle>{i18n.t('dashboard.myTasks')} <span>{lengthOfMyTasks}</span></ListTitle>
          <TaskList selectedTabId={selectedTabId} data={data} markTaskAsComplete={markTaskAsComplete} />
        </div>
        <div className="col-xl-6 col-lg-12 d-flex flex-column">
          <ListTitle>{i18n.t('dashboard.teamTasks')} <span>{lengthOfTeamTasks}</span></ListTitle>
          <TaskList selectedTabId={selectedTabId} data={data} markTaskAsComplete={markTaskAsComplete} isTeam />
        </div>
      </div>
    </Wrapper>
  )
};

export default ChecklistBlock;
