import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";

import { STORE_KEYS } from '@/stores';

const ProtectedRoute = ({ isLoggedIn, component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    isLoggedIn ? <Component {...props} /> : <Redirect to='/' />
  )} />
)

export default compose(
  inject(STORE_KEYS.AUTHSTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.AUTHSTORE]: { isLoggedIn },
     }) => ({
      isLoggedIn,
    })
  )
)(ProtectedRoute);
