import React from 'react';
import GoogleLogin from 'react-google-login';
import { Wrapper } from './styles';

class GoogleLoginBtn extends React.Component {
  responseGoogle = (response) => {
    this.props.onProcessAuth(response);
  };

  render() {
    const { label } = this.props;
    return (
      <Wrapper>
        <GoogleLogin
          clientId="699438523299-pql4hnnj5ecjo73nmphfjjas189bn7er.apps.googleusercontent.com"
          className="googBtn"
          buttonText={label}
          onSuccess={this.responseGoogle}
          onFailure={this.responseGoogle}
          cookiePolicy={'single_host_origin'}
        />
      </Wrapper>
    );
  }
}

export default GoogleLoginBtn;
