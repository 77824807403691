import React from 'react';
import styled from 'styled-components';
import {compose, withProps} from "recompose";
import {inject, observer} from "mobx-react";

import Header from '@/components/header';
import Footer from '@/components/footer';
import DashHeader from '@/components/dashboard-header';
import DashFooter from '@/components/dashboard-footer';
import { STORE_KEYS } from '@/stores';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const AppContent = styled.div`
  display: flex;
  flex: 1;
  ${props => props.isLoggedIn && `
      padding-top: 50px;
  `}
`;

const Layout = (props) => {
  const { isLoggedIn } = props;
  return (
    <Root>
      {isLoggedIn ? (
        <DashHeader path={props.location.pathname}/>
      ) : (
        <Header path={props.location.pathname}/>
      )}
      <AppContent isLoggedIn={isLoggedIn}>
        {props.children}
      </AppContent>
      {isLoggedIn ? (
        <DashFooter path={props.location.pathname}/>
      ) : (
        <Footer path={props.location.pathname}/>
      )}
    </Root>
  )
};

export default compose(
  inject(STORE_KEYS.AUTHSTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.AUTHSTORE]: { isLoggedIn },
     }) => ({
      isLoggedIn,
    })
  )
)(Layout);
