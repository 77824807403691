import React, {Component, useEffect} from 'react';
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";
import i18n from '../../i18n';
import { STORE_KEYS } from '@/stores';
import {
  Container,
  Title,
  SubHeader,
  Content,
  SubPanel,
  Center,
} from './styles';
import SolutionPanel from "./SolutionPanel";
import TeamCard from "./TeamCard";

const Account = (props) => {
  const {
    requestProducts,
    requestServices,
    requestMyTemplates,
    requestProfile,
    products,
    services,
    myTemplates: templates,
    // team
    onboarding_avatar,
    onboarding_Company,
    onboarding_email,
    onboarding_calendly,
    bookkeeping_avatar,
    bookkeeper_Company,
    bookkeeper_Email,
    bookkeeper_Calendly,
    accounting_avatar,
    accountant_Name,
    accountant_email,
    accounting_calendly,
    legal_avatar,
    incorporation_Name,
    incorporation_email,
    incorporation_calendly,
  } = props;

  useEffect(() => {
    requestProducts();
    requestServices();
    requestMyTemplates();
    requestProfile();
  }, [])

  const myProducts = [];
  const myServices = [];
  const myTemplates = [];

  products.map((item, index) => {
    myProducts.push({
      id: index + 1,
      icon: item.CP_logo__c,
      name: item.Cloud_Product_Name__c,
      subscribed: item.Start_Date__c,
      status: item.Status__c,
      currency: item.CurrencyIsoCode,
      price: item.Price__c || 0,
      cancellable: true,
      link: '#',
      solutionId: item.Id,
      subscriptionId: item.Stripe_Subscription_ID__c,
      type: "product",
    });
  });
  myProducts.sort((a, b) => {
    return new Date(a.subscribed) - new Date(b.subscribed);
  });
  services.map((item, index) => {
    myServices.push({
      id: index + 1,
      icon: item.Service_Logo_url__c,
      name: item.Cloud_Service_name__c,
      subscribed: item.Start_Date__c,
      status: item.Status__c,
      currency: item.CurrencyIsoCode,
      price: item.Client_Price__c || 0,
      cancellable: true,
      link: '#',
      solutionId: item.Id,
      subscriptionId: item.Stripe_Subscription_ID__c,
      type: "service",
    });
  });
  myServices.sort((a, b) => {
    return new Date(a.subscribed) - new Date(b.subscribed);
  });
  templates.map((item, index) => {
    myTemplates.push({
      id: index + 1,
      icon: item.Cloud_template_logo__c,
      name: item.template_name__c,
      note: '',
      property: '',
      subscribed: item.Order_date__c,
      currency: item.CurrencyIsoCode,
      price: item.Price__c || 0,
      cancellable: false,
      link: item.Download_Link__c,
      solutionId: item.Id,
      subscriptionId: "One-Time",
      type: "template",
    });
  });
  const teamData = [
    {
      title: 'ONBOARDING',
      name: onboarding_Company,
      profile_img: onboarding_avatar,
      email: onboarding_email,
      calendly: onboarding_calendly,
      desc: 'Team member will be assigned when you order an onboarding service.',
      bgImage: '/images/dashboard/solutions/special_offer_3.png'
    },
    {
      title: 'BOOKKEEPING',
      name: bookkeeper_Company,
      profile_img: bookkeeping_avatar,
      email: bookkeeper_Email,
      calendly: bookkeeper_Calendly,
      desc: 'Team member will be assigned when you order an bookkeeping service.',
      bgImage: '/images/dashboard/solutions/img_services_002.png'
    },
    {
      title: 'ACCOUNTING',
      name: accountant_Name,
      profile_img: accounting_avatar,
      email: accountant_email,
      calendly: accounting_calendly,
      desc: 'Team member will be assigned when you order an accounting service.',
      bgImage: '/images/dashboard/solutions/img_location.png'
    },
    {
      title: 'LEGAL',
      name: incorporation_Name,
      profile_img: legal_avatar,
      email: incorporation_email,
      calendly: incorporation_calendly,
      desc: 'Team member will be assigned when you order an legal service.',
      bgImage: '/images/dashboard/solutions/img_services_005.png'
    }

  ];

  return (
    <>
      <Container>
        <SubHeader>
          <Title>{i18n.t('dashboard.mySolutions')}</Title>
        </SubHeader>
        <Content>
          <Center>
            <div className={'wrapper'}>
              <SubPanel className="mb-3">
                <div className={'title'}>{i18n.t('dashboard.myTeam')}</div>
                <div className={'content'}>
                  <div className="row">
                    {
                      teamData.map((item, index) => (
                        <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12 mb-4" key={index}>
                          <TeamCard data={item} />
                        </div>
                      ))
                    }
                  </div>
                </div>
              </SubPanel>
              <SubPanel>
                <div className={'title'}>{i18n.t('dashboard.mySolutions')}</div>
                <div className={'content'}>
                  <SolutionPanel data={myProducts} title={'Products'} />
                  <SolutionPanel data={myServices} title={'Services'} />
                  <SolutionPanel data={myTemplates} title={'Templates'} />
                </div>
              </SubPanel>
            </div>
          </Center>
        </Content>
      </Container>
    </>
  )
};

export default compose(
  inject(STORE_KEYS.MYSOLUTIONSSTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.MYSOLUTIONSSTORE]: {
         requestProducts,
         requestServices,
         requestMyTemplates,
         requestProfile,
         products,
         services,
         myTemplates,
         // team members
         onboarding_avatar,
         onboarding_Company,
         onboarding_email,
         onboarding_calendly,
         bookkeeping_avatar,
         bookkeeper_Company,
         bookkeeper_Email,
         bookkeeper_Calendly,
         accounting_avatar,
         accountant_Name,
         accountant_email,
         accounting_calendly,
         legal_avatar,
         incorporation_Name,
         incorporation_email,
         incorporation_calendly,
       },
     }) => ({
      requestProducts,
      requestServices,
      requestMyTemplates,
      requestProfile,
      products,
      services,
      myTemplates,
      // team members
      onboarding_avatar,
      onboarding_Company,
      onboarding_email,
      onboarding_calendly,
      bookkeeping_avatar,
      bookkeeper_Company,
      bookkeeper_Email,
      bookkeeper_Calendly,
      accounting_avatar,
      accountant_Name,
      accountant_email,
      accounting_calendly,
      legal_avatar,
      incorporation_Name,
      incorporation_email,
      incorporation_calendly,
    })
  )
)(Account);
