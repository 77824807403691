import React, { Fragment } from "react";
import styled, { css } from "styled-components";

const Container = styled.div`
`

const Label = styled.div`
  font-family: Circe-Regular;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #5e7599;
`

const StyledInput = styled.input`
  width: 100%;
  min-height: 50px;
  border-radius: 10px;
  background-color: rgba(65, 92, 133, 0.05);
  box-sizing: border-box;

  font-family: Circe-Regular;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #181e26;

  outline: none;
  border: 0;
  padding: 14px 15px;
  
   @media (max-width: ${props => props.theme.breakpoints.lg}) {
     max-width: unset;
  }
  
  @media (max-width: 576px) {
    min-height: unset;
    padding: 10px 15px;
  }
`

const Input = ({label, type, placeholder, onChange, value}) => {
  return (
    <Container>
      <Label> {label} </Label>
      <StyledInput type={type} placeholder={placeholder} onChange={onChange} value={value}/>
    </Container>
  )
}

export default Input;
