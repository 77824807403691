import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import ProtectedRoute from "@/containers/Routes/ProtectedRoute";
import Layout from "@/containers/Layout/dashboard";
import Login from "@/containers/Login";
import SignUp from "@/containers/Signup";
import Default from "@/containers/Dashboard";
import Targets from "@/containers/Targets";
import Checklist from "@/containers/Checklist";
import Education from "@/containers/Education";
import Solution from "@/containers/Solution";
import Billing from "@/containers/Settings/billing";
import Financial from "@/containers/Settings/financial";
import Profile from "@/containers/Settings/profile";
import MySolutions from "@/containers/MySolutions";
import XeroCallback from "@/containers/XeroCallback";
import ConfirmEmail from "@/containers/ConfirmEmail";
import VerifyEmail from "@/containers/Signup/confirm";

import Error from "@/containers/Error";
import Empty from "@/containers/Empty";

export default () => {
  return (
    <Router>
      <Switch>
        <Layout>
          <Route exact path="/" component={() => <Login/>}/>
          <Route exact path="/signup/:region" component={(props) => <SignUp { ...props } />}/>
          <Route exact path="/signup" component={() => <SignUp />}/>
          <Route path="/confirm/:unverified" component={(props) => <VerifyEmail { ...props } />} />
          <Route path={"/confirmation/:token"} component={(props) => <ConfirmEmail { ...props } />} />
          <Route exact path="/error" component={() => <Error />} />

          <ProtectedRoute exact path="/home" component={() => <Default />} />
          <ProtectedRoute exact path="/targets" component={() => <Targets />} />
          <ProtectedRoute exact path="/checklist" component={() => <Checklist />} />
          <ProtectedRoute exact path="/education" component={(props) => <Education {...props}/>} />
          <ProtectedRoute exact path="/solution" component={(props) => <Solution {...props}/>} />
          <ProtectedRoute path={"/xeroCallback"} component={(props) => <XeroCallback { ...props } />} />

          {/* Settings */}
          <ProtectedRoute exact path="/billing" component={(props) => <Billing {...props}/>} />
          <ProtectedRoute exact path="/financial" component={(props) => <Financial {...props}/>} />
          <ProtectedRoute exact path="/profile" component={(props) => <Profile {...props}/>} />
          <ProtectedRoute exact path="/support" component={() => <Empty />} />
          <ProtectedRoute exact path="/my-solutions" component={() => <MySolutions />} />
        </Layout>
      </Switch>
    </Router>
  );
};
