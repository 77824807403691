import styled from "styled-components";
import {Table, TableRow, TextField as MuiTextField} from "@material-ui/core";
import {spacing} from "@material-ui/system";
import {Button, WhiteButton} from "../styles";

export const TextFieldSpacing = styled(MuiTextField)(spacing);

export const TextField = styled(TextFieldSpacing)`
  width: 100%;
  font-family: Circe-Regular !important;
  
  .MuiInputBase-input {
    font-family: Circe-Regular;
    font-size: 18px;
    color: #181e26;
  }
  
  .MuiOutlinedInput-root {
    border-radius: 0px;
  }
  
  .MuiInputLabel-outlined {
    font-family: Circe-Regular;
    color: #576f93;
    font-size: 16px;
  }
  
  .MuiFormHelperText-root {
    font-family: Circe-Regular;
  }
  
  .MuiOutlinedInput-notchedOutline {
    border-color: #576f93;
  }
  
  .PrivateNotchedOutline-legendLabelled-3 {
    font-size: 14px;
  }
`;

export const SaveBtn = styled(Button)`
  padding: 10px 20px;
  margin-left: 30px;
`;

export const CancelBtn = styled(WhiteButton)`
  padding: 10px 20px;
`;

export const PaymentList = styled.div`
  display: flex;
  flex-wrap: wrap;
  
  @media(max-width: 450px) {
    justify-content: center;
    
    > div {
      margin-right: 0 !important;
      width: 100%;
    }
  }
`;

export const AddPaymentBtn = styled.div`
  width: 100px;
  max-width: 100px;
  height: 100px;
  background: #f9fafb;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  &:hover {
    background: #dce0e5;
  }
`;

export const InvoiceTable = styled(Table)`
  width: 40% !important;
  
  @media (max-width: 1600px) {
    width: 60% !important;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    width: 70% !important;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 100% !important;
  }
`;

export const CustomTableHeader = styled(TableRow)`
  th, td {
    border-bottom: 0;
    font-family: Circe-Regular;
    color: #8f9296;
    padding: 10px;
  }
  
  .no-left-padding {
    padding-left: 0;
  }
`;

export const CustomTableRow = styled(TableRow)`
  th, td {
    border-bottom: 0;
    font-family: Circe-Regular;
    color: #32383f;
    padding: 10px;
  }
  
  .no-left-padding {
    padding-left: 0;
  }
  
  .name {
    color: #75787d;
  }
`;

export const PlusIcon = styled.i`
  color: #5e7599;
`;
