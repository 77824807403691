import React, {useEffect} from "react";
import styled, {css} from "styled-components";
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";
import i18n from '../../i18n';
import { STORE_KEYS } from '@/stores';
import NotificationList from './NotificationList';

const Container = styled.div`
  display: ${props => props.isShow === true ? 'block' : 'none'};
  width: 245px;
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
  right: 10px;
  top: 45px;
  z-index: 100;
  box-shadow: 0px 3px 10px 0px #d8d8d8;
  border: 1px solid #e8ebef;
  background: white;
`;

const Header = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  background: white;
  color: #181e26;
  font-family: Circe-Bold;
  font-size: 20px;
  padding-left: 20px;
  border-bottom: 1px solid #f3f3f4;
`;

const Content = styled.div`
  background: white;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-top: 1px solid #e7e8e9;
`;

const ShowMoreBtn = styled.button`
  padding: 9px 10px 7px;
  color: #74787d;
  font-family: Circe-Bold;
  font-size: 12px;
  background: none;
  border-radius: 3px;
  border: 1px solid #e7e8e9;
  letter-spacing: 1px;
`;

const NotificationPanel = ({isShow, showToggle, notifications}) => {

  const [isShowAll, setIsShowAll] = React.useState(false);

  const toggleContainer = React.createRef();

  const onClickOutsideHandler = (event) => {
    if (isShow && toggleContainer.current && !toggleContainer.current.contains( event.target )) {
      showToggle(false);
      setIsShowAll(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', onClickOutsideHandler);
  });

  const data = [];
  (notifications || []).map((item, index) => {
    data.push({
      'title': item.Msg_Header__c || '-',
      'text': item.Message__c || '-',
      'date': item.Msg_Date__c || '-',
      'link': item.Link__c || '',
    })
  })

  return (
    <Container isShow={isShow} ref={toggleContainer}>
      <Header>{i18n.t('dashboard.notifications')}</Header>
      <Content>
        <NotificationList data={data} isShowAll={isShowAll}/>
      </Content>
      {
        !isShowAll &&
          <Footer>
            <ShowMoreBtn
                className="upper-case"
                onClick={() => setIsShowAll(true)}
            >
              {i18n.t('dashboard.showmore').toUpperCase()}
            </ShowMoreBtn>
          </Footer>
      }
    </Container>
  )
}

export default compose(
    inject(STORE_KEYS.NOTIFICATIONSTORE),
    observer,
    withProps(
        ({
           [STORE_KEYS.NOTIFICATIONSTORE]: {
             notifications,
           },
         }) => ({
          notifications,
        })
    )
)(NotificationPanel);
