import React from "react";
import styled from "styled-components";

const Container = styled.div`
  margin-top: 15px;
  position: relative;
  display: flex;
  align-items: center;
`

const Label = styled.label`
  font-family: Circe-Regular;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #181e26;
  
  width: 100%;
  padding-left: 34px;
  padding-right: 0;
  margin-right: 26px;
  cursor: pointer;
  display: flex;
  align-items: center;
  
  &::before {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: transparent;
    border: 2px solid ${props => props.checked == true ? '#8cc178': '#797c81'};
    box-sizing: border-box;
  }

  &::after {
    position: absolute;
    left: 7px;
    top: 50%;
    transform: translateY(-50%);
    content: '';
    display: inline-block;
    opacity: ${props => props.checked == true ? 1 : 0};
    width: 11px;
    height: 11px;
    border-radius: 50%;
    border-color: #8cc178;
    background-color: #8cc178;
    transition-duration: $duration;
  }

  &.invalid::before {
    border-color: $red;
  }
`

const StyledRadioButton = styled.input`
  display: none;
`

const RadioButton = ({label, name, value, checked, onChange}) => {
  
  let isChecked = checked == value;
 
  return (
    <Container>
      <Label checked={isChecked}>
        <StyledRadioButton
          type={'radio'}
          name={name}
          value={value}
          checked={isChecked}
          onChange={() => onChange(value)}
        />
        {label}
      </Label>
    </Container>
  )
}

export default RadioButton;
