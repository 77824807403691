import { SmallLinkButton } from "../styles";
import React from "react";
import i18n from '../../../i18n';
import { SvgCalendarIcon } from "@/components/SvgIcon";
import {
  Wrapper, CardHeader, CardBody, SmallButton, CalendarBtn
} from "./styles";

const TeamCard = ({data}) => {
  const [ isHovered, setIsHovered ] = React.useState(false);

  return (
    <Wrapper>
      <CardHeader>
        <p className="upper-case">{data.title}</p>
      </CardHeader>
      {
        data.name ?
          <CardBody>
            <div className={'body'}>
              <div className="d-flex align-items-center">
                <img src={data.profile_img} alt={'Profile Image'} className="profile_img" />
                <h3>{data.name}</h3>
              </div>
              <CalendarBtn
                  onMouseOver={() => setIsHovered(true)}
                  onMouseLeave={() =>setIsHovered(false)}
                  href={data.calendly}
                  target="_blank"
              >
                <SvgCalendarIcon isHovered={isHovered} />
              </CalendarBtn>
            </div>
            <div className={'footer mobile-hidden'}>
              <SmallLinkButton href={`mailto:${data.email}`} target="_blank">{i18n.t('auth.emailUpper').toUpperCase()}</SmallLinkButton>
              <SmallLinkButton href={data.calendly} target="_blank">{i18n.t('dashboard.Calendly').toUpperCase()}</SmallLinkButton>
            </div>
          </CardBody>
          :
          <CardBody bgImage={data.bgImage}>
            <div className={'content'}>
              <p>{data.desc}</p>
            </div>
          </CardBody>
      }

    </Wrapper>
  )
};

export default TeamCard;
