import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { PtsInfo } from "../styles";
import i18n from '../../../i18n';

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.white,
    },
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        textAlign: "center",
        marginTop: "6px"
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
}

export default function PtsInfoBox({ totalPoints, requestPtsRequest }) {
    const [isUpdate, setIsUpdated] = useState(false);
    useEffect(() => {
        setIsUpdated(!isUpdate);
    }, [totalPoints]);

    const onHandlePtsClick = () => {
        if (requestPtsRequest) {
            requestPtsRequest();
        }
    };

    return (
      <div>
          <BootstrapTooltip title={<div>{i18n.t('dashboard.youHave')} <b>{totalPoints || 0} {i18n.t('dashboard.points')}</b><br />{i18n.t('dashboard.clickPoints')}</div>}>
              <PtsInfo
                  animated={isUpdate}
                  onClick={onHandlePtsClick}
              >
                  <p>{totalPoints || 0} <span>PTS</span></p>
              </PtsInfo>
          </BootstrapTooltip>
      </div>
    );
}
