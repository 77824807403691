import React  from "react";
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";

import { STORE_KEYS } from '@/stores';
import VideoPanel from '../VideoPanel';
import {
  Container, Wrapper, Left, Title, Name, VideoPanelWrapper, Message, Right,
  Header, Description, Info, Price, Warning, CardInfoText, ButtonGroup, CancelButton,
  PayButton, CloseButton, AddButton
} from './styles';
import i18n from '../../../i18n';

const AddProductDialog = (props) => {
  const { item, last4, createSubscriptionProduct } = props; // product data
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isLock, setIsLock] = React.useState(false);
  const paymentIssue = last4 === '';
  const data =
    {
      id: 1,
      icon: item ? item.Product_Logo_url__c : '',
      name: item ? item.Name : '',
      type: item ? item.Product_Sub_text__c : '',
      desc: item ? item.Description__c : '',
      price: item ? item.Cloudmeb_Price__c : '',
      unit: item ? item.CurrencyIsoCode : '',
      frequency: item ? item.Frequency__c : '',
      init: item ? item.Initial_Payment__c : '',
      video: item ? item.Video_Link__c : '',
    };

  const handleClickPayBtn = () => {
    if (!isLock) {
      setIsLock(true);
      createSubscriptionProduct(item.id)
        .then(() => {
          setIsLock(false);
          setIsSuccess(true);
        })
        .catch((err) => {
          setIsLock(false);
          console.log('err:', err);
        });
    }
  };

  const handleClosePopup = () => {
    setIsSuccess(false);
    props.handleClose();
  };

  return (
    <Container isShow={props.open}>
      <Wrapper isSuccessPanel={isSuccess}>
        {
          !isSuccess &&
            <Left>
              <VideoPanelWrapper>
                <VideoPanel link={data.video}/>
              </VideoPanelWrapper>
            </Left>
        }
        <Right isSuccess={isSuccess}>
          <Header>
            <Title>{i18n.t('dashboard.subscribeProduct').toUpperCase()}</Title>
            <Name>
              <img src={data.icon} alt="Product Image" />
              <div className={'name'}>{data.name} <span className={'type'}>{data.type}</span></div>
            </Name>
          </Header>
          <Description success={isSuccess}>
            {
              !isSuccess ?
                <p>{data.desc}</p>
              :
                <>
                  <Info>
                    <p className={'bold'}>{i18n.t('dashboard.thankyouPayment')}</p>
                    <p>{i18n.t('dashboard.yourLoginCredentials')}</p>
                    <p>{`Manager your subscription under\n`} <a href={"/my-solutions"}>{`\nMy Solutions`}</a></p>
                  </Info>
                </>
            }

          </Description>
          {
            !isSuccess ?
              <div>
                <Price>${data.price} <span className="unit">{data.unit}</span> <span>/{data.frequency}</span></Price>
                {
                  paymentIssue &&
                  <Warning><p>{i18n.t('dashboard.paymentPurchase')}</p></Warning>
                }
                <ButtonGroup>
                  <CancelButton onClick={props.handleClose}>Cancel</CancelButton>
                  {
                    paymentIssue ? (
                      <AddButton to={'/billing'}>{i18n.t('dashboard.addMethod')}</AddButton>
                    ) : (
                      <PayButton onClick={() => handleClickPayBtn()} disabled={isLock}>{i18n.t('dashboard.payNow')}</PayButton>
                    )
                  }
                </ButtonGroup>
                {
                  !paymentIssue &&
                  <CardInfoText>{i18n.t('dashboard.cardCharged')} ${`${data.init} ${data.unit}`}.</CardInfoText>
                }
              </div>
              : <CloseButton onClick={() => handleClosePopup()}>{i18n.t('dashboard.close')}</CloseButton>
          }
        </Right>
      </Wrapper>
    </Container>
  )
};

export default compose(
  inject(STORE_KEYS.SALESFORCESTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.SALESFORCESTORE]: {
         last4,
         createSubscriptionProduct,
       },
     }) => ({
      last4,
      createSubscriptionProduct,
    })
  )
)(AddProductDialog);
