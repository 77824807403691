import React from "react";
import styled from "styled-components";

import {CalendarIcon} from "@/components/common/svgIcon";

const Wrapper = styled.a`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    
    .user-img {
      width: 35px;
      height: 35px;
      min-width: 35px;
      margin-right: 10px;
      border-radius: 50%;
    }
    
    &:hover {
      background: #f7fdfd;
      
      h3 {
        color: #41474e;
      }
      
      i {
        color: #181e26;
      }
      
    }
    
  `;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    
    h3 {
      font-size: 18px;
      color: #415c85;
    }
    
    p {
      color: #9eaabc;
      font-size: 11px;
    }
  `;

const MyTeamItem = ({data}) => {
  const [ isHovered, setIsHovered ] = React.useState(false);
  const { img, default_img, name, property, calendly } = data;
  const isEmpty = name === '';

  const ItemContent = (
    <>
      <img src={!isEmpty ? img : default_img} alt={''} className="user-img"/>
      <Content>
        {!isEmpty && (
          <h3>{name || "-"}</h3>
        )}
        <p className="upper-case">{property}</p>
      </Content>
      {
        name && (
          <CalendarIcon isHovered={isHovered} />
        )
      }
    </>
  );

  return calendly ? (
    <Wrapper
        onMouseOver={() => setIsHovered(true)}
        onMouseLeave={() =>setIsHovered(false)}
        href={calendly}
        target="_blank"
    >
      {ItemContent}
    </Wrapper>
  ) : (
    <Wrapper
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() =>setIsHovered(false)}
    >
      {ItemContent}
    </Wrapper>
  )
};

export default MyTeamItem;
