import React from "react";

export const TaskCompletedIcon = (props) => {

  const {state, isHovered, onAlertAction, ...rest} = props;

  return (
      <div onClick={() => onAlertAction()}>
        {
          state === 'EXPIRED' &&
          <img src={isHovered ? "/images/icons/Icon_Tasks_Completed_Dark.svg" : "/images/icons/Icon_Tasks_Todo_Dark.svg"} {...rest} alt=""/>
        }
        {
          state === 'COMPLETED' &&
          <img src={isHovered ? "/images/icons/Icon_Tasks_Completed_Hover.svg" : "/images/icons/Icon_Tasks_Completed.svg"} {...rest} alt=""/>
        }
        {
          state !== 'COMPLETED' && state !== 'EXPIRED' &&
          <img src={isHovered ? "/images/icons/Icon_Tasks_Todo_Hover.svg" : "/images/icons/Icon_Tasks_Todo.svg"} {...rest} alt=""/>
        }
      </div>
  )
};

export const TaskOpenIcon = (props) => {

  const {state, isHovered, onAlertAction, ...rest} = props;

  return (
      <div onClick={() => onAlertAction()}>
        {
          state === 'EXPIRED' ?
              <img src={isHovered ? "/images/icons/Icon_Open_Dark_Hover.svg" : "/images/icons/Icon_Open_Dark.svg"} {...rest} alt=""/>
              :
              <img src={isHovered ? "/images/icons/Icon_Open_Hover.svg" : "/images/icons/Icon_Open.svg"} {...rest} alt=""/>
        }
      </div>
  )
};

export const CalendarIcon = ({isHovered}) => {

  return (
    <>
      <img src={isHovered ? "/images/icons/Icon_Calendar_Dark.svg" : "/images/icons/Icon_Calendar.svg"} alt=""/>
    </>
  )
};

export const GoogleDriveIcon = (props) => {

  return (
    <>
      <svg width="19px" height="16px" {...props} viewBox="0 0 19 16">
        <title>Icon — Google Drive</title>
        <g id="Design-System" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Backend-Design-System/Atoms/Icons" transform="translate(-65.000000, -911.000000)">
            <g id="Other" transform="translate(45.000000, 741.000000)">
              <g id="Google-Drive" transform="translate(0.000000, 154.000000)">
                <g id="Group" transform="translate(20.000000, 15.000000)">
                  <rect id="Area" x="0" y="0" width="18" height="18"/>
                  <path d="M17.6712517,11.6666667 C17.8592695,11.6666667 18.0332428,11.7656533 18.129065,11.9265778 C18.2254206,12.0880356 18.2295806,12.2880356 18.1405495,12.4536533 L18.1405495,12.4536533 L15.8363717,16.72032 C15.743145,16.8926933 15.5629495,17 15.3671095,17 L15.3671095,17 L4.40590059,17 L7.48778503,11.6666667 Z M5.47676281,2.59811556 L8.47267392,7.78193778 L3.29955392,16.7332978 C3.20426503,16.8984178 3.02769614,16.9999644 2.83758059,16.9999644 L2.83758059,16.9999644 C2.63651392,16.9973689 2.45994503,16.8900622 2.36828281,16.7202844 L2.36828281,16.7202844 L0.0641050325,12.4536178 C-0.0238949675,12.2900978 -0.021299412,12.0932267 0.0719272547,11.9328 L0.0719272547,11.9328 L5.47676281,2.59811556 Z M11.4952161,1 C11.6852961,1 11.8613673,1.10154667 11.9566561,1.26613333 L11.9566561,1.26613333 L17.3610295,10.6 L11.3618828,10.6 L6.27594503,1.8 C6.18062059,1.63488 6.18065614,1.43178667 6.27594503,1.26666667 C6.37123392,1.10154667 6.54730503,1 6.73791837,1 L6.73791837,1 Z" id="Combined-Shape" fill={props.color || '#181E26'} fillRule="nonzero"/>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </>
  )
};


