import React from "react";
import styled from "styled-components";
import { Doughnut } from "react-chartjs-2";

const CircleProgressBar = ({ value = 0, size = 36, colors = [ '#5d7599', '#eaf8fa'], cutout = 80 ,labelSize}) => {

  const data = {
    datasets: [
      {
        data: [value, 100 - value],
        backgroundColor: [
          colors[0],
          colors[1],
        ],
        borderColor: "transparent"
      }
    ]
  };

  const options = {
    maintainAspectRatio: false,
    cutoutPercentage: cutout,
    legend: {
      display: false
    },
    redraw : false,
    tooltips: {
      enabled: false
    }
  };
  return (
    <Container size={size}>
      <Doughnut type width={size} height={size}  data={data} options={options} />
      <Label fontSize={labelSize}>{value}%</Label>
    </Container>
  )
};

//styles
const Container = styled.div`
  height: ${props => props.size ? props.size : 36}px;
  width: ${props => props.size ? props.size : 36}px;
  position: relative;
`

const Label = styled.p`
  font-family: Circe-ExtraBold;
  color: #415c85;
  font-size: ${props => props.fontSize ? props.fontSize : 12}px;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
`

export default CircleProgressBar;
