import React, { Fragment } from "react";
import styled, { css } from "styled-components";
import {findPositionOfBar} from "recharts/lib/util/ChartUtils";

//styles
const Wrapper = styled.div`
  width: 100%;
  height: 24px;
  position: relative;
`;

const CurrentIcon = styled.div`
  position: relative;
  position: absolute;
  left: ${props => props.position == '100%' ? `calc(100% - 24px)` : props.position};
  top: 50%;
  &::before {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: white;
    border: 4px solid #8cc178;
    box-sizing: border-box;
  }
  &::after {
    position: absolute;
    left: 7px;
    top: 50%;
    transform: translateY(-50%);
    content: '';
    display: inline-block;
    opacity: 1;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border-color: #8cc178;
    background-color: #c5dfbb;
  }
`;

const UncompletedIcon = styled(CurrentIcon)`
  position: absolute;
  top: 50%;
  left: ${props => props.position == '100%' ? `calc(100% - 17px)` : props.position};

  &::before {
    width: 17px;
    height: 17px;
    border: 4px solid #E2E8E0;
  }
  &::after {
    opacity: 0;
  }
`;

const Line = styled.div`
  width: 33%;
  height: 2px;
  background: ${props => props.type == `active` ? `#8cc178` : `#e2e8e0`}
`;

const LineContainer = styled.div`
  width: 100%;
  height: 2px;
  background: #e2e8e0;
  display: flex;
  position: absolute;
  top: 50%;
`;

const FinishedIcon = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #8cc178;
  left: ${props => props.position == '100%' ? `calc(100% - 24px)` : props.position};
`;

const Completed = styled.i`
  color: white;
`;

const Finished = (props) => {
  return (
    <FinishedIcon position={props.position}>
      <Completed className="fa fa-check" aria-hidden="true"></Completed>
    </FinishedIcon>
  );
};


const SignUpStatusBar = ({step}) => {

  let icons, lines;

  switch (step) {
    case 1:
      icons = <>
        <CurrentIcon position = {`0%`}></CurrentIcon>
        <UncompletedIcon position = {`32%`} />
        <UncompletedIcon position = {`64%`} />
        <UncompletedIcon position = {`100%`} /> </>;
      lines = <LineContainer>
        <Line type = {`inactive`}/>
        <Line type = {`inactive`}/>
        <Line type = {`inactive`}/>
      </LineContainer>;
      break;

    case 2:
      icons = <>
        <Finished position={`0%`}/>
        <CurrentIcon position={`32%`} />
        <UncompletedIcon position = {`64%`} />
        <UncompletedIcon position={`100%`} /> </>;
      lines = <LineContainer>
        <Line type = {`active`}/>
        <Line type = {`inactive`}/>
        <Line type = {`inactive`}/>
      </LineContainer>;

      break;

    case 3:
      icons = <>
        <Finished position={`0%`} />
        <Finished position={`32%`} />
        <CurrentIcon position = {`64%`} />
        <UncompletedIcon position={`100%`} /> </>;
      lines = <LineContainer>
        <Line type = {`active`}/>
        <Line type = {`active`}/>
        <Line type = {`inactive`}/>
      </LineContainer>;

      break;

    case 4:
      icons = <>
        <Finished position={`0%`} />
        <Finished position={`32%`} />
        <Finished position = {`64%`} />
        <CurrentIcon position={`100%`} /> </>;
      lines = <LineContainer>
        <Line type = {`active`}/>
        <Line type = {`active`}/>
        <Line type = {`active`}/>
      </LineContainer>;

      break;
  }

  return (
    <Wrapper>
      {lines}
      {icons}
    </Wrapper>
  )
}

export default SignUpStatusBar;
