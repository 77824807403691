import React, { useState, useEffect, Fragment } from "react";
import {Link, withRouter} from "react-router-dom";
import styled, { css } from "styled-components";
import LanguageSelect from "./LanguageSelect";
import i18n from "../../i18n";

//styles
const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: .5rem 1rem;
  min-width: 280px;
  background: linear-gradient(to right, ${props => props.bgColor} 50%, rgb(255, 255, 255) 50%) !important;
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    background: rgba(234, 249, 250, 0.9) !important;
    padding: 15px 30px;
    position: unset;
    height: 70px;
  }
  @media (max-width: 630px) {
    height: unset;
  }
  @media (max-width: 576px) {
    padding: 15px 20px;
  }
`;

const Wrapper5 = styled(Wrapper)`
  background: linear-gradient(to right, rgba(234, 249, 250, 0.5) 50%, rgb(255, 255, 255) 50%) !important;
`;

const Container = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    flex-wrap: nowrap;
  }
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    justify-content: flex-end;
    padding-right: 0;
    padding-left: 0;
  }
`;

const NavBarBrand = styled.a`
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  margin-bottom: 3px;
  color: rgba(0,0,0,.9);
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  text-decoration: none;
  background-color: transparent;
  img {
    vertical-align: middle;
    border-style: none;
  }
  .logo {
    height: 30px;
    margin-right: 4px;
    margin-top: -7px;
    @media (max-width: ${props => props.theme.breakpoints.xl}) {
      display: none;
    }
    
  }
  .logo-sm {
     display: none;
     @media (max-width: ${props => props.theme.breakpoints.xl}) {
        display: block;
        width: 210px;
    }
    @media (max-width: 576px) {
        width: 210px;
    }
    @media (max-width: 480px) {
        width: 175px;
    }
    @media (max-width: 430px) {
        width: 160px;
    }
    @media (max-width: 320px) {
        width: 110px;
    }
  }
  @media (max-width: 1200px) {
    position: absolute;
    left: 30px;
    top: 15px;
  }
  @media (max-width: 992px) {
    top: 30px;
  }
  @media (max-width: 630px) {
    top: 21px;
  }
  @media (max-width: 576px) {
    top: 20px;
  }
  @media (max-width: 480px) {
    top: 22px;
  }
  @media (max-width: 430px) {
    top: 23px;
    left: 15px;
  }
`;

const Collapse = styled.div`
  flex-grow: 1;
  align-items: center;
  @media (min-width: 992px) {
    display: flex!important;
    flex-basis: auto;
  }
  &:not(.show) {
    display: none;
  }
`;

const NavBarNav = styled.ul`
  margin-top: 0;
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin-right: auto!important;
  flex-direction: row;
  box-sizing: border-box;
  @media (max-width: 1200px) {
    margin-left: 65px;
  }
  @media (min-width: 992px) {
    -ms-flex-direction: row;
    flex-direction: row;
  }
`;

const NavItem = styled.li`
  margin-left: 20px;
  box-sizing: border-box;
  @media (max-width: 1200px) {
    margin-left: 10px;
  }
`;

const NavLink = styled.a`
  font-family: Circe-Bold;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #415c85;
  padding-left: 0;
  padding-right: 0;
  display: block;
  padding: .5rem 1rem;
  text-decoration: none;
  background-color: transparent;
  box-sizing: border-box;
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
  }
  @media (max-width: 992px) {
    text-align: left;
  }
`;

const NavBarPhone = styled.div`
  font-family: Circe-Regular;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #5e7599;
  margin-right: 20px;
  @media (max-width: 1400px) {
    display: none;
  }
`;

const NavBarBtn = styled.a`
  height: 50px;
  border-radius: 10px;
  background-color: #7632d2;
  padding: 9px 20px;
  font-family: Circe-Bold;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-left: 12px;
  border: 0;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
  box-sizing: border-box;
  
  :hover {
    color: #fff;
  }
`;

const NavBarBtnSm = styled.a`
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    display: none;
  }
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    width: 24%;
    max-width: 150px;
    height: 50px;
    font-size: 20px;
    border-radius: 12px;
    background: rgba(65, 92, 133, 0.05);
    font-family: Circe-Bold;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: center;
    color: #5e7599;
    margin-left: 20px;
    border: 0;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none !important;
      ${props =>
props.sign &&
css`
    font-family: Circe-Regular;
    width: 30%;
    max-width: 176px;
    background-color: #7632d2;
    color: #ffffff;
    margin-right: 3%;
  `
}
  }
  @media (max-width: 630px) {
    height: unset;
    padding: 7px 0;
    border-radius: 7px;
    font-size: 20px;
    margin-left: 3%;
  }
  @media (max-width: 576px) {
    font-size: 20px;
  }
  @media (max-width: 480px) {
    font-size: 20px;
  }
  @media (max-width: 430px) {
    font-size: 20px;
  }
  @media (max-width: 370px) {
    font-size: 19px;
  }
  @media (max-width: 320px) {
    font-size: 15px;
    width: 80px !important;
  }
`;

const NavBarToggle = styled.button`
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: .25rem;
  cursor: pointer;
  @media (min-width: 992px) {
    display: none;
  }
  @media (max-width: 992px) {
    border: none;
    outline: none;
    width: 7%;
    font-size: unset;
  }
  @media (max-width: 576px) {
    right: 20px;
  }
  @media (max-width: 430px) {
    right: 10px;
  }
  img {
    @media (max-width: 630px) {
      width: 30px;
    }
    @media (max-width: 576px) {
      width: 26px;
    }
    @media (max-width: 480px) {
      width: 23px;
    }
    @media (max-width: 430px) {
      width: 21px;
    }
    @media (max-width: 370px) {
      width: 20px;
    }
  }
`;

const ContainerSm = styled.div`
  display: none;
  @media (max-width: 992px) {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(33, 39, 49, 0.95);
    flex-direction: column;
    z-index: 1;
  }
`;

const ContainerSmHeader = styled.div`
  @media (max-width: 992px) {
    display: flex;
    padding: 15px 30px;
    align-items: center;
    justify-content: flex-end;
  }
`;

const ContainerSmContent = styled.ul`
  @media (max-width: 992px) {
    flex: 1;
    margin: auto;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    justify-content: center;
    text-align: center;
    padding: 0;
}
  li {
    margin: 10px 0;
    a {
      @media (max-width: 992px) {
        color: #eaf8fa;
        font-family: Circe-Regular;
        font-size: 64px;
        text-decoration: none;
      }
      @media (max-width: 576px) {
        font-size: 50px;
        line-height: 1;
      }
      @media (max-width: 480px) {
        font-size: 45px;
      }
      @media (max-width: 430px) {
        font-size: 40px;
      }
      @media (max-width: 370px) {
        font-size: 37px;
      }
      @media (max-width: 320px) {
        font-size: 35px;
      }
    }
  }
`;

const ContainerSmFooter = styled.div`
  @media (max-width: 992px) {
    display: flex;
    justify-content: space-between;
    padding: 0 30px 40px;
    align-items: flex-end;
    flex-wrap: wrap;
  }
  @media (max-width: 576px) {
    padding: 0 10px 30px;
  }
  .left p {
    @media (max-width: 992px) {
      color: #eaf8fa;
      font-size: 27px;
      margin: 6px 0 0;
    }
    @media (max-width: 630px) {
      font-size: 24px;
    }
    @media (max-width: 576px) {
      font-size: 21px;
    }
    @media (max-width: 480px) {
      font-size: 19px;
    }
    @media (max-width: 430px) {
      font-size: 17px;
    }
    @media (max-width: 370px) {
      font-size: 15px;
    }
  }
  .right {
    @media (max-width: 992px) {
      display: flex;
      margin-top: 20px;
    }
    a {
      @media (max-width: 992px) {
        margin-left: 30px;
      }
      @media (max-width: 576px) {
        margin: 0 15px;
      }
      @media (max-width: 480px) {
        margin: 0 15px;
      }
      @media (max-width: 430px) {
        margin: 0 12px;
      }
      @media (max-width: 370px) {
        margin: 0 9px;
      }
      @media (max-width: 320px) {
        margin: 0 7px;
      }
      img {
        vertical-align: middle;
        border-style: none;
        @media (max-width: 992px) {
          height: 33px;
        }
        @media (max-width: 630px) {
          height: 28px;
        }
        @media (max-width: 576px) {
          height: 22px;
        }
        @media (max-width: 320px) {
          height: 20px;
        }
      }
    }
  }
`;

const Header = ({path}) => {

  const [isOpen, setIsOpen] = React.useState(false);
  const lang = localStorage.getItem('lang');
  const [selectedLanguageId, setSelectedLanguageId] = React.useState(lang || 'en');

  const languageOptions = [
    {
      'id': 'de', 'name': 'Deutsch'
    },
    {
      'id': 'en', 'name': 'English'
    },
    {
      'id': 'es', 'name': 'Español'
    },
    {
      'id': 'fr', 'name': 'Français'
    },
    {
      'id': 'it', 'name': 'Italiano'
    },
    {
      'id':'pt', 'name': 'Português'
    },
    {
      'id':'chs', 'name': '汉语'
    },
    {
      'id':'ja', 'name': '日本語'
    },
    {
      'id':'ko', 'name': '한국어'
    },
  ];

  setInterval(() => {
    if (window.innerWidth > 992 && isOpen === true) {
      setIsOpen(false);
    }
  }, 10);

  let navBarBtnGroup, bgColor = '#f7fdfd';
  const isSignUp = path.includes('signup');

  if (isSignUp) {
    navBarBtnGroup = <>
      <NavBarBtn to="/auth/login">
        Login
      </NavBarBtn>
    </>
    if (path.includes('5')) {
      bgColor = '#f3f8f1';
    }
  } else if (path.includes('login')) {
    navBarBtnGroup = <>
      <NavBarBtn to="https://account.cloudmeb.com/login">
        Start a Free Trial
      </NavBarBtn>
    </>
  }

  return (
    <Wrapper bgColor={bgColor}>
      <Container>
        <NavBarBrand href="/">
          <img src="/images/logo.svg" alt="" className="logo"/>
          <img src="/images/logo_sm.svg" alt="" className="logo-sm"/>
        </NavBarBrand>
        <NavBarBtnSm href="https://account.cloudmeb.com/login">
          Login
        </NavBarBtnSm>
        <NavBarBtnSm sign href="https://account.cloudmeb.com/register">
          Sign Up
        </NavBarBtnSm>
        <NavBarToggle onClick={() => setIsOpen(true)}>
          <img src="/images/header/nav_icon.png" alt="" />
        </NavBarToggle>

        <Collapse>
          {!isSignUp && (
            <>
              <NavBarNav>
                <NavItem>
                  <NavLink href="https://cloudmeb.com/educations/" target="_blank">{i18n.t('auth.header.education')}</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="https://cloudmeb.com/resources/" target="_blank">{i18n.t('auth.header.resources')}</NavLink>
                </NavItem>
              </NavBarNav>
              <NavBarPhone>
                +1 800 355 4936
              </NavBarPhone>
              <NavBarBtn free href="/signup">
                {i18n.t('auth.header.startFreeTrial')}
              </NavBarBtn>
              { navBarBtnGroup }
            </>
          )}
          <LanguageSelect
            name="languages"
            viewMode="img"
            selectedOptionId={selectedLanguageId}
            setOption={setSelectedLanguageId}
            options={languageOptions}
            placeholder="..."
            isSignUp={isSignUp}
          />
        </Collapse>
      </Container>
      <ContainerSm style={{display: isOpen ? 'flex': 'none'}}>
        <ContainerSmHeader>
          <NavBarBrand href="#">
            <img src="/images/logo.svg" alt="" className="logo"/>
            <img src="/images/logo_sm.svg" alt="" className="logo-sm"/>
          </NavBarBrand>
          <NavBarBtnSm href="https://account.cloudmeb.com/login">
            Login
          </NavBarBtnSm>
          <NavBarBtnSm sign href="https://account.cloudmeb.com/register">
            Sign Up
          </NavBarBtnSm>
          <NavBarToggle close onClick={() => setIsOpen(false)}>
            <img src="/images/header/times_icon.png" alt="" />
          </NavBarToggle>
        </ContainerSmHeader>
        <ContainerSmContent>
          <li><a href="#">{i18n.t('auth.header.education')}</a></li>
          <li><a href="#">{i18n.t('auth.header.resources')}</a></li>
          <li><a href="#">{i18n.t('auth.header.becomeParnter')}</a></li>
        </ContainerSmContent>
        <ContainerSmFooter>
          <div className="left">
            <p>marc@cloudmeb.com</p>
            <p>+1800 355 4936</p>
          </div>
          <div className="right">
            <a href="#">
              <img src="/images/header/icon_in.svg" alt=""/>
            </a>
            <a href="#">
              <img src="/images/header/icon_fb.svg" alt=""/>
            </a>
            <a href="#">
              <img src="/images/header/icon_tw.svg" alt=""/>
            </a>
            <a href="#">
              <img src="/images/header/icon_te.svg" alt=""/>
            </a>
          </div>
        </ContainerSmFooter>
      </ContainerSm>
    </Wrapper>
  )
}

export default withRouter(Header);
