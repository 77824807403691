import styled from 'styled-components';

export const Wrapper = styled.div`
  .googBtn {
    width: 196px;
    height: 40px;
    margin: 0 auto;
    justify-content: center;
    
    span {
      font-weight: 800 !important;
    }
  }
`;
