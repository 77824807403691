import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 144px 0 286px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Label = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 150%;
  /* identical to box height, or 54px */
  text-align: center;
  color: #777;
`;

export const MLabel = styled.a`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 150%;
  text-align: center;
  color: #777;
  text-decoration: underline;
`;
