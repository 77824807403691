import React from "react";
import styled, {css} from "styled-components";

const ArticleCard = ({article, size}) => {
  
  const colors = {
    'BOOKKEEPING'     : ['#f6fff5', '#7fa47b'],
    'LEGAL'           : ['#e5f5f9', '#659ea9'],
    'STARTUP'         : ['#fbebfb', '#996999'],
    'ACCOUNTING'      : ['#f8f4f2', '#ac8576'],
    'APPROVAL'        : ['#f8f4f2', '#ac8576'],
    'HOW TO'          : ['#f6fff5', '#7fa47b'],
    'TEMPLATES'       : ['#e5f5f9', '#659ea9'],
    'CHECKLISTS'      : ['#fbebfb', '#996999'],
    'GUIDES'          : ['#f8f4f2', '#ac8576'],
    'BEST PRACTICES'  : ['#f8f4f2', '#ac8576'],
  };

  
  const fontSize = {
    'lg': 20,
    'md': 18,
    'sm': 15,
    'xs': 13
  };

  const type = (article.type || '').toUpperCase()
  
  return (
    <Container size={size} href={article.link} target="_blank">
      <Image size={size}><img src={article.img}/></Image>
      <Content size={size}>
        <Title size={size} fontSize={fontSize[size]}>{article.title}</Title>
        <Info size={size}>
          <Type colors={colors[type]}>{type}</Type>
          <ReadCount><span>{article.readNum}</span> MIN{size === 'sm' ? '' : 'READ'}</ReadCount>
        </Info>
      </Content>
    </Container>
  )
 
};

const Container = styled.a`
  display: flex;
  flex-direction: ${props => props.size === 'xs' || props.size === 'sm' ? 'row' : 'column'};
  width: 100%;
  height: 100%;
`;

const Image = styled.div`
  width: ${props => props.size === 'md' || props.size === 'lg' ? '100%' : props.size === 'sm' ? '48%' : '68px'};
  border-radius: 10px;
  overflow: hidden;
  margin-right: 10px;
  display: flex;
  align-items: center;
  
  img {
    width: 100%;
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  
  ${props => (props.size === 'xs' || props.size === 'sm') && css`
    justify-content: space-between;
  `}
  
  ${props => (props.size === 'lg' || props.size === 'md') && css`
    flex-direction: column-reverse;
    justify-content: flex-end;
  `}
`;

const Title = styled.h3`
  font-size: ${props => props.fontSize || '15'}px;
  color: #181e26;
  line-height: 1.5;
  margin-top: ${props => props.size === 'xs' || props.size === 'sm' ? '5px' : '10px'};
`;

const Info = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${props => props.size === 'md' || props.size === 'lg' ? 'space-between' : 'flex-start'};
  align-items: center;
  margin-top: 10px;
`;

const Type = styled.div`
  background: ${props => props.colors[0] || '#fff'};
  color: ${props => props.colors[1] || '#000'};
  border-radius: 5px;
  font-family: Circe-Bold;
  font-size: 10px;
  height: 25px;
  padding: 5px 10px;
`;

const ReadCount = styled.p`
  font-size: 10px;
  letter-spacing: 1px;
  margin-left: 12px;
  color: #a2a5a8;
  
  span {
    font-weight: bold;
    color: #2e323a;
  }
`;

export default ArticleCard;
