import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import i18n from '../../../i18n';

const ConfirmPopup = (props) => {
  const { alertMode, alertAction } = props;
  return (
    <div>
      <Dialog
        open={alertMode}
        onClose={() => alertAction(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {i18n.t('dashboard.cancelSolution')}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => alertAction(false)} color="primary">
            {i18n.t('dashboard.no')}
          </Button>
          <Button onClick={() => alertAction(true)} color="primary">
            {i18n.t('dashboard.yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmPopup;
