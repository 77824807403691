import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

const PopoverModal = (props) => {
  const { isOpen, closeModal, onAgree, onDisAgree } = props;

  return isOpen ? (
    <ModalWrapper>
      <ClickAwayListener onClickAway={closeModal}>
        <ModalBody>
          <p>Do you wish to mark this task as completed?</p>

          <ButtonGroup>
            <Button className="cancel" onClick={onDisAgree}>No</Button>
            <Button className="ok" onClick={onAgree}>Yes</Button>
          </ButtonGroup>
        </ModalBody>
      </ClickAwayListener>
    </ModalWrapper>
  ) : null;
};

PopoverModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func,
  onAgree: PropTypes.func,
  onDisAgree: PropTypes.func,
};

PopoverModal.defaultProps = {
  closeModal: () => {},
  onAgree: () => {},
  onDisAgree: () => {},
};

const ModalWrapper = styled.div`
  position: absolute;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.16);
  top: -120px;
  left: -44px;
  width: 235px;
  height: auto;
  z-index: 100;
  @media(max-width: ${props => props.theme.breakpoints.sm}) {
    left: 0px;
  }
`;

const ModalBody = styled.div`
  padding: 15px 10px;
  position: relative;
  &:before {
    content: "";
    top: 98%;
    position: absolute;
    left: 50px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #FFF;
  }
  p {
    font-family: Circe-Regular;
    font-size: 16px;
    color: #333;
  }
  @media(max-width: ${props => props.theme.breakpoints.sm}) {
    &:before {
      left: 10px;
    }
  }
`;

const ButtonGroup = styled.div`
  margin-top: 15px;
  button {
    padding: 3px 4px !important;
    margin-right: 15px !important;
    font-size: 12px !important;
    min-width: 48px !important;
  }
  .cancel {
    border: 1px solid #999 !important;
    color: #999 !important;
  }
  .ok {
    border: 1px solid #333 !important;
  }
`;


export default PopoverModal;
