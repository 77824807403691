import React from "react";
import styled from "styled-components";
import {TaskOpenIcon} from "../../common/svgIcon";

const NotificationItem = ({item}) => {

    const [ isHovered, setIsHovered ] = React.useState(false);

    const Wrapper = styled.div`
        width: 100%;
        padding: 15px;
        padding-right: 5px;
        border-radius: 5px;
        color: #181e26;
        
        .title {
          display: flex;
          align-items: center;
          
          p {
            display: inline-block;
            font-family: Circe-Bold;
            font-size: 16px;
            color: #415c85;
          }
          
          .fa-circle {
            color: #8cc178;
            font-size: 8px;
            margin-left: 5px;
          }
        }
      
        .content {
          font-size: 16px;
          line-height: 1.5;
          cursor: pointer;
          
          &:hover {
            color: #415c85;
            
            .arrow-icon {
              border: none;
              background: #5e7599;
              
              .fa-long-arrow-alt-right {
                color: #f1f3f6;
              }
            }
          }
        }
        
        .date {
          font-size: 14px;
          color: #93a0b4;
          line-height: 2;
        }
    `;

    return (
        <Wrapper>
            <div className={'title'}>
                <p>{item.title}</p>
            </div>
            <div className={'content'} onMouseOver={() => setIsHovered(true)} onMouseLeave={() =>setIsHovered(false)}>
                <p>
                    { item.text }
                    <a href={item.link} target="_blank">
                        <TaskOpenIcon state={''} onAlertAction={() => {}} isHovered={isHovered} className="ml-2"/>
                    </a>
                </p>
            </div>
            <p className={'date'}>
                {item.date}
            </p>
        </Wrapper>
    )
}

export default NotificationItem;