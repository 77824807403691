import React, { Component } from 'react';
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";

import { STORE_KEYS } from '@/stores';
import MyTeamItem from "@/containers/Targets/MyTeamItem";
import QuickLink from "@/containers/Dashboard/QuickLink";
import { CommonLink } from "@/components/common/button";
import CircleProgressBar from "@/components/common/circle-progressbar";
import Tooltip from "@/components/dashboard/tasks/Tooltip";
import i18n from '../../i18n';
import {ProgressPanel, ProgressView, ScoreView, SubTitle} from "./styles";

class StatusPanel extends Component {
  render() {
    const {
      targetOverallProgress,
      taskActivityScore,
      googleDriveLink,
      onboarding_avatar,
      onboarding_Company,
      onboarding_email,
      onboarding_calendly,
      bookkeeping_avatar,
      bookkeeper_Company,
      bookkeeper_Email,
      bookkeeper_Calendly,
      accounting_avatar,
      accountant_Name,
      accountant_email,
      accounting_calendly,
      legal_avatar,
      incorporation_Name,
      incorporation_email,
      incorporation_calendly,
      products,
    } = this.props;
    const quick_links = [];

    const my_team = [
      {
        name: onboarding_Company,
        img: onboarding_avatar,
        email: onboarding_email,
        calendly: onboarding_calendly,
        default_img: '/images/Solutions/Service_Accounting.svg',
        property: 'ONBOARDING'
      },
      {
        name: bookkeeper_Company,
        img: bookkeeping_avatar,
        email: bookkeeper_Email,
        calendly: bookkeeper_Calendly,
        default_img: '/images/Solutions/Service_Accounting.svg',
        property: 'BOOKKEEPING'
      },
      {
        name: accountant_Name,
        img: accounting_avatar,
        email: accountant_email,
        calendly: accounting_calendly,
        default_img: '/images/Solutions/Service_Accounting.svg',
        property: 'ACCOUNTING'
      },
      {
        name: incorporation_Name,
        img: legal_avatar,
        email: incorporation_email,
        calendly: incorporation_calendly,
        default_img: '/images/Solutions/Service_Legal.svg',
        property: 'LEGAL'
      },
    ];
    (products || []).map((item, index) => {
      quick_links.push({
        name: item.Cloud_Product_Name__c,
        img: item.CP_logo__c || '',
        url: item.Login_Page__c || '',
        date: item.Start_Date__c || '',
      });
      quick_links.sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
      });
    });

    return (
      <div className={'wrapper'}>
        <ProgressPanel>
          <ProgressView>
            <span className="upper-case">{i18n.t('dashboard.overallProgress')}</span>
            <CircleProgressBar
              value={targetOverallProgress}
              size={80}
              colors={['#8d9bb0', '#fff']}
              cutout={93}
              labelSize={24}
            />
          </ProgressView>
          <ScoreView>
            <div className="title">
              <span className="upper-case">{i18n.t('dashboard.activityScore')}</span>
              <Tooltip/>
            </div>
            <CircleProgressBar
              value={taskActivityScore}
              size={80}
              colors={['#8d9bb0', '#fff']}
              cutout={93}
              labelSize={24}
            />
          </ScoreView>
        </ProgressPanel>
        <div className="mt-4 mb-4">
          <SubTitle>{i18n.t('dashboard.myTeam')}</SubTitle>
          {
            my_team.map((item, index) => (
              <MyTeamItem data={item} key={index} />
            ))
          }
        </div>
        <div className="mt-4 mb-4">
          <SubTitle>{i18n.t('dashboard.sharedDrive')}</SubTitle>
          <CommonLink fullWidth href={googleDriveLink} target="_blank">
            <img src={'/images/dashboard/storage_icon.png'} alt="Storage Icon" className="mr-2"/>
            {i18n.t('dashboard.openStorage')}
          </CommonLink>
        </div>
        <div className="mt-4 mb-4">
          <SubTitle>{i18n.t('dashboard.quickLinks')}</SubTitle>
          <QuickLink data={quick_links}/>
        </div>
      </div>
    );
  }
};


export default compose(
  inject(STORE_KEYS.MYSOLUTIONSSTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.MYSOLUTIONSSTORE]: {
         targetOverallProgress,
         taskActivityScore,
         googleDriveLink,
         onboarding_avatar,
         onboarding_Company,
         onboarding_email,
         onboarding_calendly,
         bookkeeping_avatar,
         bookkeeper_Company,
         bookkeeper_Email,
         bookkeeper_Calendly,
         accounting_avatar,
         accountant_Name,
         accountant_email,
         accounting_calendly,
         legal_avatar,
         incorporation_Name,
         incorporation_email,
         incorporation_calendly,
         products,
       },
     }) => ({
      targetOverallProgress,
      taskActivityScore,
      googleDriveLink,
      onboarding_avatar,
      onboarding_Company,
      onboarding_email,
      onboarding_calendly,
      bookkeeping_avatar,
      bookkeeper_Company,
      bookkeeper_Email,
      bookkeeper_Calendly,
      accounting_avatar,
      accountant_Name,
      accountant_email,
      accounting_calendly,
      legal_avatar,
      incorporation_Name,
      incorporation_email,
      incorporation_calendly,
      products,
    })
  )
)(StatusPanel);
