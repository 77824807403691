import Snackbar from '@material-ui/core/Snackbar';
import styled from 'styled-components/macro';

const standardPadding = 8;
export const CustomStyle = () => ({
  default: {
    'padding-right': '30px !important',
    'min-width': '100px !important'
  },
  buyPosition: {},
  sellPosition: {}
});

export const StyledSnackbar = styled(Snackbar)`
  // color: ${props => props.theme.palette.white};
  // min-width: 350px;
  // bottom: ${props => props.objheight + standardPadding * 3}px;
  // left: 24px;
  // margin-left: 0 !important;
  // box-shadow: 0 0 19px 3px #333;
  // background: rgb(50, 50, 50);
  // border-radius: 1px;
  //
  // .MuiSnackbarContent-root {
  //   width: 100%;
  //   color: #fff;
  //   font-family: 'open_sans', sans-serif;
  //   padding: 12px;
  //   background: rgb(50, 50, 50);
  //   box-shadow: none;
  //   font-size: 13px !important;
  //   display: flex;
  //   align-items: center;
  // }
  //
  // .MuiSnackbarContent-action {
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   margin: 0;
  //   padding: 0;
  //
  //   button {
  //     margin: 5px;
  //   }
  // }
  //
  // .MuiSnackbarContent-message {
  //   width: 100%;
  //   padding: 0;
  //
  //   & > span {
  //     display: flex;
  //     justify-content: space-between;
  //   }
  // }
  //
  // .MuiIconButton-root {
  //   padding: 5px;
  //   margin: 7px;
  // }
  //
  // // .MuiSvgIcon-root {
  // //   margin-top: 3px;
  // // }
`;
