import React from "react";
import styled from "styled-components";
import {productColors} from "@/constants";

const QuickLinkItem = ({data}) => {
  const Wrapper = styled.a`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 5px;
    cursor: pointer;
    border-radius: 7px;
    
    
    img {
      width: 25px;
      height: 25px;
      min-width: 25px;
      margin-right: 10px;
    }
    
    h3 {
      font-size: 18px;
      color: #415c85;
    }
    
    &:hover {
      h3 {
        color: #8d9bb0;
      }
    }
  `;

  return (
    <Wrapper hoverColor={productColors[data.name.toLowerCase()]} href={data.url} target="_blank">
      <img src={data.img} alt={'Link Image'} />
      <h3>{data.name}</h3>
    </Wrapper>
  )
}

export default QuickLinkItem;
