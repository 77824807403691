import React from 'react';
import i18n from '../../i18n';
import {
  Wrapper, Label, MLabel
} from './styles';

export default () => (
  <Wrapper>
    <Label>{i18n.t('dashboard.comingSoon')}</Label>
  </Wrapper>
);
