import React, { Component } from "react";
import { Carousel } from "react-bootstrap";
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import i18n from '../../../i18n';
import { STORE_KEYS } from '@/stores';
import Tooltip from "@/components/dashboard/tasks/Tooltip";
import { formatFloatString } from "@/utils";
import {SvgKnowledgeIcon, SvgArticleIcon, SvgReadMoreIcon} from "@/components/SvgIcon";
import {
  DashboardItem, NoAccountPanel, Wrapper, XeroConnectButton
} from "./styles";

class Header extends Component {
  // Xero Login
  renderLoginContent = () => {
    const { consentUrl } = this.props;
    return (
        <NoAccountPanel>
          <XeroConnectButton href={consentUrl}>
            <img src={'images/dashboard/xero_1.png'} alt="Img" />
            {i18n.t('dashboard.connectXero')}
          </XeroConnectButton>
          <div className='content'>
            <p>{i18n.t('dashboard.connectXeroAccount')}</p>
            <Link to={'#'}>{i18n.t('dashboard.getXero')}</Link>
          </div>
        </NoAccountPanel>
    );
  }

  xeroLogin = (isMobile) => {
    return isMobile ? (
        <Carousel.Item>
          {this.renderLoginContent()}
        </Carousel.Item>
    ) : this.renderLoginContent();
  }

  // Xero Details
  xeroDetails = (isMobile) => {
    const {
      oldestUnreconcDate,
      shareLoanMonth,
      shareLoanYDT,
      salesTaxMonth,
      salesTaxYDT,
    } = this.props;

    const details = [];
    details.push(
        <DashboardItem>
          <div className={'title text-center upper-case'}>
            {i18n.t('dashboard.oldestUnreconciled')}
            <Tooltip/>
          </div>
          <div className={'content'}>
            <h3 className={'bold'}>{oldestUnreconcDate || "-"}</h3>
          </div>
        </DashboardItem>,
        <DashboardItem>
          <div className={'title text-center upper-case'}>
            {i18n.t('dashboard.income')}
          </div>
          <div className={'content'}>
            <div className="d-flex justify-content-around w-100">
              <div className={'content-item'}>
                <p>{i18n.t('dashboard.thisMonth')}</p>
                <h3 className={'bold'}>${(formatFloatString(shareLoanMonth, 0) + "").split(".")[0]}<span>.{(shareLoanMonth + "").split(".")[1]}</span></h3>
              </div>
              <div className={'content-item'}>
                <p>{i18n.t('dashboard.lastMonth')}</p>
                <h3 className={'bold'}>${(formatFloatString(shareLoanYDT, 0) + "").split(".")[0]}<span>.{(shareLoanYDT + "").split(".")[1]}</span></h3>
              </div>
            </div>
          </div>
        </DashboardItem>,
        <DashboardItem>
          <div className={'title text-center upper-case'}>
            {i18n.t('dashboard.profitability')}
          </div>
          <div className={'content'}>
            <div className="d-flex justify-content-around w-100">
              <div className={'content-item'}>
                <p>{i18n.t('dashboard.thisMonth')}</p>
                <h3 className={'bold'}>${(formatFloatString(salesTaxMonth, 0) + "").split(".")[0]}<span>.{(salesTaxMonth + "").split(".")[1]}</span></h3>
              </div>
              <div className={'content-item'}>
                <p>{i18n.t('dashboard.lastMonth')}</p>
                <h3 className={'bold'}>${(formatFloatString(salesTaxYDT, 0) + "").split(".")[0]}<span>.{(salesTaxYDT + "").split(".")[1]}</span></h3>
              </div>
            </div>
          </div>
        </DashboardItem>
    )

    return isMobile ? details.map((item, index) => (
              <Carousel.Item key={index}>
                {item}
              </Carousel.Item>
          ))
        : details.map((item, index) => (
          <div className={'item-wrapper'} key={index}>
            {item}
          </div>
        ))
  }

  // Main Content
  render() {
    const { isXeroLogged } = this.props;

    const links = []
    links.push(
        <DashboardItem
            bgColor={'#fbf9fe'}
            titleColor={'#9159da'}
            contentColor={'#5b3c83'}
            bold
            clickable
            isArticle
            to="/education"
        >
          <div className={'title'}>
            <SvgKnowledgeIcon/>
            {i18n.t('dashboard.knowledgeArticles')}
          </div>
          <div className={'content'}>
            <p>{i18n.t('dashboard.learnPractices')} <SvgReadMoreIcon/></p>
          </div>
        </DashboardItem>,
        <DashboardItem
            titleColor={'#53cceb'}
            contentColor={'#457c8e'}
            bold
            clickable
            isGuide
            to="/education"
        >
          <div className={'title'}>
            <SvgArticleIcon/>
            {i18n.t('dashboard.integrationGuides')}
          </div>
          <div className={'content'}>
            <p>{i18n.t('dashboard.customizeXero')} <SvgReadMoreIcon/></p>
          </div>
        </DashboardItem>
    )

    return (
      <Wrapper>
        <div className="row mobile-hidden">
          {!isXeroLogged ? this.xeroLogin(false) : this.xeroDetails(false)}
          {links.map((item, index) => (
                <div className={'item-wrapper right'} key={index}>
                  {item}
                </div>
          ))}
        </div>
        <div className="mobile-show">
          <Carousel
              nextIcon={null}
              prevIcon={null}
          >
            {!isXeroLogged ? this.xeroLogin(true) : this.xeroDetails(true)}
            {
              links.map((item, index) => (
                  <Carousel.Item key={index}>
                    {item}
                  </Carousel.Item>
              ))
            }
          </Carousel>
        </div>
      </Wrapper>
    );
  }
}

export default compose(
    inject(STORE_KEYS.XEROSTORE),
    observer,
    withProps(
        ({
           [STORE_KEYS.XEROSTORE]: {
             consentUrl,
             oldestUnreconcDate,
             shareLoanMonth,
             shareLoanYDT,
             salesTaxMonth,
             salesTaxYDT,
           },
         }) => ({
             consentUrl,
             oldestUnreconcDate,
             shareLoanMonth,
             shareLoanYDT,
             salesTaxMonth,
             salesTaxYDT,
        })
    )
)(Header);
