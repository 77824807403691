import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1200;
  background: rgba(0, 0, 0, 0.5);
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  
  #loader {
    width: 102px;
    height: 60px;
    display: block;
    background: transparent url('images/loader-cloud.svg') no-repeat;
    position: absolute;
  }
  
  .gear {
    position: absolute;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  
  #loader .gear-small{
    top: 31px;
    left: 19px;
    animation-name: rotation;
    animation-duration: 5s;
  }
  
  #loader .gear-large{
    top: 11px;
    left: 41px;
    animation-name: rotation2;
    animation-duration: 6s;
  }

  @-webkit-keyframes rotation {
    0% {-webkit-transform:rotate(0deg);
        -moz-transform:rotate(0deg);
        -o-transform:rotate(0deg);
        transform:rotate(0deg);}
    100% {-webkit-transform:rotate(360deg);
        -moz-transform:rotate(360deg);
        -o-transform:rotate(360deg);
        transform:rotate(360deg);}
}
@-moz-keyframes rotation {
    0% {-webkit-transform:rotate(0deg);
        -moz-transform:rotate(0deg);
        -o-transform:rotate(0deg);
        transform:rotate(0deg);}
    100% {-webkit-transform:rotate(360deg);
        -moz-transform:rotate(360deg);
        -o-transform:rotate(360deg);
        transform:rotate(360deg);}
}
@-o-keyframes rotation {
    0% {-webkit-transform:rotate(0deg);
        -moz-transform:rotate(0deg);
        -o-transform:rotate(0deg);
        transform:rotate(0deg);}
    100% {-webkit-transform:rotate(360deg);
        -moz-transform:rotate(360deg);
        -o-transform:rotate(360deg);
        transform:rotate(360deg);}
}
@keyframes rotation {
    0% {-webkit-transform:rotate(0deg);
        -moz-transform:rotate(0deg);
        -o-transform:rotate(0deg);
        transform:rotate(0deg);}
    100% {-webkit-transform:rotate(360deg);
        -moz-transform:rotate(720deg);
        -o-transform:rotate(360deg);
        transform:rotate(360deg);}
}


@-webkit-keyframes rotation2 {
    0% {-webkit-transform:rotate(360deg);
        -moz-transform:rotate(360deg);
        -o-transform:rotate(360deg);
        transform:rotate(360deg);}
    100% {-webkit-transform:rotate(0);
        -moz-transform:rotate(0);
        -o-transform:rotate(0);
        transform:rotate(0);}
}
@-moz-keyframes rotation2 {
    0% {-webkit-transform:rotate(360deg);
        -moz-transform:rotate(360deg);
        -o-transform:rotate(360deg);
        transform:rotate(360deg);}
    100% {-webkit-transform:rotate(0);
        -moz-transform:rotate(0);
        -o-transform:rotate(0);
        transform:rotate(0);}
}
@-o-keyframes rotation2 {
    0% {-webkit-transform:rotate(360deg);
        -moz-transform:rotate(360deg);
        -o-transform:rotate(360deg);
        transform:rotate(360deg);}
    100% {-webkit-transform:rotate(0);
        -moz-transform:rotate(0);
        -o-transform:rotate(0);
        transform:rotate(0);}
}
@keyframes rotation2 {
    0% {-webkit-transform:rotate(360deg);
        -moz-transform:rotate(720deg);
        -o-transform:rotate(360deg);
        transform:rotate(360deg);}
    100% {-webkit-transform:rotate(0);
        -moz-transform:rotate(0);
        -o-transform:rotate(0);
        transform:rotate(0);}
}
`;
