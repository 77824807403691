import styled from "styled-components";

export const Wrapper = styled.div`
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.bgColor || '#fcfefe'};
  cursor: pointer;
  
  &:hover {
    background-color: ${props => props.hoverColor || '#f0fafb'};
  }
`;

export const Action = styled.div`
  width: fit-content;
  height: fit-content;
  position: relative;
`;
