import React, { Fragment } from "react";
import styled, { css } from "styled-components";

const Title = styled.h1`
  font-family: Circe-Regular;
  font-size: 70px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #415c85;
  margin-bottom: 10px;
  
  @media (max-width: 1200px) {
    font-size: 63px;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    text-align: center;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-top: 30px;
    font-size: 63px;
  }
  
  @media (max-width: 630px) {
    font-size: 60px;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 58px;
    margin-top: 10px;
    text-align: center;
  }
  
  @media (max-width: 480px) {
    font-size: 54px;
  }
  
  @media (max-width: 425px) {
    font-size: 50px;
  }
  
  @media (max-width: 370px) {
    font-size: 46px;
  }
  
  @media (max-width: 320px) {
    font-size: 42px;
  }
`

const LoginTitle = ({title}) => {
  return (
    <div>
      <Title>
        {title}
      </Title>
    </div>
  )
}

export default LoginTitle;
