import React from "react";
import styled from "styled-components";
import i18n from '../../i18n';
//components
import LoginTitle from "../../components/login/login-title";
import Button from "../../components/common/button";
import RadioButton from "../../components/common/RadioButton";

import Select from "../../components/common/Select";
import SignUpStatusBar from "../../components/common/SignUpStatusBar";
import { Slider } from '@material-ui/core';

//styles
const Wrapper = styled.div`
  width: 100%;
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Login = styled.div`
  flex: 1;
  display: flex;
  
   @media (max-width: ${props => props.theme.breakpoints.lg}) {
    background-image: url("/images/login/img_msg_sent.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
`;

const Brand = styled.div`
  flex: 1;
  background: #f7fdfd;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  
  > img {
    max-width: 500px;
    width: 100%;
  }
  
   @media (max-width: ${props => props.theme.breakpoints.lg}) {
    display: none;
  }
`;

const LoginForm = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 30px;
  background-color: #fff;
  
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    background-color: rgba(234, 249, 250, 0.9);
  }
`;

const LoginFormContent = styled.div`
  max-width: 480px;
  width: 100%;
  
   @media (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: unset;
  }
`;

const FormBody = styled.div`
  max-width: 400px;
  
   @media (max-width: ${props => props.theme.breakpoints.lg}) {
    max-width: unset;
  }
  
  > div {
    margin-top: 20px;
  }
`;



const FiscalYearEnd = styled.div`
  > div {
    display: flex;
    
    > div {
      margin-right: 20px;
    }
    
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      flex-direction: column;
      > div {
        width: 100%;
        margin-top: 20px;
        margin-right: 0px;
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
  
  select {
    margin-right: 20px;
  }
  
  label {
    font-family: Circe-Regular;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: #5e7599;
  }
`;

const SalesTaxFrequency = styled.div`
  label {
    font-family: Circe-Regular;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: #5e7599;
  }
  
  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

const LoginBtnGroup = styled.div`
  display: flex;
  margin-top: 30px;
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
  }
`;

const PolicyText = styled.div`
  font-family: Circe-Regular;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  
  a {
    color: #5e7599;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: none;
  }
`;

const MonthlyTransaction = styled.div`
  label {
    font-family: Circe-Regular;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: #5e7599;
  }
`;

const TransactionSlider = styled(Slider)`
  color: #5e7599 !important;
  
  .MuiSlider-thumb {
    height: 20px;
    width: 6px;
    margin-top: -9px;
    border-radius: 10px;
  }
`;

const TransactionsLabel = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LabelItem = styled.div`
  font-family: Circe-Regular;
  font-size: 15px;
  cursor: pointer;
  
  color: ${props => props.active === true ? '#415c85' : '#8d9bb0'};
`;

const SignUpPage = ({ setMode, showSnackMsg }) => {
  const [selectedYearId, setSelectedYearId] = React.useState(2021);
  const [selectedMonthId, setSelectedMonthId] = React.useState(12);
  const [frequency, setFrequency] = React.useState('monthly');
  const [selectedAccountingTypeId, setSelectedAccountingTypeId] = React.useState('');
  const [monthlyTransactionLevel, setMonthlyTransactionLevel] = React.useState(1);

  const yearOptions = [
    {
      'id': 2019,
      'name': '2019',
    },
    {
      'id': 2020,
      'name': '2020',
    },
    {
      'id': 2021,
      'name': '2021',
    },
    {
      'id': 2022,
      'name': '2022',
    },
    {
      'id': 2023,
      'name': '2023',
    }
  ];

  const monthOptions = [
    {
      'id': 1,
      'name': 'January',
    },
    {
      'id': 2,
      'name': 'February',
    },
    {
      'id': 3,
      'name': 'March',
    },
    {
      'id': 4,
      'name': 'April',
    },
    {
      'id': 5,
      'name': 'May',
    },
    {
      'id': 6,
      'name': 'June',
    },
    {
      'id': 7,
      'name': 'July',
    },
    {
      'id': 8,
      'name': 'August',
    },
    {
      'id': 9,
      'name': 'September',
    },
    {
      'id': 10,
      'name': 'October',
    },
    {
      'id': 11,
      'name': 'November',
    },
    {
      'id': 12,
      'name': 'December',
    }
  ];

  const accountingTypeOptions = [
    {
      'id': 'Xero',
      'name': 'Xero'
    },
    {
      'id': 'Quickbooks',
      'name': 'Quickbooks'
    },
    {
      'id': 'Sage',
      'name': 'Sage'
    },
    {
      'id': 'Spreadsheets/Other',
      'name': 'Spreadsheets/Other'
    }
  ];

  const transactionLable = [
    {
      level: '<50',
      label: '<50'
    },
    {
      level: '50-100',
      label: '50-100'
    },
    {
      level: '100-250',
      label: '100-250'
    },
    {
      level: '250-500',
      label: '250-500'
    },
    {
      level: '500-1000',
      label: '500-1000'
    }

  ];

  const getDayOnMon = (year, month) => {
    if (month === 1 || month === 3 || month === 5 || month === 7 || month === 8 || month === 10 || month === 12) return 31;
    if (month === 4 || month === 6 || month === 9 || month === 11) return 30;
    if (month === 2) {
      if (year % 4 === 0 && year % 100 !== 0) return 29;
      return 28;
    }
    return 1;
  }

  const onClickSignUpButton = () => {
    if ((selectedAccountingTypeId || '').trim().length === 0) {
      showSnackMsg(i18n.t("message.onboardingType"));
      return;
    }
    setMode(4, selectedAccountingTypeId, monthlyTransactionLevel, frequency,
        `${selectedYearId}-${selectedMonthId}-${getDayOnMon(selectedYearId, selectedMonthId)}`);
  };

  return (
    <Wrapper>
      <Login>
        <Brand>
          <img src={"/images/login/img_msg_sent.png"} alt=""/>
        </Brand>
        <LoginForm>
          <LoginFormContent>
            <LoginTitle title={i18n.t('auth.createAccount')}/>
            <FormBody>
              <SignUpStatusBar step={4}/>
              <MonthlyTransaction>
                <label>{i18n.t('auth.monthlyTransactions')}</label>
                <TransactionSlider
                  value={monthlyTransactionLevel}
                  min={1}
                  max={5}
                  step={1}
                  aria-labelledby="label"
                  onChange={(e, val) => setMonthlyTransactionLevel(val)}
                />
                <TransactionsLabel>
                  {
                    transactionLable.map((item, index) => {
                      return (
                        <LabelItem
                          active={monthlyTransactionLevel === item.level}
                          onClick={() => setMonthlyTransactionLevel(item.level)}
                          key={index}
                        >
                          {item.label}
                        </LabelItem>
                      )
                    })
                  }
                </TransactionsLabel>
              </MonthlyTransaction>
              <FiscalYearEnd>
                <label>{i18n.t('auth.fiscalYear')}</label>
                <div>
                  <Select
                    name="months"
                    label=""
                    selectedOptionId={selectedMonthId}
                    setOption={setSelectedMonthId}
                    options={monthOptions}
                    placeholder={i18n.t('auth.select')}
                  />
                  <Select
                    name="year"
                    label=""
                    size="md"
                    selectedOptionId={selectedYearId}
                    setOption={setSelectedYearId}
                    options={yearOptions}
                    placeholder={i18n.t('auth.select')}
                  />
                </div>
              </FiscalYearEnd>
              <SalesTaxFrequency>
                <label>{i18n.t('auth.salesTax')}</label>
                <div>
                  <RadioButton
                    label={i18n.t('auth.monthly')}
                    name={'frequency'}
                    value={'monthly'}
                    onChange={setFrequency}
                    checked={frequency}
                  />
                  <RadioButton
                    label={i18n.t('auth.quarterly')}
                    name={'frequency'}
                    value={'quarterly'}
                    onChange={setFrequency}
                    checked={frequency}
                  />
                  <RadioButton
                    label={i18n.t('auth.yearly')}
                    name={'frequency'}
                    value={'yearly'}
                    onChange={setFrequency}
                    checked={frequency}
                  />
                </div>
              </SalesTaxFrequency>
              <Select
                name="type"
                label={i18n.t('auth.whatAccounting')}
                width="max-content"
                selectedOptionId={selectedAccountingTypeId}
                options={accountingTypeOptions}
                setOption={setSelectedAccountingTypeId}
                placeholder={i18n.t('auth.select')}
              />
              <PolicyText>{i18n.t('auth.byContinuing')} <a href="#">{i18n.t('auth.termsService')}</a>, <a href="#">{i18n.t('auth.privacyPolicy')}</a> {i18n.t('auth.and')} <a href="#">{i18n.t('auth.cookiePolicy')}</a></PolicyText>
            </FormBody>
            <LoginBtnGroup>
              <Button label={i18n.t('auth.signup')} onClick={onClickSignUpButton}/>
            </LoginBtnGroup>
          </LoginFormContent>
        </LoginForm>
      </Login>
    </Wrapper>
  )
}

export default SignUpPage;
