import {observable, action, reaction} from 'mobx';
import {
    getNotifications, requestPointsRequest,
} from "@/lib/rest";
import React from "react";

class NotificationStore {
    @observable isLoggedIn = false;
    @observable email = '';
    @observable notifications = []; // notifications
    @observable isNewMsg = false;
    vModeHandle = null;

    constructor(snackbar, authStore, viewModeStore) {
        this.snackbar = snackbar;
        this.isLoggedIn = authStore.isLoggedIn;
        this.email = localStorage.getItem('email');
        this.vModeHandle = viewModeStore;

        reaction(
            () => ({
                isLoggedIn: authStore.isLoggedIn,
                email: authStore.email
            }),
            authObj => {
                this.isLoggedIn = authObj.isLoggedIn;
                this.email = authObj.email;
                if (this.isLoggedIn) {
                    this.requestNotifications();
                }
            }
        );

        if (this.isLoggedIn) {
            this.requestNotifications();
        }
    }

    @action.bound readNotifyMsg() {
        this.isNewMsg = false;
    }

    @action.bound requestNotifications() {
        this.vModeHandle.setLoadingMode(true);
        return new Promise(resolve => {
            getNotifications(this.email)
                .then(ret => {
                    if (ret.data) {
                        const data = ret.data || [];
                        data.sort(function (a, b) {
                            const aRelease = Date.parse(a.Msg_Date__c || '');
                            const bRelease = Date.parse(b.Msg_Date__c || '');
                            return aRelease < bRelease ? 1 : -1;
                        });
                        this.notifications = data;

                        const sizeOfMsgs = localStorage.getItem("notification");
                        if (Number(sizeOfMsgs) !== Number(data.length)) {
                            this.isNewMsg = true;
                            localStorage.setItem("notification", data.length);
                        }
                    }
                    this.vModeHandle.setLoadingMode(false);
                    resolve(true);
                })
                .catch(e => {
                    this.vModeHandle.setLoadingMode(false);
                    resolve(false);
                })
        });
    }

    @action.bound requestPtsRequest() {
        this.vModeHandle.setLoadingMode(true);
        return new Promise((resolve) => {
            requestPointsRequest()
                .then((ret) => {
                    this.vModeHandle.setLoadingMode(false);
                    resolve(true);
                })
                .catch((e) => {
                    this.vModeHandle.setLoadingMode(false);
                    resolve(false);
                });
        });
    }

    /**
     *  Snackbar Popup message
     */
    @action.bound showSnackMsg(msg) {
        this.snackbar({
            message: () => (
                <>
          <span>
            <b>{msg}</b>
          </span>
                </>
            )
        });
    }
}

export default (snackbar, authStore, viewModeStore) => new NotificationStore(snackbar, authStore, viewModeStore);
