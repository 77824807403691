import axios from "axios";

import { AUTH_SERVER_URL } from "@/config/constants";

const getHeaders = () => ({
  headers: {
    "Content-Type": "application/json"
  }
});

const getPrivateHeaders = () => {
  const token = localStorage.getItem('authToken') || '';
  return ({
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });
};

export const loginWithEmail = (email, password) =>
  axios.post(`${AUTH_SERVER_URL}/api/auth/login`, { email, password }, getHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const loginWithGmail = (data) =>
  axios.post(`${AUTH_SERVER_URL}/api/auth/loginWithGmail`, { oAuthData: data }, getHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const existEmail = (email) =>
  axios.post(`${AUTH_SERVER_URL}/api/auth/isEmail`, { email }, getHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const resetPasswordRequest = (email) =>
  axios.post(`${AUTH_SERVER_URL}/api/auth/reset_password_request`, { email }, getHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const resetPassword = (password, token) =>
  axios.post(`${AUTH_SERVER_URL}/api/auth/reset_password`, { password, token }, getHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const updateUsername = (obj) =>
  axios.post(`${AUTH_SERVER_URL}/api/auth/updateUsername`, obj, getPrivateHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const resendEmailConfirm = (obj) =>
  axios.post(`${AUTH_SERVER_URL}/api/auth/resendEmailConfirmation`, obj, getPrivateHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const registerWithEmail = (
    name, email, password, phone, companyName, businessType, region, onboardStatus, monthlyTransactions, salesTaxFreq, fiscalYearEnd, socialMode
  ) =>
  axios.post(`${AUTH_SERVER_URL}/api/auth/register`, {
    name, email, password, phone, companyName, businessType, region, onboardStatus, monthlyTransactions, salesTaxFreq, fiscalYearEnd, socialMode
  }, getHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const confirmEmail = (token) =>
  axios.post(`${AUTH_SERVER_URL}/api/auth/verify`, { token }, getHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const getRegions = () =>
  axios.post(`${AUTH_SERVER_URL}/api/auth/getRegions`, null, getHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

/**
 *  Salesforce API
 */
export const getProfile = (email) =>
  axios.post(`${AUTH_SERVER_URL}/api/salesforce/profile`, { email }, getPrivateHeaders())
    .then(({ data }) => data)
    .catch((err) => {
      const data = err.response.data;
      if (data && data.tokenStatus === 301) {
        localStorage.clear();
        window.location.reload();
      }
      return Promise.reject(err.response.data)
    });

export const getProducts = (email) =>
  axios.post(`${AUTH_SERVER_URL}/api/salesforce/product`, { email }, getPrivateHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const getServices = (email) =>
  axios.post(`${AUTH_SERVER_URL}/api/salesforce/service`, { email }, getPrivateHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const updateProfile = (obj) =>
  axios.post(`${AUTH_SERVER_URL}/api/salesforce/updateprofile`, obj, getPrivateHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const getCloudProducts = (email) =>
  axios.post(`${AUTH_SERVER_URL}/api/salesforce/get_cmp`, { email }, getPrivateHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const createCloudProducts = (obj) =>
  axios.post(`${AUTH_SERVER_URL}/api/salesforce/create_cmp`, obj, getPrivateHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const getCloudServices = (email) =>
  axios.post(`${AUTH_SERVER_URL}/api/salesforce/get_cms`, { email }, getPrivateHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const createCloudServices = (obj) =>
  axios.post(`${AUTH_SERVER_URL}/api/salesforce/create_cms`, obj, getPrivateHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const getNotifications = (email) =>
  axios.post(`${AUTH_SERVER_URL}/api/salesforce/notifications`, { email }, getPrivateHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const markAsReadMsg = (obj) =>
  axios.post(`${AUTH_SERVER_URL}/api/salesforce/mark_as_read`, obj, getPrivateHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const getCloudAdvisors = (obj) =>
  axios.post(`${AUTH_SERVER_URL}/api/salesforce/advisors`, obj, getPrivateHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const getCloudTasks = (email) =>
  axios.post(`${AUTH_SERVER_URL}/api/salesforce/tasks`, { email }, getPrivateHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const getXeroTemplates = (obj) =>
  axios.post(`${AUTH_SERVER_URL}/api/salesforce/xero_templates`, obj, getPrivateHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const getMyTemplates = (email) =>
  axios.post(`${AUTH_SERVER_URL}/api/salesforce/my_templates`, { email }, getPrivateHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const getCloudmebTargets = (email) =>
  axios.post(`${AUTH_SERVER_URL}/api/salesforce/get_targets`, { email }, getPrivateHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const getCloudPrograms = (obj) =>
  axios.post(`${AUTH_SERVER_URL}/api/salesforce/cloud_programs`, obj, getPrivateHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const getTeams = (email) =>
  axios.post(`${AUTH_SERVER_URL}/api/salesforce/teams`, { email }, getPrivateHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const getStorages = (email) =>
  axios.post(`${AUTH_SERVER_URL}/api/salesforce/storages`, { email }, getPrivateHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const getMentors = (email) =>
  axios.post(`${AUTH_SERVER_URL}/api/salesforce/mentors`, { email }, getPrivateHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const markTaskAsComplete = (obj) =>
  axios.post(`${AUTH_SERVER_URL}/api/salesforce/mark_task_done`, obj, getPrivateHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const createSubscriptionProduct = (obj) =>
  axios.post(`${AUTH_SERVER_URL}/api/salesforce/create_subscription_product`, obj, getPrivateHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const createSubscriptionService = (obj) =>
  axios.post(`${AUTH_SERVER_URL}/api/salesforce/create_subscription_service`, obj, getPrivateHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const createSubscriptionTemplate = (obj) =>
    axios.post(`${AUTH_SERVER_URL}/api/salesforce/create_subscription_template`, obj, getPrivateHeaders())
        .then(({ data }) => data)
        .catch((err) => Promise.reject(err.response.data));

export const cancelSubscriptionProduct = (obj) =>
  axios.post(`${AUTH_SERVER_URL}/api/salesforce/cancel_subscription_product`, obj, getPrivateHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const cancelSubscriptionService = (obj) =>
  axios.post(`${AUTH_SERVER_URL}/api/salesforce/cancel_subscription_service`, obj, getPrivateHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const createCustomer = (obj) =>
  axios.post(`${AUTH_SERVER_URL}/api/salesforce/create_customer`, obj, getPrivateHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const getKnowledges = () =>
  axios.post(`${AUTH_SERVER_URL}/api/know/educations`, {}, getPrivateHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const getGuides = () =>
    axios.post(`${AUTH_SERVER_URL}/api/know/resources`, {}, getPrivateHeaders())
        .then(({ data }) => data)
        .catch((err) => Promise.reject(err.response.data));
/**
 *  Xero API
 */
export const getConsentUrl = () =>
  axios.post(`${AUTH_SERVER_URL}/api/xero/consentUrl`, {}, getPrivateHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const setXeCallback = (obj) =>
  axios.post(`${AUTH_SERVER_URL}/api/xero/callback`, obj, getPrivateHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const getXeroInvoices = () =>
  axios.post(`${AUTH_SERVER_URL}/api/xero/getInvoices`, {}, getPrivateHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const getExecutiveSummary = () =>
  axios.post(`${AUTH_SERVER_URL}/api/xero/executiveSummary`, {}, getPrivateHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const getXeroAccounts = () =>
  axios.post(`${AUTH_SERVER_URL}/api/xero/accounts`, {}, getPrivateHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));


export const getXeroBankTransactions = () =>
  axios.post(`${AUTH_SERVER_URL}/api/xero/bankTransactions`, {}, getPrivateHeaders())
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response.data));

export const requestPointsRequest = () =>
    axios.post(`${AUTH_SERVER_URL}/api/salesforce/requestPoints`, {}, getPrivateHeaders())
        .then(({ data }) => data)
        .catch((err) => Promise.reject(err.response.data));
