import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";

import { STORE_KEYS } from '@/stores';
import Item from './menu-item';
import LanguageSelect from "../header/LanguageSelect";
import UserSettingsMenu from "./user-settings-menu"
import NotificationPanel from "./NotificationPanel";
import MobileMenu from "./MobileMenu";
import PtsInfoBox from "./PtsInfoBox";
import {
  SvgChecklistIcon, SvgChecklistSmIcon, SvgEducationIcon, SvgEducationSmIcon, SvgMenuIcon, SvgLogoCompleteIcon,
  SvgSolutionsIcon, SvgSolutionsSmIcon, SvgTargetIcon, SvgTargetSmIcon, SvgLogolabelIcon, SvgLogoIcon,
} from "@/components/SvgIcon";
import {
  Container, Left, Logo, SmallLogo, Menu, Right, ConnectProduct, Notice, pulse, Dot, PtsInfo, MobileMenuIcon
} from "./styles";
import i18n from '../../i18n';

const Header = ({
  path, activeTargetsLength, lengthOfTodoTasks, totalPoints = 0, businessName, readNotifyMsg, isNewMsg, requestPtsRequest,
}) => {
  const [ currentWidth, setCurrentWidth ] = useState(1302);
  const [ isShowNotice, setIsShowNotice ] = useState(false);
  const [ isShowMobileMenu, setIsShowMobileMenu] = useState(false);
  const lang = localStorage.getItem('lang');
  const [selectedLanguageId, setSelectedLanguageId] = React.useState(lang || 'en');

  const languageOptions = [
    {
      'id': 'de', 'name': 'Deutsch'
    },
    {
      'id': 'en', 'name': 'English'
    },
    {
      'id': 'es', 'name': 'Español'
    },
    {
      'id': 'fr', 'name': 'Français'
    },
    {
      'id': 'it', 'name': 'Italiano'
    },
    {
      'id':'pt', 'name': 'Português'
    },
    {
      'id':'chs', 'name': '汉语'
    },
    {
      'id':'ja', 'name': '日本語'
    },
    {
      'id':'ko', 'name': '한국어'
    },
  ];

  let activeNo = 0;
  if (path === '/') {
    activeNo = 1;
  }
  else if (path.includes('targets')) {
    activeNo = 2;
  }
  else if (path.includes('checklist')) {
    activeNo = 3;
  }
  else if (path.includes('education')) {
    activeNo = 4;
  }
  else if (path.includes('/solution')) {
    activeNo = 5;
  }
  const targetsLength = Number(activeTargetsLength) || 0;
  const totalChecklist = Number(lengthOfTodoTasks) || 0;

  useEffect(() => {
    window.onresize = () => {
      setCurrentWidth(window.innerWidth);

      if (window.innerWidth > 576) {
        setIsShowMobileMenu(false);
      }
    }
  }, [])

  const userMenuItems = [
    {
      id: 2,
      icon: <SvgTargetIcon/>,
      small_icon: <SvgTargetSmIcon/>,
      label: i18n.t('dashboard.targets').toUpperCase(),
      count: targetsLength,
      link: '/targets'
    },
    {
      id: 3,
      icon: <SvgChecklistIcon/>,
      small_icon: <SvgChecklistSmIcon/>,
      label: i18n.t('dashboard.checklists').toUpperCase(),
      count: totalChecklist || 0,
      link: '/checklist'
    },
    {
      id: 4,
      icon: <SvgEducationIcon/>,
      small_icon: <SvgEducationSmIcon/>,
      label: i18n.t('dashboard.education').toUpperCase(),
      link: '/education'
    },
    {
      id: 5,
      icon: <SvgSolutionsIcon/>,
      small_icon: <SvgSolutionsSmIcon/>,
      label: i18n.t('dashboard.solutions').toUpperCase(),
      link: '/solution'
    },
  ];

  const userInfo = {
    photo: '/images/icons/Icon_User_Avatar.svg',
    name: businessName,
  }

  return (
    <Container>
      <Left>
        <Link to={'/'} class={"main_logo"}>
          <SvgLogoCompleteIcon/>
          <SvgLogoIcon/>
        </Link>
        <Menu>
          {
            userMenuItems.map(item => (
              <Fragment key={item.id}>
                <Item
                  {...item} active={item.id === activeNo}
                  />
              </Fragment>
            ))
          }
        </Menu>
      </Left>
      <Right>
        <LanguageSelect
          name="languages"
          viewMode="img"
          selectedOptionId={selectedLanguageId}
          setOption={setSelectedLanguageId}
          options={languageOptions}
          placeholder="..."
          dashboard
        />
        <PtsInfoBox
            totalPoints={totalPoints}
            requestPtsRequest={requestPtsRequest}
        />
        <Notice onClick={() => {
          setIsShowNotice(!isShowNotice);
          readNotifyMsg();
        }}>
          <img src={"/images/icons/Icon_Notifications_Dark.svg"}/>
          <span className={isNewMsg ? "live_alert" : ""}/>
        </Notice>
        <UserSettingsMenu {...userInfo} />
        <MobileMenuIcon onClick={ () => setIsShowMobileMenu(!isShowMobileMenu)}>
          {
            !isShowMobileMenu ?
              <img src={'/images/icons/Icon_Mobile_Menu_Dark.svg'} alt={'Mobile Icon'} />
            :
              <img src={'/images/icons/Icon_Mobile_Menu_Active_Dark.svg'} alt={'Mobile Icon'} />
          }

        </MobileMenuIcon>
      </Right>
      <NotificationPanel isShow={isShowNotice} showToggle={setIsShowNotice}/>
      <MobileMenu
          isShow={isShowMobileMenu} showToggle={setIsShowMobileMenu}
          data={userMenuItems} activeNo={activeNo} businessName={businessName}
      />
    </Container>
  );
}

export default compose(
    inject(STORE_KEYS.TARGETSSTORE, STORE_KEYS.CHECKLISTSTORE, STORE_KEYS.SALESFORCESTORE, STORE_KEYS.NOTIFICATIONSTORE),
    observer,
    withProps(
        ({
           [STORE_KEYS.TARGETSSTORE]: {
             activeTargetsLength,
           },
           [STORE_KEYS.CHECKLISTSTORE]: {
             lengthOfTodoTasks,
           },
           [STORE_KEYS.SALESFORCESTORE]: {
             totalPoints,
             businessName,
           },
           [STORE_KEYS.NOTIFICATIONSTORE]: {
             readNotifyMsg,
             isNewMsg,
             requestPtsRequest,
           }
         }) => ({
          activeTargetsLength,
          lengthOfTodoTasks,
          totalPoints,
          businessName,
          readNotifyMsg,
          isNewMsg,
          requestPtsRequest,
        })
    )
)(Header);
