import React, {useEffect} from "react";
import {compose, withProps} from "recompose";
import {inject, observer} from "mobx-react";
import i18n from '../../../i18n';
import {STORE_KEYS} from '@/stores';
import ArticleCard from "../../../components/dashboard/Education/ArticleCard";
import {Content, Right, Center, SubPanel, SubTitle, WhiteButton} from "../styles";
import HelpCenter from "../../../components/dashboard/Education/HelpCenter";

const Integration = ({guidesData, getGuidesData}) => {

    useEffect(() => {
        getGuidesData();
    }, [])

    let integrations = [];
    if ((guidesData || []).length > 0) {
        (guidesData || []).map((item, index) => {
            integrations.push({
                title: item.name || '',
                img: item.logo || '',
                type: item.category[0],
                readNum: '',
                link: item.permalink || '',
            })
        });
    }

    return (
        <Content>
            <Center>
                <div className={'wrapper'}>
                    <SubPanel>
                        <div className={'title'}>
                            {i18n.t('dashboard.integrationGuides')}
                        </div>
                        <div className={'row'}>
                            {
                                integrations.map((article, index) => {
                                    return (
                                        <div className={'col-xl-3 col-sm-6 mb-4'} key={index}>
                                            <ArticleCard article={article} size='md'/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </SubPanel>
                    <div className="text-center">
                        <WhiteButton>{i18n.t('dashboard.loadMore')}</WhiteButton>
                    </div>
                </div>
            </Center>
            <Right>
                <div className={'wrapper'}>
                    <SubTitle>{i18n.t('dashboard.helpCenters')}</SubTitle>
                    <p>{i18n.t('dashboard.connectHelpCenters')}</p>
                    <HelpCenter/>
                </div>
            </Right>
        </Content>
    )
}

export default compose(
    inject(STORE_KEYS.KNOWLEDGESTORE),
    observer,
    withProps(
        ({
             [STORE_KEYS.KNOWLEDGESTORE]: {guidesData, getGuidesData},
         }) => ({
            guidesData,
            getGuidesData,
        })
    )
)(Integration);
