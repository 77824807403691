import React from "react";
import {compose, withProps} from "recompose";
import {inject, observer} from "mobx-react";
import i18n from '../../../../i18n';
import {STORE_KEYS} from '@/stores';
import ConfirmPopup from "@/components/dialogs/ConfirmPopup";
import {SmallLinkButton} from "../../styles";
import {Wrapper, SmallButton, MobileFooter, ActionBtn} from "./styles";
import i18next from "i18next";


const SolutionCard = ({data, requestCancelProduct, requestCancelService}) => {
    const [alertMode, setAlertMode] = React.useState(false);

    const onCancelSolution = () => {
        setAlertMode(true);
    };

    const onAlertAction = (mode) => {
        setAlertMode(false);
        if (mode) {
          const {type, subscriptionId, solutionId} = data;
          if (type === "product") {
            requestCancelProduct(solutionId, subscriptionId);
          } else if (type === "service") {
            requestCancelService(solutionId, subscriptionId);
          }
        }
    };

    const subscriptionId = data.subscriptionId || 'One-Time';

    return (
        <Wrapper inActive={data.status === 'Inactive'}>
            <div className={'name'}>
                <div className="d-flex align-items-center">
                    <img src={data.icon} alt={"Image"}/>
                    <div>
                        <h3>{data.name}</h3>
                    </div>
                </div>
                <ActionBtn onClick={onCancelSolution}><img src={'/images/dashboard/cancel_icon.png'} alt={""}/></ActionBtn>
            </div>
            <div className={'price mobile-hidden'}>
                <p>{i18n.t('dashboard.payment').toUpperCase()}</p>
                <h3 className={'price-num'}>
                    ${data.price} <p>{data.currency}{`${subscriptionId !== 'One-Time' ? '/MO' : ''}`}</p>
                </h3>
            </div>
            <div className={'subscribed mobile-hidden'}>
                <p>{data.subscribed && 'Subscribed' || data.ordered && 'Ordered'}</p>
                <h3>{data.subscribed || data.ordered}</h3>
            </div>
            <div className={'status mobile-hidden'}>
                {
                    data.status &&
                    <>
                        <p>{i18n.t('dashboard.status').toUpperCase()}</p>
                        <h3>{data.status}</h3>
                    </>
                }
            </div>
            <MobileFooter>
                <div className={'price'}>
                    <p>{i18n.t('dashboard.payment').toUpperCase()}</p>
                    <h3 className={'price-num'}>${data.price} <p>{data.currency} <span>{`${subscriptionId !== 'One-Time' ? '/MO' : ''}`}</span></p></h3>
                </div>
                <div className={'subscribed'}>
                    <p>{data.subscribed && 'SUBSCRIBED' || data.ordered && 'ORDERED'}</p>
                    <h3>{data.subscribed || data.ordered}</h3>
                </div>
                <div className={'status'}>
                    {
                        data.status &&
                        <>
                            <p>{i18n.t('dashboard.status').toUpperCase()}</p>
                            <h3>{data.status}</h3>
                        </>
                    }
                </div>
            </MobileFooter>
            <div className={'action mobile-hidden'}>
                {
                    data.status === 'Inactive' ?
                        <></>
                        :
                        data.cancellable ?
                            <SmallButton onClick={onCancelSolution}>{i18n.t('dashboard.cancel').toUpperCase()}</SmallButton>
                            :
                            <SmallLinkButton href={data.link} target="_blank">{i18n.t('dashboard.download').toUpperCase()}</SmallLinkButton>
                }
            </div>
            <ConfirmPopup
                alertMode={alertMode}
                alertAction={onAlertAction}
            />
        </Wrapper>
    );
};

export default compose(
    inject(STORE_KEYS.MYSOLUTIONSSTORE),
    observer,
    withProps(
        ({
             [STORE_KEYS.MYSOLUTIONSSTORE]: {
                 requestCancelProduct,
                 requestCancelService,
             },
         }) => ({
            requestCancelProduct,
            requestCancelService,
        })
    )
)(SolutionCard);
