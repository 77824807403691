import React, { Component } from 'react';
import queryString from 'query-string';
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";
import { Redirect } from 'react-router-dom';
import i18n from '../../i18n';
import { STORE_KEYS } from '@/stores';
import { Wrapper } from './styles';
import imgXero from './assets/img_xero.png';

class XeroCallback extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
    };
  }

  componentDidMount() {
    const { setXeroCallback, location, setViewMode } = this.props;
    const values = queryString.parse(location.search);
    setXeroCallback(values.code, values.scope, values.session_state);

    setTimeout(() => {
      setViewMode('xero_reports');
      this.setRedirect();
    }, 5000);
  }

  setRedirect = () => {
    this.setState({
      redirect: true
    })
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='/home' />
    }
  };

  render() {
    const { xeroOrgs } = this.props;
    const orgs = [];
    try {
      for (let i = 0; i < xeroOrgs.length; i++) {
        orgs.push(
          <li key={i}>
            {`Org Name: ${xeroOrgs[i].tenantName || ''}`}
          </li>
        );
      }
    } catch (e) {}

    return (
      <>
        {this.renderRedirect()}
        <Wrapper>
          <div className="text-center">
            <br/><br/><br/><br/>
            <img src={imgXero} alt="" style={{
              width: 100,
              height: 100,
              marginBottom: 20,
            }}/>
            <h4>{i18n.t('dashboard.importingFinancial')}</h4>
            <ul style={{
              textAlign: 'left',
              display: 'inline-block',
              lineHeight: '40px',
              marginTop: '20px',
            }}>
              {orgs}
            </ul>
            <br/><br/><br/><br/>
          </div>
        </Wrapper>
      </>
    );
  }
}

export default compose(
  inject(STORE_KEYS.XEROSTORE, STORE_KEYS.VIEWMODESTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.XEROSTORE]: {
         xeroOrgs,
         setXeroCallback,
       },
       [STORE_KEYS.VIEWMODESTORE]: {
         setViewMode,
       },
     }) => ({
      xeroOrgs,
      setXeroCallback,
      setViewMode,
    })
  )
)(XeroCallback);
