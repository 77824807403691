import styled,{css} from "styled-components";
import {Link} from "react-router-dom";
import {CommonButton} from "../../components/common/button";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: white;
`;

export const SubHeader = styled.div`
  padding: 19px 0 19px 30px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  flex-wrap: wrap;
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  font-family: Circe-Bold;
  color: #171d26;
  width: 20%;
  white-space: nowrap;
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  margin: 0 auto;
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

export const Center = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  overflow: auto;
  
  &::-webkit-scrollbar-thumb {
    background-color: #e5e5e5;
    border-radius: 2.5px;
    width: 5px;
  }
  
  &::-webkit-scrollbar {
    width: 5px;
    padding: 5px;
  }
  
  &::-webkit-scrollbar-track {
    display: none;
  }
  
  .wrapper {
    height: calc(100vh - 126px - 50px);
    padding: 1.5rem 2rem;
    width: 65%;
    
    @media (max-width: 1300px) {
      height: calc(100vh - 116px - 50px);
      width: 75%;
    }
    
    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      height: unset;
      width: 85%;
      overflow: initial;
    }
    
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      width: 100%;
    }
  }
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    border: none;
  }
`;

export const SubPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  
  .title {
    font-family: Circe-Bold;
    font-size: 20px;
    color: #75787d;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    
    span {
      margin-left: 10px;
      margin-top: 4px;
      font-size: 15px;
    }
  }
  
  .content {
    width: 100%;
    
    .sub-title {
      font-family: Circe-Bold;
      font-size: 18px;
      color: #a3a5a9;
      margin-bottom: 20px;
    }
  }
  
`;

export const ExploreButton = styled(CommonButton)`
  background: #f5f7f9;
  color: #5e7599;
  
  &:hover {
    background: #e6e9eb;
  }
`;

export const SmallButton = styled.button`
  padding: 8px 10px;
  color: #7f8489;
  font-family: Circe-Bold;
  font-size: 12px;
  background: none;
  border-radius: 3px;
  border: 1px solid #e7e8e9;
`;

export const SmallLinkButton = styled.a`
  padding: 8px 10px;
  color: #7f8489;
  font-family: Circe-Bold;
  font-size: 12px;
  background: none;
  border-radius: 3px;
  border: 1px solid #e7e8e9;
  letter-spacing: 1px;
`;
