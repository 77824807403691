import React, { useState, Fragment } from "react";
import styled, { css } from "styled-components";
import {productColors} from "../../constants";
import i18n from '../../i18n';

const ProductCard = ({ data, toggleOpenModal }) => {

  const {
    Product_Logo_url__c,
    Name,
    Product_Sub_text__c,
    color_code_hover__c,
    Category__c,
    Description__c,
    Cloudmeb_Price__c,
    Retail_Price__c,
    CurrencyIsoCode,
    Frequency__c,
  } = data;
  const [ isHovered, setHover ] = useState(false);

  return (
    <Fragment>
      <Container onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)}  >
        <Header style={{backgroundColor: isHovered ? color_code_hover__c : 'white'}}>
          <Icon src={Product_Logo_url__c} />
          <div>
            <h3 style={{color: isHovered ? 'white' : '#405c85'}}>{Name}</h3>
            <p style={{color: isHovered ? 'white' : '#97a4b7'}}>{Product_Sub_text__c}</p>
          </div>
        </Header>
        <Content>
          <p className={'property'}>{Category__c}</p>
          <Desc>
            <p>
              {Description__c}
            </p>
          </Desc>
        </Content>
        <Footer>
          <div>
            {
              Retail_Price__c ?
                <p className={'prev-price'}>${Retail_Price__c} <span>{CurrencyIsoCode}/MO</span></p>
              : <></>
            }
            <p className={'price'}>${Cloudmeb_Price__c} <span>{CurrencyIsoCode}/{Frequency__c}</span></p>
          </div>
          <AddButton
            style={{color: isHovered ? 'white' : '#72767c', backgroundColor: isHovered ? '#5d7599' : ''}}
            onClick={() => toggleOpenModal(true, data)}
          >
            {i18n.t('dashboard.add')}
          </AddButton>
        </Footer>
      </Container>
    </Fragment>
  )
}

//styles
const Container = styled.div`
  box-shadow: 0 0 20px #f5f5f5;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: 100%;
  
  &:hover {
    box-shadow: 0 0 40px #f5f5f5;
  }
`

const Header = styled.div`
  display: flex;
  border-bottom: 1px solid #ebeef2;
  padding: 10px 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: none;
  
  h3 {
    margin-left: 10px;
    font-size: 16px;
  }
  
  p {
    margin-left: 10px;
    font-size: 12px;
  }
`

const Icon = styled.img`
  width: 28px;
  height: 28px;
  border-radius: 100%;
`

const Content = styled.div`
  flex: 1;
  padding: 10px 15px;
  
  .property {
    font-size: 11px;
    padding-bottom: 5px;
    color: #8295b0;
  }
`

const Desc = styled.div`
  display: flex;
  align-items: flex-start;
  
  span {
    font-size: 31px;
    line-height: 14px;
    margin-right: 5px;
  }
  
  p {
    font-size: 12px;
    line-height: 1.9;
    white-space: break-spaces;
  }
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 12px;
  background-color: #fbfefe;
  min-height: 50px;
  
  .prev-price {
    font-size: 10px;
    font-weight: bold;
    text-decoration: line-through;
    color: #afb3b6;
    
    span {
      font-weight: 400;
    }
  }
  
  .price {
    font-size: 17px;
    font-weight: bold;
    color: #405c85;
    
    span {
      font-size: 12px;
      font-weight: 400;
    }
  }
`

const AddButton = styled.button`
  font-size: 10px;
  padding: 4px 10px;
  font-family: Circe-Regular;
  background: none;
  border-radius: 3px;
  border: 1px solid #e3e7e8;
  cursor: pointer;
  letter-spacing: 1px;
  
  &:hover {
    background-color: #415c85 !important;
    color: #fff;
    border-color: #415c85;
  }
`

export default ProductCard;
