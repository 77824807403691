import React, { useState } from 'react';
import PropTypes from 'prop-types';

import PopoverModal from './PopoverModal';
import CheckBox from './CheckBox';

import { Action, Wrapper } from './styles';

const CheckAction = (props) => {
  const {
    value, isExpired, onActionProcess,
  } = props;

  const [modal, setModal] = useState(false);
  const [checked, setChecked] = useState(value);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  return (
    <Wrapper
      onClick={openModal}
      onMouseEnter={openModal}
      onMouseLeave={closeModal}
      bgColor={isExpired && '#d37466'}
      hoverColor={isExpired && '#c37167'}
    >
      <Action>
        <PopoverModal
          isOpen={modal}
          closeModal={closeModal}
          onAgree={() => {
            if (!value) {
              setChecked(true);
              onActionProcess(true);
            }
          }}
          onDisAgree={() => {
            if (value) {
              setChecked(false);
              onActionProcess(false);
            }
          }}
        />
        <CheckBox
          type={isExpired ? 1 : 2}
          checked={checked}
        />
      </Action>
    </Wrapper>
  );
};

CheckAction.propTypes = {
  type: PropTypes.oneOf(["EXPIRED", "COMPLETED"]),
};

CheckAction.defaultProps = {
  type: "COMPLETED",
};

export default CheckAction;
