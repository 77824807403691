import React from "react";
import styled from "styled-components";
import i18n from '../../i18n';
//components
import LoginTitle from "../../components/login/login-title";
import Input from "../../components/common/Input";
import Button from "../../components/common/button";
import SignUpStatusBar from "../../components/common/SignUpStatusBar";

//styles
const Wrapper = styled.div`
  width: 100%;
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SignUp = styled.div`
  flex: 1;
  display: flex;
  
   @media (max-width: ${props => props.theme.breakpoints.lg}) {
    background-image: url("/images/login/img_msg_sent.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
`;

const Brand = styled.div`
  flex: 1;
  background: #f7fdfd;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  
  > img {
    max-width: 500px;
    width: 100%;
  }
  
   @media (max-width: ${props => props.theme.breakpoints.lg}) {
    display: none;
  }
`;

const SignUpForm = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 30px;
  background-color: #fff;
  
   @media (max-width: ${props => props.theme.breakpoints.lg}) {
    background-color: rgba(234, 249, 250, 0.9);
  }
`;

const FormContent = styled.div`
  max-width: 480px;
  width: 100%;
  
   @media (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: unset;
  }
`;

const FormBody = styled.div`
  max-width: 400px;
  
   @media (max-width: ${props => props.theme.breakpoints.lg}) {
    max-width: unset;
  }
  
  > div {
    margin-top: 30px;
  }
`

const BtnGroup = styled.div`
  display: flex;
  margin-top: 50px;
  
   @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
  }
`;

const SignUpPage = ({ setMode, checkExistEmail, showSnackMsg }) => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [repassword, setRepassword] = React.useState('');


  const onClickNextBtn = async () => {
    if (!validateEmail(email)) {
      showSnackMsg('Email is not valid.');
      return;
    }

    if ((email || '').trim().length === 0 || (password || '').trim().length === 0) {
      showSnackMsg('Email or Password is not valid.');
      return;
    }

    if (password !== repassword) {
      showSnackMsg('Password does not match.');
      return;
    }

    const isExist = await checkExistEmail(email);
    if (isExist) {
      showSnackMsg('Email is already existing.');
      return;
    }
    setMode(1, email, password);
  };

  const validateEmail = (email) => {
    return /^(([a-zA-Z0-9_.-])+@([a-zA-Z0-9_.-])+\.([a-zA-Z])+([a-zA-Z])+)?$/.test(email);
  };

  const onChangeEmail = (e) => {
    setEmail(e.target.value || '');
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value || '');
  };

  const onChangeRepassword = (e) => {
    setRepassword(e.target.value || '');
  };

  return (
    <Wrapper>
      <SignUp>
        <Brand>
          <img src="/images/login/img_msg_sent.png" alt=""/>
        </Brand>
        <SignUpForm>
          <FormContent>
            <LoginTitle title={i18n.t('auth.createAccount')}/>
            <FormBody>
              <SignUpStatusBar step={1}/>
              <Input label={i18n.t('auth.emailUpper')} type={'text'} placeholder={i18n.t('auth.emailLower')} onChange={onChangeEmail} value={email} />
              <Input label={i18n.t('auth.passwordUpper')} type={'password'} placeholder={i18n.t('auth.passwordLower')} onChange={onChangePassword} value={password} />
              <Input label={i18n.t('auth.retypePassword')} type={'password'} placeholder={i18n.t('auth.passwordLower')} onChange={onChangeRepassword} value={repassword} />
            </FormBody>
            <BtnGroup>
              <Button label={i18n.t('auth.next')} onClick={onClickNextBtn} />
            </BtnGroup>
          </FormContent>
        </SignUpForm>
      </SignUp>
    </Wrapper>
  )
}

export default SignUpPage;
