import styled, {css} from "styled-components";
import {Link} from "react-router-dom";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: white;
`;

export const SubHeader = styled.div`
  padding: 19px 0 19px 30px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  flex-wrap: wrap;
  
  @media (max-width: 750px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  font-family: Circe-Bold;
  color: #171d26;
  width: 20%;
  
  @media (max-width: 750px) {
    width: 100%;
  }
`;

export const Tabs = styled.div`
  flex: 1;
  display: flex;
  
  @media (max-width: 750px) {
    flex-direction: column;
    align-self: flex-start;
  }
`;

export const TabItem = styled.div`
  font-size: 20px;
  font-family: Circe-Bold;
  color: #171d26;
  margin-left: 40px;
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  text-decoration: none;
  
  color: ${props => props.active === 'active' ? '#171d26' : '#647a9d'};
  border-bottom: ${props => props.active === 'active' ? '3px solid #181e26' : '3px solid #fff'};
  
  @media (max-width: 750px) {
    border-bottom: none;
    padding-left: 10px;
    margin-left: 0;
    border-left: ${props => props.active === 'active' ? '3px solid #181e26' : '3px solid #fff'};
  }
  
  span {
    font-size: 14px;
    color: #9da9bb;
    padding-left: 5px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 2rem;
  }
`;

export const Left = styled.div`
  flex: 0 0 20%;
  max-width: 20%;
  min-width: 250px;
  overflow: auto;
  height: calc(100vh - 126px - 50px);
  margin-right: 4px;
  box-sizing: border-box;
  
  &::-webkit-scrollbar-thumb {
    background-color: #e5e5e5;
    border-radius: 2.5px;
    width: 5px;
  }
  
  &::-webkit-scrollbar {
    width: 5px;
  }
  
  &::-webkit-scrollbar-track {
    display: none;
  }
  
  .wrapper {
     padding: 1.5rem 2rem;
    
     @media (max-width: ${props => props.theme.breakpoints.md}) {
      padding: 0;
      margin-bottom: 30px;
    }
  }
  
  @media (max-width: 1300px) {
    height: calc(100vh - 116px - 50px);
  }
  
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    height: calc(100vh - 136px - 50px);
  }
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    height: unset;
    flex: 1;
    max-width: 100%;
    width: 100%;
  }
`;

export const Center = styled.div`
  flex: 1;
  border-left: 1px solid #e5e5e5;
  
  .wrapper {
    height: calc(100vh - 126px - 50px);
    overflow: auto;
    margin-right: 4px;
    padding: 1.5rem 2rem;
    
    &::-webkit-scrollbar-thumb {
      background-color: #e5e5e5;
      border-radius: 2.5px;
      width: 5px;
    }
    
    &::-webkit-scrollbar {
      width: 5px;
      padding: 5px;
    }
    
    &::-webkit-scrollbar-track {
      display: none;
    }
   
     @media (max-width: 1300px) {
       height: calc(100vh - 116px - 50px);
     }
     
     @media (max-width: ${props => props.theme.breakpoints.lg}) {
       height: calc(100vh - 136px - 50px);
     }
     
     @media (max-width: ${props => props.theme.breakpoints.md}) {
       height: unset;
       flex: 0 0 100%;
       max-width: 100%;
       overflow: initial;
       padding: 0;
     }
  }
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    border: none;
  }
`;

export const Right = styled.div`
  flex: 0 0 20%;
  max-width: 20%;
  min-width: 250px;
  overflow: auto;
  height: calc(100vh - 126px - 50px);
  margin-right: 4px;
  box-sizing: border-box;
  border-left: 1px solid #e5e5e5;
  
  &::-webkit-scrollbar-thumb {
    background-color: #e5e5e5;
    border-radius: 2.5px;
    width: 5px;
  }
  
  &::-webkit-scrollbar {
    width: 5px;
  }
  
  &::-webkit-scrollbar-track {
    display: none;
  }
  
  .wrapper {
     padding: 1.5rem 2rem;
    
     @media (max-width: ${props => props.theme.breakpoints.md}) {
      padding: 0;
      margin-bottom: 30px;
    }
  }
  
  @media (max-width: 1300px) {
    height: calc(100vh - 116px - 50px);
  }
  
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    height: calc(100vh - 136px - 50px);
  }
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-top: 30px;
    height: unset;
    flex: 1;
    max-width: 100%;
    width: 100%;
  }
`;

export const SubPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  
  .title {
    font-family: Circe-Bold;
    font-size: 20px;
    color: #75787d;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    
    span {
      margin-left: 10px;
      margin-top: 4px;
      font-size: 15px;
    }
  }
  
  .content {
    display: flex;
    width: 100%;
  }
`;

export const Button = styled.button`
  min-width: 85px;
  border-radius: 5px;
  background: #415c85;
  color: #e7eaef;
  outline: none;
  cursor: pointer;
  font-family: Circe-Bold;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  border: none;
  padding: 8px 20px;
  
  &:hover {
    background: #2f466a;
  }
`;

export const WhiteButton = styled(Button)`
  background: #f5f7f9;
  color: #6e83a3;
  
  &:hover {
    background: #e3e7eb;
  }
`;

export const FooterWrapper = styled.div`
  height: 50px;
  background: #fbfcfd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  font-family: Circe-Regular;
  
  p {
    margin-top: 5px;
    color: #74777d;
  }
  
  a {
    color: #5f7699;
  }
`;

export const SubTitle = styled.h2`
  margin-bottom: 20px;
  font-family: Circe-Bold;
  font-size: 20px;
  color: #75787d;
  width: 100%;
  
  span {
    font-size: 14px;
    color: #9ea0a4;
  }
  
  ${props => props.center && css`
    margin-left: 40px;
  `}
`;
