export const AUTH_SERVER_URL = process.env.REACT_APP_AUTH_SERVER_URL;
export const RETURN_URL = process.env.RETURN_URL;
export const CANCEL_URL = process.env.CANCEL_URL;
export const BUY_SIDE = 'Buy';
export const SELL_SIDE = 'Sell';

export const WS = {
  REAL: {
    HOST: process.env.REACT_APP_WS_PRIVATE_HOST,
    PORT: process.env.REACT_APP_WS_PRIVATE_PORT
  },
};
