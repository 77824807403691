export default {
  breakpoints: {
    xs: '400px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1450px'
  },
  colors: {
    background: "#0E1115",
    white: "#FFFFFF",
    black: "#000",
    hover: "#FAFAFA",
    dimLight: "rgba(255, 255, 255, .6)",
    primary: "#5A68F0",
    card: "#212730",
    textColor: "#191F27",
    red: "#EC253A",
    blue: "#3953FF",
    blueGradient: "linear-gradient(135deg, #6753F4, #4B80EB)",
    purpleGradient: "radial-gradient(circle, #7D46EE, #4A33B2)",
    redGradient: "linear-gradient(-45deg, #EE4949, #EE4989)",
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fontSizes: [14, 16, 18, 20, 26, 42, 54, 64],
  lineHeights: {
    head: 1.2,
    body: 1.428,
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  containerMaxWidth: 1400,
  contentMaxWidth: 1260,
  sectionMaxWidth: 1080,
  textMaxWidth: 500,
  imageMaxWidth: 450,
  subtextMaxWidth: 350,
  whiteSectionHeight: 832,
  whiteSectionMobileHeight: 400,
};
