import React from "react";
import styled, {css} from "styled-components";
import {compose, withProps} from "recompose";
import {inject, observer} from "mobx-react";
import { STORE_KEYS } from '@/stores';
import { Link } from "react-router-dom";

const DropDown = (props) => {
  const onChangeView = (id) => {
    const { setViewMode } = props;
    setViewMode(id);
    props.onClick();
  };

  return (
    <Container>
      {
        props.dropdownList.map((item, index) => (
          <Item key={index}>
            <Link to={item.link} onClick={() => onChangeView(item.viewMode)}>{item.label}</Link>
          </Item>
        ))
      }
    </Container>
  )
}

export default compose(
  inject(STORE_KEYS.VIEWMODESTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.VIEWMODESTORE]: { setViewMode, viewMode },
     }) => ({
      setViewMode,
      viewMode,
    })
  )
)(DropDown);

//styles
const Container = styled.ul`
  position: absolute;
  top: 40px;
  right: -10px;
  border-radius 5px;
  list-style-type: none;
  background-color: white;
  font-family: Circe-Regular;
  padding: 10px 0;
  z-index: 100;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.16);

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    right: unset;
    left: -10px;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    right: 0;
    left: unset;
  }
`;

const Item = styled.li`
  font-size: 14px;
  
  a {
    padding: 7px 20px;
    display: block;
    color: #4b648b;
    text-decoration: none;
  }
  
  &:hover {
    background-color: #f7fdfd;
    
    a {
      color: #1e242b;
    }
  }
  
  ${props => props.active && css`
    background-color: #f7fdfd;
    
    a {
      color: #1e242b;
    }
  `}
`
