import React, {useState} from "react";
import { CircularProgress } from '@material-ui/core';
import styled from "styled-components";
import {compose, withProps} from "recompose";
import {inject, observer} from "mobx-react";

import { STORE_KEYS } from '@/stores';

//styles
const Wrapper = styled.div`
  width: 100%;
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SignUp = styled.div`
  flex: 1;
  display: flex;
  
   @media (max-width: ${props => props.theme.breakpoints.lg}) {
    background-image: url("/images/login/img_msg_sent.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
`;

const Brand = styled.div`
  flex: 1;
  background: #f7fdfd;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  
  > img {
    max-width: 500px;
    width: 100%;
  }
  
   @media (max-width: ${props => props.theme.breakpoints.lg}) {
    display: none;
  }
`;

const SignUpForm = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 30px;
  background-color: #fff;
  
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    background-color: rgba(234, 249, 250, 0.9);
  }
  
  .message {
    text-align: center;
    padding-top: 20px;
    font-size: 20px;
    line-height: 35px;
    
    span {
        color: #7632d2;
    }
  }
`;

const FormContent = styled.div`
  max-width: 480px;
  width: 100%;
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: unset;
  }
`;

const ButtonVerify = styled.button`
  min-width: 260px;
  height: 50px;
  border-radius: 10px;
  background-color: #7632d2;
  padding: 9px 20px;
  font-family: Circe-Bold;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-left: 12px;
  margin-top: 20px;
  border: 0;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
  box-sizing: border-box;
  
  :hover {
    color: #fff;
  }
`;

const ActionContent = styled.div`
  display: block;
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  .actionBtn {
    color: #7632d2;
    background: transparent;
    border: 0;
    margin-top: 5px;
  }
  
  .emailForm {
     display: flex;
     justify-content: center;
     margin-top: 10px;
     margin-bottom: 10px;
     
     .updateInput {
        margin-right: 10px;
     }
     .updateBtn {
        
     }
  }
  
  .description {
    text-align: center;
    max-width: 400px;
    margin-top: 15px;
    
    span {
      font-weight: bold;
    }
  }
`;

const ConfirmPage = (props) => {
    const [isChangeEmail, setIsChangeEmail] = useState(false);
    const [isNeedHelp, setIsNeedHelp] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const {
      email, resendEmailConfirmation, changeEmailAndResendConfirmation, match,
    } = props;
    const isNormalConfirm = match.params.unverified === '0';

    const resendVerifyEmail = () => {
      setLoading(true);
      resendEmailConfirmation()
        .then(() => {
          setLoading(false);
        });
    };

    const handleChangeEmail = () => {
      setIsChangeEmail(!isChangeEmail);
    };

    const handleHelpNeed = () => {
      setIsNeedHelp(!isNeedHelp);
    };

    const updateAndResend = () => {
      changeEmailAndResendConfirmation(newEmail)
        .then((status) => {
          if (status) {
            setIsChangeEmail(false);
          }
        });
    };

    const onChangeNewEmail = (e) => {
      setNewEmail(e.target.value || '');
    };

    return (
        <Wrapper>
            <SignUp>
                <Brand>
                    <img src={"/images/login/img_msg_sent.png"} alt=""/>
                </Brand>
                <SignUpForm>
                    <FormContent>
                        Verify your email to proceed
                    </FormContent>
                    <div className="message">
                        {
                          isNormalConfirm ? (
                            <>
                              We just sent an email to the address: <span>{email}</span><br/>
                            </>
                          ) : (
                            <>
                              We had sent an email to the address: <span>{email}</span><br/>
                            </>
                          )
                        }

                        Please check your email and click on the link provided to<br/> verify your address
                    </div>
                    <ActionContent>
                      <button className="actionBtn" onClick={handleChangeEmail}>Change email</button>
                      {
                        isChangeEmail && (
                          <div className="emailForm">
                            <input type="text" className="updateInput" value={newEmail} onChange={onChangeNewEmail} />
                            <button className="updateBtn" onClick={updateAndResend}>Update & Resend</button>
                          </div>
                        )
                      }
                      <button className="actionBtn" onClick={handleHelpNeed}>I need help verifying my email</button>
                      {
                        isNeedHelp && (
                          <div className="description">
                            <span>Why do we ask for email confirmation?</span><br/>
                            Email confirmation is an important security check that helps prevent other people from signing up for an Cloudmeb account using your email address.<br/><br/>

                            <span>How do I confirm my email address?</span><br/>
                            We sent you an email with a link to click on. If you aren't able to click the link, copy the full URL from the email and paste it into a new web browser window.<br/><br/>

                            <span>If you haven't received the confirmation email, please:</span><br/>
                            Check the junk mail folder or spam filter in your email account.<br/>
                            Make sure your email address is entered correctly.
                          </div>
                        )
                      }
                    </ActionContent>
                    <ButtonVerify onClick={resendVerifyEmail} disabled={loading}>
                      { !loading && <span>Resend Verification Email</span> }
                      { loading && <CircularProgress color="secondary" /> }
                    </ButtonVerify>
                </SignUpForm>
            </SignUp>
        </Wrapper>
    )
}

export default compose(
  inject(STORE_KEYS.AUTHSTORE),
  observer,
  withProps(
    ({
         [STORE_KEYS.AUTHSTORE]: {
           email,
           resendEmailConfirmation,
           changeEmailAndResendConfirmation,
         },
     }) => ({
        email,
        resendEmailConfirmation,
        changeEmailAndResendConfirmation,
    })
  )
)(ConfirmPage);
