import React from "react";
import { css } from "@emotion/core";
// import PulseLoader from "react-spinners/BarLoader";
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";

import { STORE_KEYS } from '@/stores';
import { Container } from "./styles";
import loaderGearSmall from "./assets/loader-gear-small.svg";
import loaderGearLarge from "./assets/loader-gear-large.svg";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class AwesomeComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    render() {
        const { isLoading } = this.props;
        return isLoading && (
            <Container>
                {/*
                <PulseLoader
                    css={override}
                    size={15}
                    color={"#ffffff"}
                    loading={this.state.loading}
                />
                */}
                <div id="loader">
                    <img className="gear gear-small" src={loaderGearSmall}/>
                    <img className="gear gear-large" src={loaderGearLarge}/>
                </div>
            </Container>
        );
    }
}

export default compose(
    inject(STORE_KEYS.VIEWMODESTORE),
    observer,
    withProps(
        ({
             [STORE_KEYS.VIEWMODESTORE]: {
                 isLoading,
             },
         }) => ({
            isLoading,
        })
    )
)(AwesomeComponent);