import React, { Fragment } from "react";
import styled, { css } from "styled-components";

export const CommonButton = styled.button`
  min-width: 85px;
  border-radius: 5px;
  background: #415c85;
  color: #e7eaef;
  outline: none;
  cursor: pointer;
  font-family: Circe-Bold;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  border: none;
  padding: 8px 20px;
  
  &:hover {
    background: #2f466a;
  }
  
  ${props => props.fullWidth && css`
    width: 100%;
  `}
`;
export const CommonLink = styled.a`
  min-width: 85px;
  border-radius: 5px;
  background: #415c85;
  color: #e7eaef;
  outline: none;
  cursor: pointer;
  font-family: Circe-Bold;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  border: none;
  padding: 8px 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &:hover {
    background: #2f466a;
  }
  
  ${props => props.fullWidth && css`
    width: 100%;
  `}
`;

const StyledButton = styled.button`
  max-width: ${props => props.maxWidth ? props.maxWidth : 170}px;
  width: 100%;
  min-height: 73px;
  border-radius: 20px;
  background: #7632d2;
  outline: none;
  cursor: pointer;
  font-family: Circe-Bold;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: unset;
    width: 100%;
    min-height: 70px;
  }
`

const Button = ({label, maxWidth, onClick}) => {
  return (
    <StyledButton
      maxWidth={maxWidth}
      onClick={onClick}
    >
      {label}
    </StyledButton>
  )
};

export default Button;
