import styled, {css} from "styled-components";
import {Link} from "react-router-dom";

export const Wrapper = styled.div`
  width: 100%;
  padding-top: 20px;
  
  .row {
    margin-left: -5px !important;
    margin-right: -5px !important;
  }
  
  .item-wrapper {
    position: relative;
    flex: 0 0 20%;
    max-width: 20%;
    padding-right: 5px;
    padding-left: 5px;
    margin-bottom: 10px;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.xl}) {
    .item-wrapper {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    
    .item-wrapper.right {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding-top: 0;
    
    .item-wrapper {
      flex: 0 0 100%;
      max-width: 100%;
    }
    
    .item-wrapper.right {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  
  .carousel-item {
    height: 200px;
  }
  
  .carousel-indicators {
    margin-bottom: 0;
  }
`;

export const DashboardItem = styled(Link)`
  width: 100%;
  min-height: 120px;
  border-radius: 5px;
  background-color: ${props => props.bgColor || '#f7fdfd'};
  padding: 15px 20px;
  height: 100%;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  
  ${props => props.clickable && `
    cursor: pointer;
  `}
  
  .title {
    color: ${props => props.titleColor || '#425d86'};
    margin-bottom: 20px;
    
    img {
      margin-right: 10px;
    }
    
    ${props => props.bold && css`
      font-family: Circe-Bold;
      font-size: 16px;
    `}
  }
  
  .content {
    display: flex;
    justify-content: center;
    color: ${props => props.contentColor || '#181e26'};
    
    p.clickable {
      cursor: pointer;
      
      i {
        margin-left: 7px;
      }
    }
    .bold {
      font-size: 16px;
      color: #181e26;
      display: flex;
      align-items: flex-start;
      
      span {
        font-size: 10px;
        color: #797f84;
        margin-left: 5px;
      }
    }
    
    .content-item {
      p {
        color: #797f84;
        margin-bottom: 10px;
      }
    }
  }
  
  &:hover {
    ${props => props.isArticle && `
        background-image: radial-gradient(circle at 50% 40%, #7632d2, #5b19b4 85%);
        .title {
          color: #ffffff;
        }
        .content {
          color: #ffffff;
        }
        svg {
          fill: rgba(255, 255, 255, 0.8);
        }
    `}
    
    ${props => props.isGuide && `
        background-image: radial-gradient(circle at 50% 40%, #14b5ea, #109ecc 85%);
        .title {
          color: #ffffff;
        }
        .content {
          color: #ffffff;
        }
        svg {
          fill: rgba(255, 255, 255, 0.8);
        }
    `}
  }
`;

export const NoAccountPanel = styled.div`
  border-radius: 5px;
  min-height: 120px;
  position: relative;
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 10px;
  background: #f2f3f4;
  
  .content {
    max-width: 275px;
    
    a {
      color: #415c85;
      font-family: Circe-Bold;
      border-bottom: 2px solid #8d9bb0;
    }
  }
  
  @media (max-width: ${props => props.theme.breakpoints.xl}) {
    flex: unset;
    width: 100% !important;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
    min-height: 200px;
    justify-content: center;
    
    .content {
      margin-top: 30px;
    }
  }
`;

export const XeroConnectButton = styled.a`
  border-radius: 5px;
  background: #337da1;
  color: white;
  font-family: Circe-Regular;
  font-size: 18px;
  padding: 5px 10px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  
  &:hover {
    background: #32789c;
  }
  
  img {
    width: 36px;
    min-width: 36px;
    height: 36px;
    margin-right: 20px;
  }
`;
