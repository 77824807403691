import React, {useState} from "react";
import styled, {css} from "styled-components";
import CheckBox from "@/components/CheckBox";
import {TaskCompletedIcon, TaskOpenIcon} from "../../../common/svgIcon";
import CheckAction from "../../../CheckAction";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid #e5dfde;
  background: ${props => props.color || 'none'};
  
  &:last-child {
    border-bottom: none;
  }
`;

const Action = styled.div`
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.bgColor || '#fcfefe'};
  cursor: pointer;
  
  &:hover {
    background-color: ${props => props.hoverColor || '#f0fafb'};
  }
`;

const Content = styled.a`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  position: relative;
  height: 100%;
  padding: 15px 10px 15px 10px;
  cursor: pointer;
  
  &:hover {
    background-color: ${props => props.hoverColor || '#f0fafb'};
    
    .arrow-icon {
      border: none;
      background: #5e7599;
      
      .fa-long-arrow-alt-right {
        color: #f1f3f6;
      }
    }
    
    ${props => props.isExpired && css`
      .arrow-icon {
        border: none;
        background: #fff;
        
        .fa-long-arrow-alt-right {
          color: #2b3138;
        }
      }
    `}
  }
`;

const Heading = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  
  img {
    width: 20px;
    height: 20px;
    min-width: 20px;
    margin-right: 5px;
  }
  
  p {
    font-family: Circe-Bold;
    font-size: 13px;
    color: ${props => props.color || '#181e26'};
    margin-right: 10px;
  }
  
`;

const SubTitle = styled.span`
  color: ${props => props.color || '#a4afc0'};
  font-size: 13px;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  
  img {
    width: 20px;
    height: 20px;
    min-width: 20px;
    margin-left: 10px;
  }
`;

const DateView = styled.p`
  color: ${props => props.color || '#919498'};
  white-space: nowrap;
  font-size: 12px;
`;

const PTView = styled.p`
  margin-left: 10px;
  display: flex;
  align-items: center;
  flex-wrap: no-wrap;
  white-space: nowrap;
  color: #d88f32;
`;

const PTIcon = styled.span`
  width: 7px;
  height: 7px;
  background-color: #eea950;
  border-radius: 10px;
  margin-right: 5px;
  margin-bottom: 2px;
`;

const ArrowIcon = styled.span`
  width: 15px;
  min-width: 15px;
  height: 15px;
  border-radius: 2px;
  border: 1px solid #f3eef0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
  
  .fa-long-arrow-alt-right {
    transform: rotate(-45deg);
    font-size: 12px;
    color: #97a2b6;
  }
  
  &:hover {
    border: none;
    background: #5e7599;
    
    .fa-long-arrow-alt-right {
      color: #f1f3f6;
    }
  }
  
  ${props => props.expired === true && css`
    border: 1px solid #d57c6f;
    background: #d37466;
    
    .fa-long-arrow-alt-right {
      color: #fbf3f2;
    }
  `}
`;


const Desc = styled.div`
  display: flex;
  font-family: Circe-Regular;
  padding-right: 10px;
  color: ${props => props.color || '#181e26'};
  
  .decorationText {
    text-decoration: line-through;
  }
`;


const TaskItem = ({data, state, isMyTask, markTaskAsComplete}) => {
  let backgroundColor, actionBgColor, hoverColor, dateColor, descColor, titleColor='#181e26', subTitleColor='#8d9bb0';

  switch (state) {
    case 'EXPIRED':
      backgroundColor = '#d37466';
      descColor       = '#fff';
      dateColor       = '#fff';
      titleColor      = '#fff';
      subTitleColor   = '#e6c4c0';
      actionBgColor   = '#d37466';
      hoverColor      = '#c37167';
      break;
    case 'TODAY':
      backgroundColor = '#fef6f4';
      dateColor       = '#965f56';
      break;
    case 'COMPLETED':
      dateColor       = '#a2a5a8';
      descColor       = '#8b8e93';
      actionBgColor   = '#fff';
      titleColor      = '#a3a5a9';
      subTitleColor   = '#d2d7e0';
      break;
  }

  const onActionProcess = (mode) => {
    markTaskAsComplete(data.objectId, mode)
  };

  const getTaskItemContent = () => {
    return (
      <>
        <Heading>
          <Title color={titleColor}>
            {data.relatedLogo && (
              <img src={data.relatedLogo} alt="Task Image" />
            )}
            <p>
              {data.name}
            </p>
            <SubTitle color={subTitleColor}>
              {data.property}
            </SubTitle>
          </Title>
          <Right>
            <DateView color={dateColor}>{data.date}</DateView>
            {
              isMyTask ?
                state !== 'EXPIRED' && data.price !== null &&
                <PTView><PTIcon/>{data.price}</PTView>
                :
                <img src={data.icon} alt="Team Member Image" />
            }
            <ArrowIcon className={'arrow-icon'} expired={state === 'EXPIRED'}>
              <i className="fa fa-long-arrow-alt-right" />
            </ArrowIcon>
          </Right>
        </Heading>
        <Desc color={descColor}><p className={state === 'COMPLETED' ? 'decorationText' : ''}>{data.desc}</p></Desc>
      </>
    )
  };

  return (
    <Wrapper color={backgroundColor}>
      {
        isMyTask && (
          <CheckAction
            bgColor={actionBgColor}
            hoverColor={hoverColor}
            value={state === 'COMPLETED'}
            isExpired={state === 'EXPIRED'}
            onActionProcess={(mode) => onActionProcess(mode)}
          />
        )
      }
      {data.link ? (
        <Content hoverColor={hoverColor} isExpired={state === 'EXPIRED'} href={data.link || '#'} target="_blank">
          {getTaskItemContent()}
        </Content>
      ) : (
        <Content hoverColor={hoverColor} isExpired={state === 'EXPIRED'}>
          {getTaskItemContent()}
        </Content>
      )}
    </Wrapper>
  )
}

export default TaskItem;
