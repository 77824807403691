import styled from "styled-components";
import React from "react";
import NotificationItem from "@/components/dashboard-header/NotificationItem";

const NotificationList = ({data, isShowAll}) => {

    const Wrapper = styled.div`
      margin-right: 5px;
      padding: 5px;
      overflow: auto;
      box-sizing: border-box;
      max-height: 500px;
      
      &::-webkit-scrollbar-thumb {
        background-color: #f5f7f9;
        border-radius: 2.5px;
        width: 6px;
      }
      
      &::-webkit-scrollbar {
        width: 6px;
      }
      
      &::-webkit-scrollbar-track {
        display: none;
      }
    `;
    return (
        <Wrapper>
            {
                !isShowAll ?
                    data.filter((item, index) => index < 2).map((item, index) => (
                        <NotificationItem item={item} key={index} />
                    ))
                    :
                    data.map((item, index) => (
                        <NotificationItem item={item} key={index} />
                    ))
            }
        </Wrapper>
    );
};

export default NotificationList;
