import styled, {keyframes} from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 55px;
  background-color: #405c85;
  padding: 0 30px;
  color: white;
  position: fixed;
  width: 100%;
  z-index: 100;
  
  @media (max-width: 1300px) and (min-width: 992px) {
    height: 40px;
  }
  
   @media (max-width: ${props => props.theme.breakpoints.lg}) {
    height: 50px;
    padding: 0 20px;
  }
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  
  .main_logo > svg {
    width: 125px !important;
    margin-right: 20px;
    
    @media (max-width: 1300px) {
        width: 50px !important;
    }
  }
  
   @media (max-width: ${props => props.theme.breakpoints.lg}) {
    width: 50%;
  }
  
   @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex: 1;
  }
`;

export const Logo = styled.img`
  margin-right: 30px;
  
  @media (max-width: 1300px) {
    display: none;
  }
  
  @media (max-width: 992px) {
    display: none;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
   display: block;
   margin-right: 0;
  }
`;

export const SmallLogo = styled.img`
  margin-right: 26px;
  display: none;
  
  @media (max-width: 1300px) {
    display: block;
  }
  
 @media (max-width: ${props => props.theme.breakpoints.sm}) {
   display: none;
  }
`;

export const Menu = styled.ul`
  display: flex;
  list-style-type: none;
  align-items: center;
  padding: 0;
  margin: 0;
  
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    justify-content: space-around;
    flex: 1;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: none;
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  justify-self: flex-end;
  
   @media (max-width: ${props => props.theme.breakpoints.md}) {
   
  }
`

export const ConnectProduct = styled.div`
  display: flex;
  align-items: center;
  background-color: #546c91;
  border-radius: 5px;
  padding: 3px 6px;
  font-size: 12px;
  font-family: Circe-Regular;
  
  img {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    margin-right: 12px;
  }
`;

export const Notice = styled.div`
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  margin-left: 27px;
  position: relative;
  
  .live_alert {
      width: 6px;
      height: 6px;
      background-color: #f67f5c;
      border-radius: 50%;
      position: absolute;
      top: 0;
      right: 0;
  }
  
  @media (max-width: 1100px) and (min-width: 992px) {
    margin-left: 10px;
  }
`;

export const pulse = keyframes`
  0% {
    transform: scale(0);
    opacity: 0.0;
  }
  25% {
    transform: scale(0);
    opacity: 0.1;
  }
  50% {
    transform: scale(0.1);
    opacity: 0.3;
  }
  75% {
    transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 0.0;
  }
`

export const Dot = styled.div`
  position: relative;
  top: -25px;
  
  .dot {
    border: 4px solid #f37f5b;
    background: transparent;
    border-radius: 60px;
    height: 30px;
    width: 30px;
    animation: ${pulse} 3s ease-out;
    animation-iteration-count: infinite;
    position: absolute;
    top: -12px;
    left: 0;
    z-index: 1;
    opacity: 0;
    color: red;
  }
  
  .pulse {
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: #f37f5b;
    z-index: 10;
    position: absolute;
    right: 0;
  }
`

export const PtsInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: #d78c2c;
  padding: 0 10px;
  margin-left: 21px;
  position: relative;
  cursor: pointer;
  
  p {
    font-size: 14px;
    font-family: Circe-Bold;
    display: flex;
    align-items: center;
    line-height: 14px;
    padding: 6px 0 4px;
  
    span {
      font-size: 8px;
      letter-spacing: 1px;
      margin-left: 3px;
    }
    
    ${props => props.animated && `
      animation: heartbeat 1s 1;    
    `}
  
    ${props => !props.animated && `
        animation: heartbeat02 1s 1;    
    `}
  }
  
  @media (max-width: 1100px) and (min-width: 992px) {
    margin-left: 10px;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-left: 0;
  }
`;

export const MobileMenuIcon = styled.div`
  margin-left: 25px;
  display: none;
  cursor: pointer;
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: block;
  }
`;
