import React, { Component } from 'react';
import ReactPlayer from "react-player";

class VideoPanel extends Component {
  render() {
    const { link } = this.props;
    return (
      <ReactPlayer
        url={link}
        controls
        width='100%'
        height='100%'
      />
    )
  }
}

export default VideoPanel;
