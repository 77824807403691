import styled from "styled-components";
import {TextField as MuiTextField} from "@material-ui/core";
import {spacing} from "@material-ui/system";
import {Button, WhiteButton} from "../styles";

export const TextFieldSpacing = styled(MuiTextField)(spacing);

export const TextField = styled(TextFieldSpacing)`
  width: 100%;
  font-family: Circe-Regular !important;
  
  .MuiInputBase-input {
    font-family: Circe-Regular;
    font-size: 18px;
    color: #181e26;
  }
  
  .MuiOutlinedInput-root {
    border-radius: 0px;
  }
  
  // .MuiOutlinedInput-input {
  //   padding: 13px 14px !important;
  // }
  
  .MuiInputLabel-outlined {
    font-family: Circe-Regular;
    color: #576f93;
    font-size: 16px;
  }
  
  .MuiFormHelperText-root {
    font-family: Circe-Regular;
  }
  
  .MuiOutlinedInput-notchedOutline {
    border-color: #576f93;
  }
  
  .PrivateNotchedOutline-legendLabelled-3 {
    font-size: 14px;
  }
`;

export const SaveBtn = styled(Button)`
  padding: 15px 30px;
  margin-left: 30px;
`;

export const CancelBtn = styled(WhiteButton)`
  padding: 15px 30px;
  
`;
