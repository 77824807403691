import React, {Component} from "react";
import {Redirect} from "react-router-dom";
import {compose, withProps} from "recompose";
import {inject, observer} from "mobx-react";

//components
import SignUp1 from "./signup1";
import SignUp2 from "./signup2";
import SignUp3 from "./signup3";
import SignUp4 from "./signup4";
import {STORE_KEYS} from '@/stores';

class SignUpPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: 0,
            email: '',
            password: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            companyName: '',
            businessType: '',
            region: '',
            referral: '',
            onboardStatus: '',
            monthlyTransactions: '',
            salesTaxFreq: '',
            fiscalYearEnd: '',
            socialMode: '',
        }
    }

    componentDidMount() {
        try {
            const region = this.props.match.params.region;
            this.setState({
                referral: (region || '').split('=')[1],
            })
        } catch (e) {
        }
    }

    setMode1 = (md, email, password) => {
        this.setState({
            mode: md, email, password,
        });
    };

    setMode2 = (md, firstName, lastName, phoneNumber) => {
        this.setState({
            mode: md, firstName, lastName, phoneNumber,
        });
    };

    setMode3 = (md, companyName, businessType, region) => {
        this.setState({
            mode: md, companyName, businessType, region,
        });
    };

    setMode4 = (md, onboardStatus, monthlyTransactions, salesTaxFreq, fiscalYearEnd) => {
        // validation
        let {
            firstName, lastName, phoneNumber, companyName, email, password, businessType,
            region, socialMode
        } = this.state;

        // api call
        this.props.registerWithMail(
            `${firstName} ${lastName}`, email, password, phoneNumber, companyName, businessType,
            region, onboardStatus, monthlyTransactions, salesTaxFreq, fiscalYearEnd, socialMode
        )
            .then(() => {
                this.setState({
                    redirect: true,
                });
            })
            .catch(() => {
                this.props.showSnackMsg('Sorry, your registration is failed.');
            });
    };

    childContent = () => {
        const {mode, referral} = this.state;
        const {checkExistEmail, showSnackMsg, regions} = this.props;
        switch (mode) {
            case 0:
                return <SignUp1 setMode={this.setMode1} checkExistEmail={checkExistEmail} showSnackMsg={showSnackMsg}/>;
            case 1:
                return <SignUp2 setMode={this.setMode2} showSnackMsg={showSnackMsg}/>;
            case 2:
                return <SignUp3 setMode={this.setMode3} showSnackMsg={showSnackMsg} regions={regions}
                                referral={referral}/>;
            case 3:
                return <SignUp4 setMode={this.setMode4} showSnackMsg={showSnackMsg}/>;
        }
    };

    renderRedirect = () => {
        const { redirect } = this.state;
        if (redirect) {
            return <Redirect to='/home'/>
        }
    };

    render() {
        return (
            <>
                {this.renderRedirect()}
                {this.childContent()}
            </>
        )
    }
}

export default compose(
    inject(STORE_KEYS.AUTHSTORE),
    observer,
    withProps(
        ({
             [STORE_KEYS.AUTHSTORE]: {registerWithMail, setPendingEmail, showSnackMsg, checkExistEmail, regions},
         }) => ({
            registerWithMail,
            setPendingEmail,
            showSnackMsg,
            checkExistEmail,
            regions,
        })
    )
)(SignUpPage);
