import React from 'react';
import { inject, observer } from 'mobx-react';
import { STORE_KEYS } from '@/stores';
import SimpleSnackbar from './Snackbar';

const SnackbarPortal = inject(STORE_KEYS.SNACKBARSTORE)(
  observer(({ [STORE_KEYS.SNACKBARSTORE]: { SnackBarProps, open, onClose, isRight } }) => {
    return (
      <SimpleSnackbar {...SnackBarProps} open={open} onClose={onClose} isRight={isRight} />
    );
  })
);

export default SnackbarPortal;
