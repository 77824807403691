import styled, {css} from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const CardHeader = styled.div`
  width: 100%;
  padding-bottom: 15px
  
  p {
    color: #8d9bb0;
  }
`;

export const CardBody = styled.div`
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  flex: 1;
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 20px #f5f5f5;
  
  ${props => props.bgImage && css`
    background: url(${props => props.bgImage});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    box-shadow: unset;
  `}
  
  
  .content {
    width: 100%;
    height: 100%;
    background: rgba(250,250,250,0.9);
    padding: 40px 25px;
    
    p {
      font-size: 18px;
      color: #51565c;
      text-align: center;
    }
    
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      padding: 20px 30px;
    }
  }
  
  .body {
    display: flex;
    flex: 1;
    padding: 15px;
    align-items: center;
    justify-content: space-between;
    
    img {
      width: 50px;
      height: 50px;
      min-width: 50px;
      margin-right: 20px;
    }
    
    h3 {
      font-size: 20px;
      color: #415c85;
      white-space: pre-wrap;
    }
  }
  
  .footer {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border-top: 1px solid #ebeef2;
    background: #fbfefe;
  }
  
  .profile_img {
    border-radius: 50% 50%;
  }
`;

export const SmallButton = styled.button`
  padding: 9px 10px 7px;
  color: #7f8489;
  font-family: Circe-Bold;
  font-size: 12px;
  background: none;
  border-radius: 3px;
  border: 1px solid #e7e8e9;
`;

export const CalendarBtn = styled.a`
  padding: 2px 10px 5px;
  border-radius: 5px;
  border: 1px solid #e7e8e9;
  cursor: pointer;
  display: none;
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: block;
  }
`;

