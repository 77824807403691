import React from "react";
import styled from "styled-components";
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";
import i18n from '../../../i18n';
import { STORE_KEYS } from '@/stores';

import {CommonButton} from "@/components/common/button";
import {Link} from "react-router-dom";

const NoTaskPanel = ({ setSolutionsMode }) => {
  const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fafbfb;
    border-radius: 7px;
    padding: 30px;
    
    p {
      font-size: 16px;
      color: #9fa2a5;
      max-width: 270px;
      text-align: center;
    }
  `;

  const Title = styled.div`
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    img {
      width: 25px;
      height: 25px;
      min-width: 25px;
      margin-right: 10px;
    }
    
    h3 {
      font-size: 20px;
      color: #72767b;
    }
  `;

  const Button = styled(CommonButton)`
    background: #8cc178;
    color: #fff;
    font-size: 14px;
    
    &:hover {
      background: #83b570;
    }
  `;

  const onExploreService = () => {
      setSolutionsMode('solution_service');
  }

  return (
    <Wrapper>
      <img src={'/images/icons/Icon_Empty_Check.svg'} alt="Empty List" className="mb-3"/>
      <Title>
        <img src={'/images/Solutions/Service_Accounting.svg'} alt={'Image'} />
        <h3>{i18n.t('dashboard.noTasks')}</h3>
      </Title>
      <p className="mb-4">{i18n.t('dashboard.addSomeServices')}</p>
      <Link to="/solution" onClick={() => onExploreService() }>
        <Button className="upper-case">{i18n.t('dashboard.explore').toUpperCase()}</Button>
      </Link>
    </Wrapper>
  )

};

export default compose(
    inject(
        STORE_KEYS.VIEWMODESTORE
    ),
    observer,
    withProps(
        ({
             [STORE_KEYS.VIEWMODESTORE]: { setSolutionsMode, solutionsMode },
         }) => ({
            setSolutionsMode,
            solutionsMode,
        })
    )
)(NoTaskPanel);
