import styled, {css} from "styled-components";

export const ProgressPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
 
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`;

export const ProgressView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 140px;
  background-color: #eaf9fa;
  border-radius: 6px;
  
  span {
    font-family: Circe-Regular;
    font-size: 10px;
    color: #6b757b;
    margin-bottom: 8px;
  }
`

export const ScoreView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 140px;
  margin-top: 10px;
  background-color: #eaf9fa;
  border-radius: 6px;
  position: relative;
  
  span {
    font-family: Circe-Regular;
    font-size: 10px;
    color: #6b757b;
    margin-bottom: 8px;
  }
  
  .title {
    display: flex;
  }
`

export const SubTitle = styled.h2`
  margin-bottom: 15px;
  font-family: Circe-Bold;
  font-size: 20px;
  width: 100%;
  color: #75787d;
  
  span {
    font-size: 14px;
    color: #8d9bb0;
  }
  
  ${props => props.center && css`
    margin-left: 40px;
  `}
`;
