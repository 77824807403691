import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import i18n from '../../i18n';
import './CardSectionStyles.css';

class CheckoutForm extends React.Component {
  handleSubmit = async (event) => {
    event.preventDefault();
    const { stripe, elements, onSubmit } = this.props;
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });
    if (!error) {
      onSubmit(paymentMethod);
    }
  };

  render() {
    const {stripe} = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <CardElement />
        <button type="submit" disabled={!stripe} className="btn_submit">
          {i18n.t('dashboard.add')}
        </button>
      </form>
    );
  }
}

const InjectedCheckoutForm = ({ onSubmit }) => (
  <ElementsConsumer>
    {({stripe, elements}) => (
      <CheckoutForm stripe={stripe} elements={elements} onSubmit={onSubmit} />
    )}
  </ElementsConsumer>
);

const stripePromise = loadStripe('pk_live_bS5KryU0LTySjUkHsIrVdRTe');

const Checkout = ({ onSubmit }) => (
  <Elements stripe={stripePromise}>
    <InjectedCheckoutForm onSubmit={onSubmit} />
  </Elements>
);

export default Checkout;
