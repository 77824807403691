import styled, {css} from "styled-components";

export const Container = styled.div`
  height: 100%;
  background: white;
  width: 100%;
`;


export const SubHeader = styled.div`
  height: 75px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
    justify-content: flex-start;
    height: 100px;
  }
`;

export const Title = styled.div`
  padding-left: 30px;
  font-size: 24px;
  font-family: Circe-Bold;
  color: #171d26;
  width: 20%;
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
    margin-top: 20px;
  }
`;

export const Tabs = styled.div`
  display: flex;
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`;

export const TabItem = styled.div`
  font-size: 20px;
  font-family: Circe-Bold;
  color: #171d26;
  margin-left: 40px;
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  
  color: ${props => props.active ? '#171d26' : '#647a9d'};
  border-bottom: ${props => props.active ? '3px solid #181e26' : '3px solid #fff'};
  
  span {
    font-size: 14px;
    color: #9da9bb;
    padding-left: 5px;
  }
`

export const Content = styled.div`
  display: flex;
  display: flex;
  flex: 1;
  width: 100%;
  
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    flex-direction: column;
    align-items: center;
  }
`

export const Left = styled.div`
  flex: 0 0 20%;
  max-width: 20%;
  height: calc(100vh - 176px);
  min-width: 250px;
  margin-right: 4px;
  box-sizing: border-box;
  overflow: auto;
  
  &::-webkit-scrollbar-thumb {
    background-color: #e5e5e5;
    border-radius: 2.5px;
    width: 5px;
  }
  
  &::-webkit-scrollbar {
    width: 5px;
  }
  
  &::-webkit-scrollbar-track {
    display: none;
  }
  
  .wrapper {
    padding: 1.5rem 2rem;
    
    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      margin-bottom: 30px;
      display: flex;
      justify-content: space-between;
    }
    
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      flex-direction: column;
    }
  }
  
  @media (max-width: 1300px) {
    height: calc(100vh - 166px);
  }
  
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    height: unset;
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
  }
`;

export const Center = styled.div`
  flex: 1;
  border-right: 1px solid #e5e5e5;
  
  .wrapper {
    padding: 20px 15px 20px 25px;
    overflow: auto;
    height: calc(100vh - 176px);
    margin-right: 4px;
    padding: 1.5rem 2rem;
    
    &::-webkit-scrollbar-thumb {
      background-color: #e5e5e5;
      border-radius: 2.5px;
      width: 5px;
    }
    
    &::-webkit-scrollbar {
      width: 5px;
      padding: 5px;
    }
    
    &::-webkit-scrollbar-track {
      display: none;
    }
   
    @media (max-width: 1300px) {
      height: calc(100vh - 166px);
    }
    
    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      height: unset;
      flex: 0 0 100%;
      max-width: 100%;
      margin: 0;
      border: none;
    }
  }
  
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    border: none;
    width: 100%;
  }
`;

export const ProgressPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
 
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`;

export const ProgressView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 140px;
  background-color: #eaf9fa;
  border-radius: 6px;
  
  span {
    font-family: Circe-Regular;
    font-size: 10px;
    color: #6b757b;
    margin-bottom: 8px;
  }
`

export const ScoreView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 140px;
  margin-top: 10px;
  background-color: #eaf9fa;
  border-radius: 6px;
  
  span {
    font-family: Circe-Regular;
    font-size: 10px;
    color: #6b757b;
    margin-bottom: 8px;
  }
  
  .title {
    display: flex;
  }
`

export const NotificationPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #e7eaee;
  margin-bottom: 40px;
  
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    width: 48%;
    margin-bottom: 0;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
    margin-bottom: 30px;
  }
  
  .header {
    border-bottom: 1px solid #e7eaee;
    padding: 18px 0 20px 0;
    text-align: center;
    
    h3 {
      font-family: Circe-Bold;
      font-size: 16px;
      color: #22272f;
    }
  }
  
  .body {
    padding: 15px;
  }
  
  .footer {
    border-top: 1px solid #e7eaee;
    padding: 10px 0 15px 0;
    display: flex;
    justify-content: center;
  }
`;

export const NotificationItem = styled.div`
  width: 100%;
  border-radius: 5px;
  color: #181e26;
  margin-bottom: 23px;
  
  &:last-child {
    margin-bottom: 0;
  }
  
  .title {
    display: flex;
    align-items: center;
    
    p {
      display: inline-block;
      font-family: Circe-Bold;
      font-size: 16px;
      color: #415c85;
    }
    
    .fa-circle {
      color: #8cc178;
      font-size: 8px;
      margin-left: 5px;
    }
  }
  
  .content-text {
    font-size: 14px;
    line-height: 1.5;
  }
  
  .date {
    font-size: 14px;
    color: #93a0b4;
    line-height: 2;
  }
`;

export const ListTitle = styled.div`
  color: #181e26;
  font-family: Circe-Bold;
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: ${props => props.mt || 0}px;
  
  span {
    color: #8d9bb0;
    font-size: 15px;
    margin-left: 5px;
  }
  
  img {
    width: 25px;
    height: 25px;
    margin-right: 10px
  }
`;

export const ShowMoreBtn = styled.button`
  padding: 8px 10px;
  color: #74787d;
  font-family: Circe-Bold;
  font-size: 12px;
  background: none;
  border-radius: 3px;
  border: 1px solid #e7e8e9;
`;

export const SubTitle = styled.h2`
  margin-bottom: 15px;
  font-family: Circe-Bold;
  font-size: 20px;
  width: 100%;
  color: #75787d;
  
  span {
    font-size: 14px;
    color: #8d9bb0;
  }
  
  ${props => props.center && css`
    margin-left: 40px;
  `}
`;
