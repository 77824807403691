import React from "react";

//components
import ProductCard from "../../../components/products/product-card";
import SpecialProductCard from "../../../components/dashboard/solutions/SpecialProductCard";
import SpecialServiceCard from "../../../components/dashboard/solutions/SpecialServiceCard";
import ServiceCard from "../../../components/services/service-card";
import TemplateCard from "../../../components/templates/template-card";
import AddProductDialog from "../../../components/dialogs/AddProductDialog";
import AddServiceDialog from "../../../components/dialogs/AddServiceDialog";
import AddTemplateDialog from "../../../components/dialogs/AddTemplateDialog";
import i18n from '../../../i18n';
// styles
import { Content, Left, Center, SubPanel } from "../../styles";
import { LeftCard, CardSlider } from "../styles";
import { CommonButton } from "../../../components/common/button";

const All = (props) => {
  const {
    cloudSpecialProducts,
    cloudProducts,
    cloudSpecialServices,
    cloudServices,
    xeroTemplates,
  } = props;
  const [showAllProducts, setShowAllProducts] = React.useState(false);
  const [showAllServices, setShowAllServices] = React.useState(false);

  const [openProductModal, setOpenProductModal] = React.useState(false);
  const [activeProductItem, setActiveProductItem] = React.useState(null);

  const [openServiceModal, setOpenServiceModal] = React.useState(false);
  const [activeServiceItem, setActiveServiceItem] = React.useState(null);

  const [openTemplateModal, setOpenTemplateModal] = React.useState(false);
  const [activeTemplateItem, setActiveTemplateItem] = React.useState(null);

  const toggleOpenProductModal = (isOpen, item) => {
    setOpenProductModal(isOpen);
    setActiveProductItem(item);
  };

  const toggleOpenServiceModal = (isOpen, item) => {
    setOpenServiceModal(isOpen);
    setActiveServiceItem(item);
  };

  const toggleOpenTemplateModal = (isOpen, item) => {
    setOpenTemplateModal(isOpen);
    setActiveTemplateItem(item);
  };

  return (
    <Content>
      <Left isMobile={true}>
        <div className={'wrapper'}>
          <LeftCard>
            <div className={'brand'}>
              <img src="/images/dashboard/solutions/img_services_002.png" alt=""/>
            </div>
            <div className={'description'}>
              <p>{i18n.t('dashboard.buildSolution')}</p>
            </div>
          </LeftCard>
        </div>
      </Left>
      <Center smPadding>
        <div className={'wrapper'}>
          <AddProductDialog
            open={openProductModal}
            item={activeProductItem}
            handleClose={() => setOpenProductModal(false)}
          />
          <AddServiceDialog
            open={openServiceModal}
            item={activeServiceItem}
            handleClose={() => setOpenServiceModal(false)}
          />
          <AddTemplateDialog
              open={openTemplateModal}
              item={activeTemplateItem}
              handleClose={() => setOpenTemplateModal(false)}
          />
          <div className="row">
            <div className="col-lg-12">
              <SubPanel>
                <div className="title">{i18n.t('dashboard.products')}</div>
                <div className="row">
                  <div className="col-xl-6 col-sm-12 mb-4">
                    {
                      cloudSpecialProducts.length > 0 && (
                        <SpecialProductCard data={cloudSpecialProducts[0]} toggleOpenModal={toggleOpenProductModal} />
                      )
                    }
                  </div>
                  <div className="col-xl-3 col-sm-6 mb-4 mobile-hidden">
                    {
                      cloudProducts.length > 0 && (
                        <ProductCard data={cloudProducts[0]} toggleOpenModal={toggleOpenProductModal} />
                      )
                    }
                  </div>
                  <div className="col-xl-3 col-sm-6 mb-4 mobile-hidden">
                    {
                      cloudProducts.length > 1 && (
                        <ProductCard data={cloudProducts[1]} toggleOpenModal={toggleOpenProductModal} />
                      )
                    }
                  </div>
                </div>
                <CardSlider>
                  {
                    !showAllProducts ?
                        cloudProducts.filter((item, index) => index < 2).map((item, index) => (
                            <ProductCard data={item} key={index} />
                        ))
                        :
                        cloudProducts.map((item, index) => (
                            <ProductCard data={item} key={index} />
                        ))
                  }
                </CardSlider>
                {
                  !showAllProducts ?
                    <div className="mb-4">
                      <CommonButton onClick={() => setShowAllProducts(true)}>{i18n.t('dashboard.exploreAllProducts')}</CommonButton>
                      {
                        (cloudProducts || []).filter((item, index) => index > 1).map(item => (
                          <img src={item.Product_Logo_url__c} className="ml-3 logo_item mobile-hidden" key={item.id}/>
                        ))
                      }
                    </div>
                  :
                    <div className="row mobile-hidden">
                      {
                        (cloudProducts || []).filter((item, index) => index > 1).map(item => {
                          return (
                            <div className="col-xl-3 col-lg-6 col-sm-6 col-xs-12 mb-4" key={item.id}>
                              <ProductCard data={item} toggleOpenModal={toggleOpenProductModal}/>
                            </div>
                          )
                        })
                      }
                    </div>
                }
              </SubPanel>
            </div>
            <div className="col-lg-12">
              <SubPanel>
                <div className="title">{i18n.t('dashboard.services')}</div>
                <div className="row">
                  <div className="col-xl-6 col-sm-12 mb-4">
                    {
                      cloudSpecialServices.length > 0 && (
                        <SpecialServiceCard data={cloudSpecialServices[0]} toggleOpenModal={toggleOpenServiceModal} />
                      )
                    }
                  </div>
                  <div className="col-xl-3 col-sm-6 mb-4 mobile-hidden">
                    {
                      cloudServices.length > 0 && (
                        <ServiceCard data={cloudServices[0]} toggleOpenModal={toggleOpenServiceModal} />
                      )
                    }
                  </div>
                  <div className="col-xl-3 col-sm-6 mb-4 mobile-hidden">
                    {
                      cloudServices.length > 1 && (
                        <ServiceCard data={cloudServices[1]} toggleOpenModal={toggleOpenServiceModal} />
                      )
                    }
                  </div>
                </div>
                {
                  !showAllServices ?
                    <div className="mb-4">
                      <CommonButton onClick={() => setShowAllServices(true)}>{i18n.t('dashboard.exploreAllServices')}</CommonButton>
                      {
                        (cloudServices || []).filter((item, index) => index > 1).map(item => (
                          <img src={item.Service_Logo_url__c} className="ml-3 logo_item" key={item.id}/>
                        ))
                      }
                    </div>
                    :
                    <div className="row mobile-hidden">
                      {
                        (cloudServices || []).filter((item, index) => index > 1).map(item => {
                          return (
                            <div className="col-xl-3 col-lg-6 col-sm-6 col-xs-12 mb-4" key={item.id}>
                              <ServiceCard data={item} toggleOpenModal={toggleOpenServiceModal} />
                            </div>
                          )
                        })
                      }
                    </div>
                }
              </SubPanel>
              <SubPanel>
                <div className="title">{i18n.t('dashboard.templates')}</div>
                <div className="row">
                  {
                    (xeroTemplates || []).map(item => {
                      return (
                        <div className="col-xl-3 col-lg-6 col-sm-6 col-xs-12 mb-4" key={item.id}>
                          <TemplateCard data={item} toggleOpenModal={toggleOpenTemplateModal} />
                        </div>
                      )
                    })
                  }
                </div>
              </SubPanel>
            </div>
          </div>
        </div>
      </Center>
      <Left isMobile={false}>
        <div className={'wrapper'}>
          <LeftCard>
            <div className={'brand'}>
              <img src="/images/dashboard/solutions/img_services_002.png" alt=""/>
            </div>
            <div className={'description'}>
              <p>{i18n.t('dashboard.buildSolution')}</p>
            </div>
          </LeftCard>
        </div>
      </Left>
    </Content>
  )
}

export default All;

