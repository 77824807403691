import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      content: '""',
    },
  },
  checkedIcon: {
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      content: '""',
    },
  },
  icon_mode1: {
    '&:before': {
      backgroundImage:
        "url(/images/icons/Icon_Tasks_Todo_Dark.svg)",
    },
  },
  icon_mode2: {
    '&:before': {
      backgroundImage:
        "url(/images/icons/Icon_Tasks_Todo.svg)",
    },
  },
  checkedIcon_mode1: {
    '&:before': {
      backgroundImage:
        "url(/images/Icons/Icon_Tasks_Completed_Dark.svg)",
    },
  },
  checkedIcon_mode2: {
    '&:before': {
      backgroundImage:
        "url(/images/Icons/Icon_Tasks_Todo_Hover.svg)",
    },
  },
});

const CheckBox = (props) => {
  const { type } = props;
  const classes = useStyles();

  return (
    <Checkbox
      disableRipple
      className={classes.root}
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon, {
        [classes.checkedIcon_mode1] : type === 1,
        [classes.checkedIcon_mode2] : type === 2,
      })} />}
      icon={<span className={clsx(classes.icon, {
        [classes.icon_mode1] : type === 1,
        [classes.icon_mode2] : type === 2,
      })} />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...props}
    />
  );
};

export default CheckBox;
