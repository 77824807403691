import React, { Component } from "react";
import styled, {css} from "styled-components";

const CheckedIcon = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    min-width: 15px;
    height: 15px;
    border-radius: 2px;
    background: #415c85;
    cursor: pointer;
    
    &:hover {
      i {
        color: #8399b2;
      }
    }
  `;

const Checked = styled.i`
    color: white;
    font-size: 8px;
  `;

const UncheckedIcon = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    min-width: 15px;
    height: 15px;
    border: 1px solid #e4e4e4;
    border-radius: 2px;
    cursor: pointer;
    
    i {
      display: none;
    }
    
    &:hover {
      i {
        color: #8399b2;
        display: block;
      }
      
      ${props => props.isExpired && css`
      background: white;
      border: none;
      
      i {
        color: #2b3138;
        display: block;
      }
    `}
    }
`;

class CheckBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: false,
    };
  }

  componentDidMount() {
    const { value } = this.props;
    this.setState({
      mode: value
    });
  }

  onAlertAction = () => {
    const { mode } = this.state;
    this.setState({
      mode: !mode,
    });
    this.props.onActionProcess(!mode);
  };

  render() {
    const { mode } = this.state;
    const { isExpired } = this.props;

    return (
      <div onClick={() => this.onAlertAction()}>
        {
          mode ?
            <CheckedIcon>
              <Checked className="fa fa-check" aria-hidden="true"/>
            </CheckedIcon>
            :
            <UncheckedIcon isExpired={isExpired}>
              <Checked className="fa fa-check" aria-hidden="true"/>
            </UncheckedIcon>
        }
      </div>
    );
  }
}

export default CheckBox;
