import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0 1.25rem 1.5rem;
  font-family: Circe-Bold;
  
  .flex {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    
    > span {
      font-size: 18px;
      padding-bottom: 6px;
    }
  }
  
  .price {
    color: #ed5f74;
    font-size: 27px;
    font-weight: bold;
    padding-right: 12px;
  }
  
  .notify {
    font-family: Circe-Regular;
    color: rgba(0,0,0,0.8);
    text-align: center;
    font-size: 15px;
    line-height: 17px;
    margin-top: 12px;
    
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      
      > a {
        padding-left: 4px;
        font-weight: bold;
      }
    }        
  }
`;

export const IconStripe = styled.img`
  height: 30px;
  margin-left: 10px;
`;
