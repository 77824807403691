import React from 'react';
import queryString from 'query-string';
import i18next from 'i18next';
import en from './en.json';
import ko from './ko.json';
import de from './de.json';
import es from './es.json';
import fr from './fr.json';
import it from './it.json';
import ja from './ja.json';
import pt from './pt.json';
import chs from './chs.json';

const translationsLanguages = [
  'chs',
  'cht',
  'de',
  'en',
  'es',
  'fr',
  'it',
  'ja',
  'ko',
  'pt',
];

const translationsMap = translationsLanguages.reduce((ac, a) => ({ ...ac, [a]: {} }), {});

export const languages = {
  de: { name: 'Deutsch' },
  en: { name: 'English' },
  es: { name: 'Español' },
  fr: { name: 'Français' },
  it: { name: 'Italiano' },
  pt: { name: 'Português' },
  chs: { name: '汉语' },
  cht: { name: '漢語' },
  ja: { name: '日本語' },
  ko: { name: '한국어' },
};

export const getLanguageIsoCode = (languageId) => {
  if (languageId === 'chs' || languageId === 'cht') return 'zh';
  return languageId;
};

// Get i18n parameters from URL if present
const urlParams = queryString.parse(window.location.search || window.location.hash.substring(window.location.hash.indexOf('?')));

const rootDiv = document.getElementById('root');
const region = urlParams.region || rootDiv.getAttribute('region');
const languageUrlAlias = urlParams.languageUrlAlias || rootDiv.getAttribute('languageUrlAlias');
let language = localStorage.getItem('lang') || urlParams.language || rootDiv.getAttribute('language') || 'en';
if (!Object.keys(translationsMap).includes(language)) {
  language = 'en';
}

const instance = i18next.createInstance({
  initImmediate: false,
  fallbackLng: 'en',
  lng: language,
  resources: {
    en: {
      translation: en,
    },
    ko : {
      translation: ko,
    },
    es: {
      translation: es,
    },
    fr: {
      translation: fr,
    },
    it: {
      translation: it,
    },
    pt: {
      translation: pt,
    },
    chs: {
      translation: chs,
    },
    de: {
      translation: de,
    },
    ja: {
      translation: ja,
    },
  },
}, (error) => {
  if (error) throw error;
});
instance.region = region;
instance.languageUrlAlias = languageUrlAlias;

// let module;
// export const loadLanguage = async () => {
//   if (language !== 'en') {
//     module = await import(/* webpackChunkName: "[request]" */ `./${language}.json`);
//     instance.addResourceBundle(language, 'translation', module);
//   }
// };

export default instance;
