import styled from "styled-components";
import { SubPanel } from "../styles";

export const LeftCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #f2f3f6;
  border-radius: 7px;
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: row;
  }
  
  .brand {
    background-color: #fbfefe;
    padding: 25px;
    
    img {
      width: 100%;
    }
    
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 10px 20px;
    }
  }
  
  .description {
    padding: 15px;
    display: flex;
    align-items: center;
    
    p {
      font-size: 16px;
      line-height: 1.5;
    }
  }
`;


export const SpecialCardPanel = styled(SubPanel)`
  .content {
    @media (max-width: 1000px) {
     flex-direction: column;
     
     > div {
        width: 100%;
        margin-bottom: 30px;
      }
    }
    > div:last-child {
      margin-bottom: 0px;
    }
  }
`;

export const CardSlider = styled.div`
  display: none;
  flex-wrap: nowrap;
  margin-bottom: 1.5rem;
  overflow-y: auto;
  
  &::-webkit-scrollbar-thumb {
    background-color: #e5e5e5;
    border-radius: 2.5px;
    height: 5px;
  }
  
  &::-webkit-scrollbar {
    margin-top: 10px;
    height: 5px;
  }
  
  &::-webkit-scrollbar-track {
    display: none;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: flex;
    flex-wrap: nowrap;
  }
  
  > div {
    margin-right: 15px;
  }
`;
