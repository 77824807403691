import styled from "styled-components";
import { CommonButton } from "@/components/common/button";

export const Wrapper = styled.div`
  width: 100%;
`;

export const Header = styled.div`
  p {
    font-family: Circe-Bold;
    font-size: 20px;
  }
`;

export const Body = styled.div`
  margin: 20px 0;
`;

export const EmptyBody = styled.div`
  height: 75px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f7f9;
  border-radius: 5px;
  
  p {
    font-size: 16px;
    color: #81848a;
    display: inline-block;
  }
`;

export const Footer = styled.div`
  .item_icon {
    width: 35px;
  }
`;

export const ExploreButton = styled(CommonButton)`
  background: #f5f7f9;
  color: #5e7599;
  
  &:hover {
    background: #e6e9eb;
  }
`;

export const SolutionCardList = styled.div`
  width: 100%;
  box-shadow: 0 0 20px #f5f5f5;
`;
