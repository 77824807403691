import React, {Component, Fragment, useEffect} from "react";
import styled, { css } from "styled-components";
import i18n from "../../i18n";

const Select = (props) => {
  const {
    label, placeholder, options, selectedOptionId, viewMode, icon, size, dashboard, isSignUp,
  } = props;

  const SelectWrapper = styled.div`
    position: relative;
    ${props => props.isSignUp && `
      margin-left: auto;    
    `}
  `;

  const Label = styled.div`
    font-family: Circe-Regular;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #5e7599;
  `;

  const SelectBox = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 10px;
    background-color: ${props => (dashboard ? null: '#fff')};
    margin-right: ${props => (dashboard ? '10px': '0')};
    margin-left: 10px;
    min-width: 30px;

    font-family: Circe-Regular;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${props => (dashboard ? '#fff': '#181e26')};

    outline: none;
    border: 0;
    padding: 14px 15px;
    
    &:hover {
      cursor: pointer;
    }
    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      justify-content: space-around;
      flex: 1;
    }
    
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      display: none;
    }
  `;

  const SelectList = styled.div`

    display: ${props => props.isShow ? 'block' : 'none'};
    position: absolute;
    top: 33px;
    right: 0px;
    
    width: 150px;
    
    min-width: 10rem;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    
    font-family: Circe-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;

    outline: none;
    z-index: 10;
    
    &:hover {
      cursor: pointer;
    }
  `;

  const SelectItem = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    
    &:hover {
      border-radius: .25rem;
      background: #f8f9fa;
      cursor: pointer;
    }
  `;

  const DownIcon = styled.div`
    font-size: 20px;
    position: absolute;
    right: 0;
  `;

  const LanguageTitle = styled.div`
      font-size: 20px;
      margin-right: 10px;
  `;
  const selectedOption = options.find((option) => {
    return option.id === selectedOptionId;
  });

  const [isOpened, setIsOpened] = React.useState(null);

  const openedToggle = () => {
    setIsOpened(!isOpened);
  }

  const selectOption = (optionId) => {
    if (optionId !== props.selectedOptionId) {
      props.setOption(optionId);
    }
    else {
      props.setOption(null);
    }
    localStorage.setItem('lang', optionId);
    window.location.reload();
    openedToggle();
  }

  const toggleContainer = React.createRef();

  const onClickOutsideHandler = (event) => {
    if (isOpened && toggleContainer.current && !toggleContainer.current.contains( event.target )) {
      setIsOpened(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', onClickOutsideHandler);
  });
  return (
    <SelectWrapper
      ref={toggleContainer}
      isSignUp={isSignUp}
    >
      {label ? <Label>{label}</Label>: ''}
      <SelectBox onClick = {openedToggle} size = {size}>
        {
          selectedOption && selectedOption.name &&
            <LanguageTitle>
              {selectedOption.name}
            </LanguageTitle>
        }
        <DownIcon className="fa fa-caret-down" aria-hidden="true"></DownIcon>

        <SelectList isShow={isOpened}>
          {
            options.length ?
              options.map(option => {
                return (
                  <SelectItem
                    key = {option.id}
                    onClick={() => {
                      selectOption(option.id)
                    }}
                  >
                    {option.name}
                  </SelectItem>
                )
              })
              : null
          }
        </SelectList>
      </SelectBox>

    </SelectWrapper>
  );
};

export default Select;








