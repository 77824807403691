import React, { useEffect } from 'react';
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";

//components
import TargetBlock from "@/containers/Targets/TargetBlock";
import StatusPanel from "@/containers/StatusPanel";
import { STORE_KEYS } from '@/stores';
import i18n from '../../i18n';
//styles
import {
  Container, Title, Content, Center, Right, BackToList, SubHeader, TabItem, Tabs,
} from './styles';

const TargetPage = ({ cloudmebTargets, tabItems, requestCloudmebTargets, markTaskAsComplete, requestCloudTasks }) => {
  const [selectedTabId, setSelectedTabId] = React.useState('Active');
  const [detailViewId, setDetailViewId] = React.useState('');

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    requestCloudTasks();
    requestCloudmebTargets();
  }, []);

  return (
    <>
      <Container>
        <SubHeader>
          <Title>{i18n.t('dashboard.targets')}Targets</Title>
          <Tabs>
            {
              tabItems.map(item => {
                return (
                  selectedTabId === item.name ?
                    <TabItem active onClick={() => setSelectedTabId(item.name)} key={item.name}>{item.name} <span>{item.count}</span></TabItem>
                    : <TabItem onClick={() => setSelectedTabId(item.name)} key={item.name}>{item.name} <span>{item.count}</span></TabItem>
                )
              })
            }
          </Tabs>
        </SubHeader>
        <Content>
          <Center>
            <div className={'wrapper'} id="center">
              {
                cloudmebTargets.map((item, index) => {
                    if (selectedTabId === 'All') {
                      return (
                        <TargetBlock
                          target={item}
                          showAll={detailViewId !== ''}
                          setDetailViewId={setDetailViewId}
                          key={index}
                          markTaskAsComplete={markTaskAsComplete}
                        />
                      )
                    } else if (selectedTabId === 'Active') {
                      if (!item.completed) {
                        return (
                          <TargetBlock
                            target={item}
                            showAll={detailViewId !== ''}
                            setDetailViewId={setDetailViewId}
                            key={index}
                            markTaskAsComplete={markTaskAsComplete}
                          />
                        )
                      }
                    } else if (selectedTabId === 'Completed') {
                      if (item.completed) {
                        return (
                          <TargetBlock
                            target={item}
                            showAll={detailViewId !== ''}
                            setDetailViewId={setDetailViewId}
                            key={index}
                            markTaskAsComplete={markTaskAsComplete}
                          />
                        )
                      }
                    }
                })
              }
            </div>
          </Center>
          <Right>
            <StatusPanel/>
          </Right>
        </Content>
      </Container>
    </>
  )
};

export default compose(
  inject(STORE_KEYS.TARGETSSTORE, STORE_KEYS.CHECKLISTSTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.TARGETSSTORE]: {
         cloudmebTargets,
         tabItems,
         requestCloudmebTargets,
         markTaskAsComplete,
       },
       [STORE_KEYS.CHECKLISTSTORE]: {
         requestCloudTasks,
       }
     }) => ({
      cloudmebTargets,
      tabItems,
      requestCloudmebTargets,
      markTaskAsComplete,
      requestCloudTasks,
    })
  )
)(TargetPage);
