import React from 'react';
import {observable, action} from 'mobx';
import {
    loginWithEmail, loginWithGmail, registerWithEmail, confirmEmail, resendEmailConfirm,
    existEmail, resetPasswordRequest, resetPassword, updateUsername, getRegions
} from "@/lib/rest";

class AuthStore {
    @observable isLoggedIn = false;
    @observable email = '';
    @observable originEmailAfterSignup = '';
    @observable pendingEmail = '';
    @observable username = '';
    @observable profileName = '';
    @observable regions = [];
    vModeHandle = null;

    constructor(snackbar, viewModeStore) {
        this.snackbar = snackbar;
        this.isLoggedIn = !!localStorage.getItem('authToken');
        this.email = localStorage.getItem('email');
        this.originEmailAfterSignup = localStorage.getItem('originEmailAfterSignup');
        this.username = localStorage.getItem('username');
        this.vModeHandle = viewModeStore;
        this.getRegionsData();
    }

    /**
     *  Email login, Sign Up
     */
    @action.bound loginWithMail(email, password) {
        this.vModeHandle.setLoadingMode(true);
        return new Promise((resolve, reject) => {
            loginWithEmail(email, password)
                .then(data => {
                    this.vModeHandle.setLoadingMode(false);
                    this.username = data.name || '';
                    this.email = data.email || '';

                    localStorage.setItem("authToken", data.jwt || "");
                    localStorage.setItem("username", this.username);
                    localStorage.setItem("email", this.email);

                    this.isLoggedIn = true;
                    this.showSnackMsg('You are successfully logged in.');
                    resolve(true);
                })
                .catch(e => {
                    this.vModeHandle.setLoadingMode(false);
                    console.log('[verify failed]', e);
                    this.email = email || '';
                    this.originEmailAfterSignup = email || '';
                    this.showSnackMsg('Login failed.');
                    reject(e);
                });
        });
    }

    @action.bound loginWithGoogle(data) {
        this.vModeHandle.setLoadingMode(true);
        return new Promise((resolve, reject) => {
            loginWithGmail(data)
                .then(data => {
                    this.vModeHandle.setLoadingMode(false);
                    this.username = data.name || '';
                    this.email = data.email || '';

                    localStorage.setItem("authToken", data.jwt || "");
                    localStorage.setItem("username", this.username);
                    localStorage.setItem("email", this.email);

                    this.isLoggedIn = true;
                    this.showSnackMsg('You are successfully logged in.');
                    resolve(true);
                })
                .catch(e => {
                    this.vModeHandle.setLoadingMode(false);
                    console.log('[verify failed]', e);
                    this.showSnackMsg('Login failed.');
                    reject(e);
                });
        });
    }

    @action.bound getRegionsData() {
        getRegions()
            .then(data => {
                const regionsData = [];
                (data.regions || []).map((item, index) => {
                    regionsData.push({
                        id: item.value,
                        name: item.value,
                        img: '',
                    });
                });
                this.regions = regionsData;
            })
            .catch(e => {
                this.regions = [];
            });
    }

    @action.bound checkExistEmail(email) {
        this.vModeHandle.setLoadingMode(true);
        return new Promise((resolve, reject) => {
            existEmail(email)
                .then(data => {
                    this.vModeHandle.setLoadingMode(false);
                    console.log('isExist:', data.isExist);
                    if (data.isExist) {
                        this.showSnackMsg('Email is existing already.');
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
                .catch(e => {
                    this.vModeHandle.setLoadingMode(false);
                    console.log('[checkExistEmail]', e);
                    resolve(true);
                });
        });
    }

    @action.bound updateProfileName(name) {
        if ((name || '').trim().length === 0) return;
        this.username = name || '';
    }

    @action.bound updateProfile() {
        this.vModeHandle.setLoadingMode(true);
        return new Promise((resolve, reject) => {
            updateUsername(this.profileName)
                .then(data => {
                    this.vModeHandle.setLoadingMode(false);
                    if (data.success) {
                        this.showSnackMsg('Profile has been updated successfully.');
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
                .catch(e => {
                    this.vModeHandle.setLoadingMode(false);
                    console.log('[updateUsername]', e);
                    resolve(true);
                });
        });
    }

    @action.bound resetPasswordRequest(email) {
        this.vModeHandle.setLoadingMode(true);
        resetPasswordRequest(email)
            .then(ret => {
                this.vModeHandle.setLoadingMode(false);
                this.showSnackMsg(ret.message);
                setTimeout(() => {
                    window.location.href = '/login';
                }, 3000);
            })
            .catch(err => {
                this.vModeHandle.setLoadingMode(false);
                this.showSnackMsg(err.message);
            });
    }

    @action.bound resetPassword(token, password) {
        this.vModeHandle.setLoadingMode(true);
        resetPassword(password, token)
            .then(ret => {
                this.vModeHandle.setLoadingMode(false);
                this.showSnackMsg(ret.message);
                setTimeout(() => {
                    window.location.href = '/login';
                }, 3000);
            })
            .catch(err => {
                this.vModeHandle.setLoadingMode(false);
                this.showSnackMsg(err.message);
            });
    }

    @action.bound resendEmailConfirmation(withNewEmail = false) {
        this.vModeHandle.setLoadingMode(true);
        const mainEmailForResend = this.email;
        return new Promise((resolve, reject) => {
            const obj = {
                mainEmailForResend,
                originEmail: this.originEmailAfterSignup,
                withNewEmail,
            }
            resendEmailConfirm(obj)
                .then((data) => {
                    this.vModeHandle.setLoadingMode(false);
                    this.showSnackMsg(data.message);
                    resolve(true);
                })
                .catch((e) => {
                    this.vModeHandle.setLoadingMode(false);
                    this.showSnackMsg(e.message);
                    resolve(false);
                });
        });
    }

    @action.bound
    async changeEmailAndResendConfirmation(newEmail) {
        return new Promise(async (resolve, reject) => {
            if ((newEmail || '').trim().length === 0) {
                this.showSnackMsg('Please enter new email.');
                return resolve(false);
            }
            const isNewEmailExist = await this.checkExistEmail(newEmail);
            if (!isNewEmailExist) {
                this.email = newEmail || '';
                localStorage.setItem("email", newEmail);
                await this.resendEmailConfirmation(true);
                resolve(true);
            } else {
                resolve(false);
            }
        });
    }

    @action.bound registerWithMail(
        name, email, password, phone, companyName, businessType, region,
        onboardStatus, monthlyTransactions, salesTaxFreq, fiscalYearEnd, socialMode
    ) {
        const mTrans = [
            "1-50 Transactions",
            "50-100 Transactions",
            "100-250 Transactions",
            "250-500 Transactions",
            "500-1000 Transactions",
        ];
        return new Promise((resolve, reject) => {
            this.vModeHandle.setLoadingMode(true);
            this.email = email || '';
            this.originEmailAfterSignup = email || '';
            localStorage.setItem("email", this.email);
            localStorage.setItem("originEmailAfterSignup", this.originEmailAfterSignup);

            registerWithEmail(
                name, email, password, phone, companyName, businessType, region,
                onboardStatus, mTrans[monthlyTransactions - 1], salesTaxFreq, fiscalYearEnd, socialMode
            )
                .then(data => {
                    this.vModeHandle.setLoadingMode(false);
                    if (data.isVerified) {
                        this.username = data.name || '';
                        this.email = data.email || '';
                        this.isLoggedIn = true;

                        localStorage.setItem("authToken", data.jwt || "");
                        localStorage.setItem("username", this.username);
                        localStorage.setItem("email", this.email);
                        this.showSnackMsg(data.message);
                        resolve(true);
                    } else {
                        this.showSnackMsg(data.message);
                        reject('Registration is failed');
                    }
                })
                .catch(e => {
                    this.vModeHandle.setLoadingMode(false);
                    this.showSnackMsg(e.message);
                    reject(e);
                });
        });
    }

    @action.bound confirmMail(token) {
        this.vModeHandle.setLoadingMode(true);
        return new Promise((resolve, reject) => {
            confirmEmail(token)
                .then(data => {
                    this.vModeHandle.setLoadingMode(false);
                    this.username = data.name || '';
                    this.email = data.email || '';

                    localStorage.setItem("authToken", data.jwt || "");
                    localStorage.setItem("username", this.username);
                    localStorage.setItem("email", this.email);

                    this.isLoggedIn = true;
                    this.showSnackMsg(data.message);
                    resolve(true);
                })
                .catch(e => {
                    this.vModeHandle.setLoadingMode(false);
                    this.showSnackMsg(e.message);
                    this.logout();
                    reject(e);
                });
        });
    }

    @action.bound setPendingEmail(email) {
        this.pendingEmail = email;
    }

    /**
     *  Logout
     */
    @action.bound logout() {
        this.isLoggedIn = false;
        this.email = '';
        this.username = '';
        localStorage.clear();
        window.location.href = '/';
    }

    /**
     *  Snackbar Popup message
     */
    @action.bound showSnackMsg(msg) {
        this.snackbar({
            message: () => (
                <>
          <span>
            <b>{msg}</b>
          </span>
                </>
            )
        });
    }
}

export default (snackbar, viewModeStore) => new AuthStore(snackbar, viewModeStore);
